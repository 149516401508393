import React from 'react';
import Spinner from 'components/Shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EcgLoader from 'components/Shared/EcgLoader';
import { Grid, Typography } from '@material-ui/core';

const Statistics = (props) => {
  // const classes = useStyle();
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.appointments.statsloader);
  return (
    <React.Fragment>
      {isLoading ? (
        // <div className="spinnerContainer margin_200">
        //   {/* <Spinner /> */}
        // </div>
        <EcgLoader />
      ) : (
        <div style={{ height: 'auto', margin: '20px 0' }}>
          <Grid container spacing={2} style={{ textAlign: 'center' }}>
            <Grid item xs={12} className="">
              <Typography style={{ fontFamily: 'Work Sans', fontSize: '18px', fontWeight: '500' }}>
                {t('Consultations')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">{t('Total')}</Typography>
              <Typography variant="h4">{props.stats.totalAppointments || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">{t('Completed')}</Typography>
              <Typography variant="h4">{props.stats.totalAppointmentsCompleted || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">{t('Canceled')}</Typography>
              <Typography variant="h4">{props.stats.totalAppointmentsCancelled || '-'}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ textAlign: 'center' }}>
            <Grid item xs={12} className="">
              <Typography style={{ fontFamily: 'Work Sans', fontSize: '18px', fontWeight: '500' }}>
                {t('Session Type')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">{t('General')}</Typography>
              <Typography variant="h4">{props.stats.totalConsulationGeneral || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">{t('Detailed')}</Typography>
              <Typography variant="h4">{props.stats.totalConsulationDetailed || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">{t('Follow-up')}</Typography>
              <Typography variant="h4">{props.stats.totalConsulationFollowup || '-'}</Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
};

export default Statistics;
