import * as types from './actionTypes';
import { CrpAPI } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';

export const setSearchButtonClicked = (boolean) => {
  return {
    type: types.IS_SEARCH_BUTTON_CLICKED,
    payload: boolean,
  };
};

export const isFetchingSearchResults = (status) => {
  return {
    type: types.IS_FETCHING_SEARCH_RESULTS,
    payload: status,
  };
};

export const errorRetrievingSearchList = (status) => {
  return {
    type: types.ERROR_SEARCHING_RESULTS,
    payload: status,
  };
};

export const getSearchData = (search = '', status) => {
  return (dispatch) => {
    dispatch(isFetchingSearchResults(true));
    CrpAPI.get(`v1/searchlist?search=${search}&status=${status}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: types.GETSEARCHDATA_SUCCESS,
            payload: res.data.data,
          });
          dispatch(isFetchingSearchResults(false));
          dispatch(errorRetrievingSearchList(false));
        }
      })
      .catch((err) => {
        dispatch(isFetchingSearchResults(false));
        dispatch(errorRetrievingSearchList(true));
      });
  };
};
