import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { headerText } from '../constant/Constant';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  width: '100%',
  fontFamily: 'Work Sans',
  '& .MuiTypography-subtitle2': {
    fontWeight: 600,
    fontFamily: 'Work Sans',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
  },
}));
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  fontFamily: 'Work sans',
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      // backgroundColor: theme.palette.action.focus,
      backgroundColor: 'unset',
      border: '1px solid rgba(0, 0, 0, 0.3)',
      borderRadius: '30px',
    },
    [`&.Mui-selected > &.MuiTreeItem-label > &.MuiBox-root > &.MuiTypography-body2`]: {
      fontWeight: 700,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: '400',
      color: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '& .MuiTypography-body2': {
      lineHeight: '175%',
      letterSpacing: '0.15px',
      fontFamily: 'Work Sans',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, setActive, ...other } = props;

  const changetab = (id) => {
    setActive(id);
  };
  return (
    <StyledTreeItemRoot
      onClick={() => changetab(other.nodeId)}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 0.5 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo != 0 && labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function SettingsSideNav(props) {
  return (
    <Root>
      <TreeView
        aria-label="gmail"
        defaultSelected={[props?.activeTab] || ['1']}
        // defaultExpanded={['3']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        sx={{ height: 'auto', flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
      >
        <StyledTreeItem nodeId="1" labelText={headerText.TAB1} {...props}>
          <StyledTreeItem
            nodeId="5"
            labelText={headerText.TAB5}
            // labelIcon={SupervisorAccountIcon}
            color="#1a73e8"
            bgColor="#e8f0fe"
            {...props}
          />
        </StyledTreeItem>
        {props?.isAdmin && (
          <>
            <StyledTreeItem nodeId="2" labelText={headerText.TAB2} {...props}>
              <StyledTreeItem
                nodeId="6"
                labelText={headerText.TAB6}
                // labelIcon={SupervisorAccountIcon}
                color="#1a73e8"
                bgColor="#e8f0fe"
                {...props}
              />
            </StyledTreeItem>
            <StyledTreeItem nodeId="3" labelText={headerText.TAB3} {...props} />
            {/* <StyledTreeItem nodeId="4" labelText="Notifications" {...props} /> */}
          </>
        )}
      </TreeView>
    </Root>
  );
}
