/* eslint-disable */
import React, { Component } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Datepicker from './material_datepicker';
import Timepicker from './material_timepicker';
import { withStyles } from '@material-ui/core/styles';
import 'assets/styles/bootstrap.scss';
import {
  Grid,
  Switch,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from '@material-ui/core';
import Alert from '../../Shared/Alert/Alerts';
import Flex from '../../Shared/Flex';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import * as zone from 'utilities/Utilities';
import MuiTypography from '../../Shared/Typography/MuiTypography';
import * as actions from '../../../Services/API/actions';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: 'none',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const GreenSwitch = withStyles({
  switchBase: {
    color: '#F1F1F1',
    '&$checked': {
      color: '#2D9F86',
    },
    '&$checked + $track': {
      backgroundColor: '#2D9F86',
    },
  },
  checked: {},
  track: {},
})(Switch);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: 'rgba(120, 120, 128, 0.32)',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const GreenCheckbox = withStyles({
  root: {
    color: '#DADADA',
    '&$checked': {
      color: '#2D9F86',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: '#DADADA',
    '&$checked': {
      color: '#2D9F86',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: 'none ',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MuiTypography
        fontSize="24px"
        fontFamily="Work Sans"
        lineHeight="28px"
        letterSpacing="28px"
        fontWeight={500}
        color="#000000"
      >
        {title}
      </MuiTypography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class AvailabilityForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      Recurrence: false,
      seriespattern: 'Weekly',
      workingDays: [
        { id: '0', value: 'Sunday' },
        { id: '1', value: 'Monday' },
        { id: '2', value: 'Tuesday' },
        { id: '3', value: 'Wednesday' },
        { id: '4', value: 'Thursday' },
        { id: '5', value: 'Friday' },
        { id: '6', value: 'Saturday' },
      ],
      dailyworkingDays: [
        { id: '0', value: 'All Days' },
        { id: '1', value: 'Week Days' },
      ],
      actualworkingDays: [],
      selectedworkingDayValue: [],
      selectedworkingDay: [],
      alert: null,
    };
    this.baseState = this.state;
  }
  componentDidMount() {
    this.setState({
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      startTime: this.props.startTime,
      endTime: this.props.endTime,
      alert: (
        <Alert
          date={new Date()}
          title="Date and time required"
          message={
            this.props.isRecurring
              ? 'Please select date, start and end time to update availability.'
              : 'Please select date, start and end time to add availability.'
          }
        />
      ),
    });
    let recursiveElements = null;

    let weekdayselection = [];
    if (this.props.recurrsivepattern !== 'NotRecursive' && this.props.seriesConfirm === 'multi') {
      recursiveElements = this.props.recurrsivepattern.split('=');
      let recurrencepattern = recursiveElements[1].split(';');
      let days = recursiveElements[3].split(',');
      if (recurrencepattern[0] === 'DAILY' && recursiveElements[3] === 'SU,MO,TU,WE,TH,FR,SA')
        this.setState({
          seriespattern: 'Daily',
          selectedworkingDay: ['0'],
          Recurrence: true,
        });
      else if (recurrencepattern[0] === 'DAILY' && recursiveElements[3] === 'MO,TU,WE,TH,FR')
        this.setState({
          seriespattern: 'Daily',
          selectedworkingDay: ['1'],
          Recurrence: true,
        });
      else {
        days.map((key) => {
          if (key === 'SU') weekdayselection.push('0');
          else if (key === 'MO') weekdayselection.push('1');
          else if (key === 'TU') weekdayselection.push('2');
          else if (key === 'WE') weekdayselection.push('3');
          else if (key === 'TH') weekdayselection.push('4');
          else if (key === 'FR') weekdayselection.push('5');
          else weekdayselection.push('6');
        });
        this.setState({
          seriespattern: 'Weekly',
          selectedworkingDay: weekdayselection,
          Recurrence: true,
        });
      }
    }
  }
  componentWillUnmount() {
    this.setState({
      isOpen: false,
    });
  }
  modalOpenHandler = () => {
    this.setState({
      isOpen: true,
    });
  };
  modalCloseHandler = () => {
    this.setState({
      isOpen: false,
    });
  };
  navigate = (action) => {
    this.props.onNavigate(action);
  };
  startDate_handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  endDate_handleChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  startTime_handleChange = (date) => {
    this.setState({
      startTime: date,
    });
  };
  endTime_handleChange = (date) => {
    this.setState({
      endTime: date,
    });
  };

  handleRadioChange = (event) => {
    this.setState({ seriespattern: event.target.value });
  };

  handleFieldWorkingDayChecked = (event, id, workingDay) => {
    const selectedIndex = this.state.selectedworkingDay.indexOf(id);
    let newSelectedworkingDays = [];
    let newSelectedworkingDaysValue = [];

    if (selectedIndex === -1) {
      newSelectedworkingDays = newSelectedworkingDays.concat(this.state.selectedworkingDay, id);
      newSelectedworkingDaysValue = newSelectedworkingDaysValue.concat(
        this.state.selectedworkingDayValue,
        workingDay,
      );
    } else if (selectedIndex === 0) {
      newSelectedworkingDays = newSelectedworkingDays.concat(
        this.state.selectedworkingDay.slice(1),
      );
      newSelectedworkingDaysValue = newSelectedworkingDaysValue.concat(
        this.state.selectedworkingDayValue.slice(1),
      );
    } else if (selectedIndex === this.state.selectedworkingDay.length - 1) {
      newSelectedworkingDays = newSelectedworkingDays.concat(
        this.state.selectedworkingDay.slice(0, -1),
      );
      newSelectedworkingDaysValue = newSelectedworkingDaysValue.concat(
        this.state.selectedworkingDayValue.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedworkingDays = newSelectedworkingDays.concat(
        this.state.selectedworkingDay.slice(0, selectedIndex),
        this.state.selectedworkingDay.slice(selectedIndex + 1),
      );
      newSelectedworkingDaysValue = newSelectedworkingDaysValue.concat(
        this.state.selectedworkingDayValue.slice(0, selectedIndex),
        this.state.selectedworkingDayValue.slice(selectedIndex + 1),
      );
    }
    let selectedWorkingDayWithoutId = newSelectedworkingDaysValue.map(
      (newSelectedworkingDayValue) => newSelectedworkingDayValue.value,
    );
    this.setState({
      selectedworkingDay: newSelectedworkingDays,
      selectedworkingDayValue: newSelectedworkingDaysValue,
      actualworkingDays: selectedWorkingDayWithoutId,
    });
  };

  handleSwitchChange = (event) => {
    this.setState({ Recurrence: event.target.checked });
  };
  tConvert = (time) => {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join('');
  };
  submitHandler = () => {
    let sDate = moment(this.state.startDate || this.props.startDate);
    let sTime = moment(this.state.startTime || this.props.startTime);
    let eDate = moment(this.state.endDate || this.props.endDate);
    let eTime = moment(this.state.endTime || this.props.endTime);
    if (
      !this.state.Recurrence &&
      (this.props.component === 'toolbar' || this.props.component === 'popover') &&
      !this.state.isSplit
    ) {
      eDate = moment(this.state.startDate || this.props.startDate);
      eTime = moment(this.state.endTime || this.props.endTime);
    }
    let startDate = moment({
      year: sDate.year(),
      month: sDate.month(),
      day: sDate.date(),
    }).format('YYYY-MM-DD');
    let startTime = moment({
      hour: sTime.hour(),
      minute: sTime.minute(),
    }).format('HH:mm');

    let endDate = moment({
      year: eDate.year(),
      month: eDate.month(),
      day: eDate.date(),
    }).format('YYYY-MM-DD');
    let endTime = moment({
      hour: eTime.hour(),
      minute: eTime.minute(),
    }).format('HH:mm');
    let days =
      this.state.seriespattern === 'Daily'
        ? this.state.selectedworkingDay[0] === '0'
          ? ['0', '1', '2', '3', '4', '5', '6']
          : ['1', '2', '3', '4', '5']
        : this.state.selectedworkingDay;
    let formData = {
      days: days,
      endDate: endDate,
      endTime: endTime,
      isRecurring: this.state.Recurrence,
      reason: 'string',
      recurrencePattern: this.state.seriespattern === 'Daily' ? '0' : '1',
      startDate: startDate,
      startTime: startTime,
    };
    let splitStart = true;
    let splitEnd = true;
    if (this.props.component === 'popover' && this.state.isSplit) {
      splitStart = moment(startDate).isBetween(
        moment(this.props.event.splitStartDateTime).format('YYYY-MM-DD'),
        moment(this.props.event.splitEndDateTime).format('YYYY-MM-DD'),
        null,
        [],
      );
      splitEnd = moment(endDate).isBetween(
        moment(this.props.event.splitStartDateTime).format('YYYY-MM-DD'),
        moment(this.props.event.splitEndDateTime).format('YYYY-MM-DD'),
        null,
        [],
      );
    }
    if (
      ((!splitStart && splitEnd) || (splitStart && !splitEnd) || (!splitStart && !splitEnd)) &&
      this.props.seriesConfirm === 'single'
    ) {
      this.setState({
        alert: (
          <Alert
            date={new Date()}
            title="Date Conflict"
            message="Cannot edit availability more than two days for Recursive Series."
          />
        ),
      });
    } else if (startDate >= endDate && startTime >= endTime && !this.state.isSplit) {
      this.setState({
        alert: (
          <Alert
            date={new Date()}
            title="Time Conflict"
            message="End time should not be before to start time."
          />
        ),
        validation: true,
      });
    } else if (
      this.state.Recurrence &&
      this.state.seriespattern === 'Weekly' &&
      !this.state.selectedworkingDay.length > 0
    ) {
      this.setState({
        alert: (
          <Alert
            date={new Date()}
            title="Select Day(s)"
            message="Please select day(s) to add availability."
          />
        ),
        validdays: true,
        validation: false,
      });
    } else if (
      this.state.Recurrence &&
      this.state.seriespattern === 'Daily' &&
      !this.state.selectedworkingDay.length > 0
    ) {
      this.setState({
        alert: (
          <Alert
            date={new Date()}
            title="Select Day(s)"
            message={
              this.props.type === 'add'
                ? 'Please select day(s) to add availability.'
                : 'Please select day(s) to delete availability.'
            }
          />
        ),
        validdays: true,
        validation: false,
      });
    } else if (this.state.Recurrence && this.state.startDate === this.state.endDate) {
      this.setState({
        alert: (
          <Alert
            date={new Date()}
            title="Date Conflict"
            message={'Please change the End date as multiple day(s) are selected.'}
          />
        ),
        validdays: false,
        validation: false,
        validdate: true,
      });
    } else {
      switch (this.props.component) {
        case 'toolbar':
          actions.addAvailability(this.props.teamId, this.props.profileId, formData).subscribe(
            (res) => {
              if ('detailed_error' in res.data) {
                this.setState({
                  validdays: false,
                  validation: false,
                  validdate: false,
                  alert: (
                    <Alert
                      date={new Date()}
                      title="Cannot set Availability"
                      message={res.data['detailed_error']}
                    />
                  ),
                });
              } else {
                let weekdayselection = [];
                for (let key in formData.days.sort()) {
                  if (days[key] === '0') weekdayselection.push('Sun');
                  else if (days[key] === '1') weekdayselection.push('Mon');
                  else if (days[key] === '2') weekdayselection.push('Tue');
                  else if (days[key] === '3') weekdayselection.push('Wed');
                  else if (days[key] === '4') weekdayselection.push('Thu');
                  else if (days[key] === '5') weekdayselection.push('Fri');
                  else if (days[key] === '6') weekdayselection.push('Sat');
                }
                let selectedString = 'every ' + weekdayselection.join(', ').toString();
                let selecteddays =
                  this.state.seriespattern === 'Daily'
                    ? this.state.selectedworkingDay[0] === '0'
                      ? ' every week'
                      : ' all week days'
                    : selectedString;
                this.props.onSubmit({
                  isRecurring: formData.isRecurring,
                  type: 'add',
                  days: selecteddays,
                  startDate: moment(formData.startDate).format('Do MMMM YYYY'),
                  startTime: this.tConvert(formData.startTime),
                  endTime: this.tConvert(formData.endTime),
                  endDate: moment(formData.endDate).format('Do MMMM YYYY'),
                });
                this.setState({
                  isOpen: false,
                  alert: null,
                  validdays: false,
                  validdate: false,
                  validation: false,
                });
              }
            },
            (err) => {
              this.setState({
                validdays: false,
                validation: false,
                validdate: false,
                alert: (
                  <Alert
                    date={new Date()}
                    title="Technical Issue"
                    message="Unable to process this request due to internal server error."
                  />
                ),
              });
            },
          );
          break;
        case 'popover':
          {
            let params = {
              isAvailable: true,
              timeZoneOffset: new Date().getTimezoneOffset(),
              timeZone: zone.getTimezoneName(),
              version: this.props.event.version,
            };
            let recurrsiveData = {
              modifiedEndDateTime: moment(formData.endDate + 'T' + formData.endTime).format(
                'YYYY-MM-DDTHH:mm:ss.SSS',
              ),
              modifiedStartDateTime: moment(formData.startDate + 'T' + formData.startTime).format(
                'YYYY-MM-DDTHH:mm:ss.SSS',
              ),
              // recurrenceStartTime:this.props.event.recurrenceStartTime,
              // recurrenceEndTime:this.props.event.recurrenceEndTime,
            };
            let recurrsiveParams = {
              timeZoneOffset: new Date().getTimezoneOffset(),
              timeZone: zone.getTimezoneName(),
              startTime: moment(this.props.event.instanceDateTime).format('HH:mm'),
              instanceDate: moment(this.props.event.instanceDateTime).format('YYYY-MM-DD'),
              version: this.props.event.version,
            };

            this.props.event.isRecurring && this.props.seriesConfirm === 'single'
              ? actions
                  .updateRecursiveAvailability(
                    this.props.teamId,
                    this.props.profileId,
                    this.props.event.id,
                    recurrsiveData,
                    recurrsiveParams,
                  )
                  .subscribe(
                    (res) => {
                      if ('detailed_error' in res.data) {
                        this.setState({
                          validdays: false,
                          validation: false,
                          validdate: false,
                          alert: (
                            <Alert
                              date={new Date()}
                              title="Cannot update Availability"
                              message={
                                'detailed_error' in res.data
                                  ? res.data['detailed_error']
                                  : 'Unable to process this request due to internal server error.'
                              }
                            />
                          ),
                        });
                      } else {
                        this.props.onSubmit({
                          type: 'edit',
                        });
                        this.setState({
                          isOpen: false,
                          alert: null,
                          validdays: false,
                          validation: false,
                          validdate: false,
                        });
                      }
                    },
                    (err) => {
                      this.setState({
                        validdays: false,
                        validation: false,
                        validdate: false,
                        alert: (
                          <Alert
                            date={new Date()}
                            title="Technical Issue"
                            message="Unable to process this request due to internal server error."
                          />
                        ),
                      });
                    },
                  )
              : actions
                  .updateAvailability(
                    this.props.teamId,
                    this.props.profileId,
                    this.props.event.id,
                    formData,
                    params,
                  )
                  .subscribe(
                    (res) => {
                      if ('detailed_error' in res.data) {
                        this.setState({
                          validdays: false,
                          validation: false,
                          validdate: false,
                          alert: (
                            <Alert
                              date={new Date()}
                              title="Cannot update Availability"
                              message={
                                'detailed_error' in res.data
                                  ? res.data['detailed_error']
                                  : 'Unable to process this request due to internal server error.'
                              }
                            />
                          ),
                        });
                      } else {
                        this.props.onSubmit({
                          type: 'edit',
                        });
                        this.setState({
                          isOpen: false,
                          alert: null,
                          validdays: false,
                          validation: false,
                          validdate: false,
                        });
                      }
                    },
                    (err) => {
                      this.setState({
                        validdays: false,
                        validation: false,
                        validdate: false,
                        alert: (
                          <Alert
                            date={new Date()}
                            title="Technical Issue"
                            message="Unable to process this request due to internal server error."
                          />
                        ),
                      });
                    },
                  );
          }
          break;
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Dialog
        fullWidth={true}
        // maxWidth={"xl"}
        onClose={this.props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.show}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle
          // title={this.props.title}
          id="customized-dialog-title"
          onClose={this.props.handleClose}
          title={this.props.title}
        />
        <DialogContent>
          <div className="">
            {/* <ScopedCssBaseline> */}
            <form>
              <Grid container direction="row" justify="flex-end">
                <Grid item>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        disabled={this.props.disabled || this.props.seriesConfirm === 'single'}
                        checked={this.state.Recurrence}
                        onChange={this.handleSwitchChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <MuiTypography
                        fontSize="16px"
                        fontWeight={700}
                        fontFamily="Work Sans"
                        lineHeight="24px"
                        letterSpacing="-0.2px"
                        color="#142A39"
                      >
                        {t('Recurrence')}
                      </MuiTypography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
              <div className="m-3">{this.state.alert}</div>
              <Box p={1} />
              {this.state.Recurrence ||
              (this.props.component === 'popover' && this.state.isSplit) ? null : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MuiTypography
                      fontSize="12px"
                      fontFamily="Work Sans"
                      lineHeight="16px"
                      letterSpacing="0.4px"
                      color="rgba(20, 42, 57, 0.72)"
                    >
                      {t('Date')}
                    </MuiTypography>
                    <Datepicker
                      date={this.props.startDate || this.state.startDate}
                      changeHandle={this.startDate_handleChange}
                      disable={this.props.seriesConfirm === 'single' ? true : false}
                    />
                  </Grid>
                </Grid>
              )}
              <Box p={1} />
              <Grid container direction="row" justify="space-between" spacing={2}>
                <Grid item xs={6}>
                  <MuiTypography
                    fontSize="12px"
                    fontFamily="Work Sans"
                    lineHeight="16px"
                    letterSpacing="0.4px"
                    color="rgba(20, 42, 57, 0.72)"
                  >
                    {t('Start Time')}
                  </MuiTypography>
                  <Timepicker
                    valid={this.state.validation}
                    time={this.props.startTime || this.state.startTime}
                    changeHandle={this.startTime_handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiTypography
                    fontSize="12px"
                    fontFamily="Work Sans"
                    lineHeight="16px"
                    letterSpacing="0.4px"
                    color="rgba(20, 42, 57, 0.72)"
                  >
                    {t('End Time')}
                  </MuiTypography>
                  <Timepicker
                    valid={this.state.validation}
                    time={this.props.endTime || this.state.endTime}
                    changeHandle={this.endTime_handleChange}
                  />
                </Grid>
              </Grid>
              <Box p={1} />
              {this.state.Recurrence === true && (
                <>
                  <Grid container direction="row" justify="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <>
                        <MuiTypography
                          fontSize="16px"
                          fontWeight={700}
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          color="#142A39"
                        >
                          {t('Availability Series pattern')}
                        </MuiTypography>
                      </>
                      <div style={{ margin: '10px' }}>
                        <RadioGroup
                          aria-label="quiz"
                          name="quiz"
                          value={this.state.seriespattern}
                          onChange={this.handleRadioChange}
                        >
                          <Grid container item xs={12} style={{ margin: '0px 10px' }}>
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="Daily"
                                control={<GreenRadio />}
                                label={
                                  <MuiTypography
                                    fontSize="14px"
                                    fontWeight={400}
                                    fontFamily="Work Sans"
                                    lineHeight="24px"
                                    letterSpacing="-0.2px"
                                    color="#142A39"
                                  >
                                    {t('Daily')}
                                  </MuiTypography>
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="Weekly"
                                control={<GreenRadio />}
                                label={
                                  <MuiTypography
                                    fontSize="14px"
                                    fontWeight={400}
                                    fontFamily="Work Sans"
                                    lineHeight="24px"
                                    letterSpacing="-0.2px"
                                    color="#142A39"
                                  >
                                    {t('Weekly')}
                                  </MuiTypography>
                                }
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </div>
                    </Grid>
                  </Grid>
                  {this.state.seriespattern === 'Daily' && (
                    <Box>
                      <MuiTypography
                        fontSize="16px"
                        fontFamily="Work Sans"
                        lineHeight="24px"
                        letterSpacing="-0.2px"
                        fontWeight={700}
                        color={this.state.validdays ? '#CC3D3F' : '#142A39'}
                      >
                        {t('Select day')}(s)
                      </MuiTypography>
                      <Grid container item xs={12} spacing={2} style={{ margin: '10px' }}>
                        {this.state.dailyworkingDays.map((workingDay, index) => (
                          <Grid item xs={6} key={index}>
                            <FormControlLabel
                              key={workingDay.id}
                              control={
                                <GreenCheckbox
                                  checked={
                                    this.state.selectedworkingDay.indexOf(workingDay.id) !== -1
                                  }
                                  color="primary"
                                  onChange={(event) =>
                                    this.handleFieldWorkingDayChecked(
                                      event,
                                      workingDay.id,
                                      workingDay,
                                    )
                                  }
                                  value={
                                    this.state.selectedworkingDay.indexOf(workingDay.id) !== -1
                                  }
                                />
                              }
                              label={
                                <MuiTypography
                                  fontSize="14px"
                                  fontWeight={400}
                                  fontFamily="Work Sans"
                                  lineHeight="24px"
                                  letterSpacing="-0.2px"
                                  color="#142A39"
                                >
                                  {workingDay.value}
                                </MuiTypography>
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  {this.state.seriespattern === 'Weekly' && (
                    <Box>
                      <MuiTypography
                        fontSize="16px"
                        fontFamily="Work Sans"
                        lineHeight="24px"
                        letterSpacing="-0.2px"
                        fontWeight={700}
                        color={this.state.validdays ? '#CC3D3F' : '#142A39'}
                      >
                        {t('Select day')}(s)
                      </MuiTypography>
                      <Grid container item xs={12} spacing={2} style={{ margin: '10px' }}>
                        {this.state.workingDays.map((workingDay, index) => (
                          <Grid item xs={6} key={index}>
                            <FormControlLabel
                              key={workingDay.id}
                              control={
                                <GreenCheckbox
                                  checked={
                                    this.state.selectedworkingDay.indexOf(workingDay.id) !== -1
                                  }
                                  color="primary"
                                  onChange={(event) =>
                                    this.handleFieldWorkingDayChecked(
                                      event,
                                      workingDay.id,
                                      workingDay,
                                    )
                                  }
                                  value={
                                    this.state.selectedworkingDay.indexOf(workingDay.id) !== -1
                                  }
                                />
                              }
                              label={
                                <MuiTypography
                                  fontSize="14px"
                                  fontWeight={400}
                                  fontFamily="Work Sans"
                                  lineHeight="24px"
                                  letterSpacing="-0.2px"
                                  color="#142A39"
                                >
                                  {workingDay.value}
                                </MuiTypography>
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  {this.state.Recurrence ||
                  (this.props.component === 'popover' && this.state.isSplit) ? (
                    <Box>
                      <MuiTypography
                        fontSize="16px"
                        fontWeight={700}
                        fontFamily="Work Sans"
                        lineHeight="24px"
                        letterSpacing="-0.2px"
                        color="#142A39"
                      >
                        {t('Range of Recurrence')}
                      </MuiTypography>
                      <Grid container spacing={2} style={{ margin: '10px 0px' }}>
                        <Grid item xs={6}>
                          <MuiTypography
                            fontSize="12px"
                            fontFamily="Work Sans"
                            lineHeight="16px"
                            letterSpacing="0.4px"
                            color="rgba(20, 42, 57, 0.72)"
                          >
                            {t('Start Date')}
                          </MuiTypography>
                          <Datepicker
                            date={this.props.startDate || this.state.startDate}
                            changeHandle={this.startDate_handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MuiTypography
                            fontSize="12px"
                            fontFamily="Work Sans"
                            lineHeight="16px"
                            letterSpacing="0.4px"
                            color="rgba(20, 42, 57, 0.72)"
                          >
                            {t('End Date')}
                          </MuiTypography>
                          <Datepicker
                            valid={this.state.validdate}
                            date={this.props.endDate || this.state.endDate}
                            changeHandle={this.endDate_handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                  <Box p={1} />
                </>
              )}
            </form>
            <div className="telekardia">
              <Grid container direction="row" justify="flex-end" spacing={2}>
                <Grid item>
                  <button
                    type="button"
                    className="btn px-4 text-uppercase h40 ml-3 swal_green"
                    onClick={() => this.submitHandler()}
                  >
                    {this.props.seriesConfirm ? 'SAVE UPDATE' : 'SAVE'}
                  </button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(AvailabilityForm);
