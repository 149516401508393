import React, { useEffect } from 'react';
import { ListRow } from '.';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import { useTranslation } from 'react-i18next';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

type RowDef = {
  entityValues: Array<string>,
  rowNum: number,
  editAction?: Function,
  reloadAction?: Function,
  deleteAction?: Function,
  toggleChecked?: boolean,
  inlineEditAction?: Function,
  viewFeedbackDetailsAction?: Function,
  viewAdminConsultationDetailsAction?: Function,
  drillDownAction?: Function,
  headings?: Array<string>,
};

type Props = {
  items: Array<RowDef>,
  onSortEnd: Function,
  sortHeaderAction: Function,
};

// const StyledTableCell = withStyles(theme => ({
//   head: {
//     backgroundColor: theme.hpsBlue,
//     fontSize: 14,
//     fontWeight: "500",
//     color: "#FFFFFF"
//   }
// }))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    maxHeight: 'calc(100vh - 350px)',
    backgroundColor: '#FFFFFF',
    // overflow: 'auto'
  },
  tablecell: {
    // fontSize: 20,
    whiteSpace: 'nowrap',
    fontWeight: 'bolder',
    backgroundColor: '#FFFFFF',
  },
  tablecellbody: {
    // whiteSpace: "nowrap",
    backgroundColor: '#FFFFFF',
  },
}));

const MyTable = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    mergeAction,
    inlineEditAction,
    headings,
    toggleField,
    viewFeedbackDetailsAction,
    viewAdminConsultationDetailsAction,
    editAction,
    deleteAction,
    calendarAction,
    uploadappointmentsAction,
    uploadlabresultssAction,
    drillDownAction,
    clinicalSummaryAction,
    pthreesixtyAction,
    fhiraction,
    sortHeaderAction,
    sortordertype,
    sortPropsName,
    title,
  } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Name');

  useEffect(() => {
    setOrder(sortordertype === 'desc' ? 'desc' : 'asc');
    // setOrder(sortordertype);
    setOrderBy(!sortPropsName ? 'Date' : sortPropsName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSortHandler = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    sortHeaderAction({ sortparameters: { headername: property, order: isDesc ? 'asc' : 'desc' } });
  };

  return (
    <TableContainer className={classes.table}>
      <Table stickyHeader>
        {headings && (
          <TableHead>
            <TableRow>
              {headings.map((heading, i) => (
                <TableCell
                  key={`table-heading-${i}`}
                  className={classes.tablecell}
                  sortDirection={orderBy === heading ? order : false}
                >
                  {heading === t('Date') || heading === t('Time') || heading === t('Name') ? (
                    <TableSortLabel
                      active={orderBy === heading || heading === t('Date')}
                      direction={order}
                      onClick={() => createSortHandler(heading)}
                      IconComponent={heading === t('Date') ? SortIcon : ArrowDownwardIcon}
                    >
                      {heading.render ? heading.render() : heading}
                    </TableSortLabel>
                  ) : heading.render ? (
                    heading.render()
                  ) : (
                    heading
                  )}
                </TableCell>
              ))}
              {inlineEditAction && toggleField && (
                <TableCell className={classes.tablecell}>Status</TableCell>
              )}
              {calendarAction && <TableCell className={classes.tablecell}>Calendar</TableCell>}
              {editAction && <TableCell className={classes.tablecell}>Actions</TableCell>}
              {viewFeedbackDetailsAction && (
                <TableCell className={classes.tablecell}>{t('Actions')}</TableCell>
              )}
              {viewAdminConsultationDetailsAction && (
                <TableCell className={classes.tablecell}>Actions</TableCell>
              )}
              {!viewFeedbackDetailsAction && title !== 'Feedbacks' && (
                <TableCell className={classes.tablecell}>Notes</TableCell>
              )}
              {deleteAction && <TableCell className={classes.tablecell}>Delete</TableCell>}
              {clinicalSummaryAction && (
                <TableCell className={classes.tablecell}>Appointments/Requests</TableCell>
              )}
              {pthreesixtyAction && <TableCell className={classes.tablecell}>P360</TableCell>}
              {fhiraction && <TableCell className={classes.tablecell}>FHIR</TableCell>}
              {mergeAction && <TableCell className={classes.tablecell}>Merge</TableCell>}
              {uploadappointmentsAction && (
                <TableCell className={classes.tablecell}>Upload Appointments</TableCell>
              )}
              {uploadlabresultssAction && (
                <TableCell className={classes.tablecell}>Upload Lab Results</TableCell>
              )}

              {drillDownAction && <TableCell className={classes.tablecell}></TableCell>}
            </TableRow>
          </TableHead>
        )}
        <TableBody className={classes.tablecellbody}>{props.children}</TableBody>
      </Table>
    </TableContainer>
  );
};

function ListTable(props) {
  const { items, padded, feature } = props;

  return (
    <MyTable {...props}>
      {items &&
        items.map((item, index) => (
          <ListRow
            key={index}
            indexvalue={index}
            padded={padded}
            {...item}
            feature={feature}
            title={props.title}
          />
        ))}
    </MyTable>
  );
}

export default ListTable;
