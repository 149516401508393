import React from 'react';
import classes from './MultilaneIndicator.scss';
import { xScale } from '../../../Utilities';

class MultilaneIndicator extends React.PureComponent {
  render() {
    const {
      rows,
      rowSeconds,
      // eslint-disable-next-line no-unused-vars
      samples,
      height,
      stripWidth,
      is6l = false,
      duration
    } = this.props;

    const offsetLeft = 0.25;
    const offsetLeft6l = 0.2;


    const renderTick = () => {
      const content = [];
      const xTrans = xScale(rowSeconds, stripWidth, 1)(offsetLeft);
      for (let i = 0; i < rows; i += 1) {
        content.push(
          <g transform={`translate(${0}, ${height * i})`} key={i}>
            <g transform={`translate(${xTrans} ,0)`}>
              <g>
                <text
                  className={classes.secondsText}
                  dy=".71em"
                  y="9"
                  x="0"
                  style={{ textAnchor: 'start',  fontWeight: "bold",fontSize: "16px"}}
                >
                  {i * rowSeconds}s
                </text>
              </g>
            </g>
          </g>,
        );
      }
      return content;
    };
    const renderLeads = () => {
      const leads = {
        0: `Lead I Full ${duration ? duration / 1000 : ""}s strip`,
        1: "Lead II",
        2: "Lead III",
        3: "aVR",
        4: "aVL",
        5: "aVF",
      };
      const content = [];
      const xTrans = xScale(rowSeconds, stripWidth, 1)(offsetLeft6l);
      for (let i = 0; i < 6; i += 1) {
        content.push(
          <g transform={`translate(${0}, ${(height / 2) * i})`} key={i}>
            <g transform={`translate(${xTrans} ,0)`} className={classes.tick}>
              <g>
                <text
                  className={classes.secondsText}
                  dy=".71em"
                  y="9"
                  x="0"
                  style={{ textAnchor: 'start', fontWeight: "bold",fontSize: "16px"
                 }}
                >
                  {leads[i]}
                </text>
              </g>
            </g>
          </g>,
        );
      }
      return content;
    };

    return (
      <React.Fragment>
        {is6l ? (
          <g className={classes.ticks} transform={`translate(0, ${20})`}>
            {renderLeads()}
          </g>
        ) : (
          <g className={classes.ticks} transform={`translate(0, ${20})`}>
            {renderTick()}
          </g>
        )}
      </React.Fragment>
    );
  }
}

export default MultilaneIndicator;
