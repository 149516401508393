import React, { useEffect, useState } from 'react';
import { useStyles } from '../AudioStyle';
import { Box, Grid, Typography } from '@mui/material';
import { fontFamily } from '../../../../style/commonStyles';
import { black } from '../../../../style/colors';
import { useTranslation } from 'react-i18next';
import MuiTypography from 'components/Shared/Typography/MuiTypography';
import BasicBreadcrumbs from 'components/Shared/BreadCrumb/BreadCrumb';
import BottomLineTitle from 'components/Shared/Typography/BottomLineTitle';
import {
  CANCEL_MESSAGE,
  DOCTOR_ROUTES,
  LEAVE_MESSAGE,
  NETWORK_ISSUE,
  NETWORK_ISSUE_MESSAGE,
  REQUIRED_FIELD,
  REVIEW_ERROR,
  TRY_AGAIN_MESSAGE,
  USER_REGION,
} from 'constants/app';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SelectBox from 'components/Shared/SelectBox/SelectBox';
import CheckboxLabels from 'components/Shared/CheckBox/CheckBox';
import TextField from '@mui/material/TextField';
import MuiButton from 'components/Shared/Buttons/MuiButton';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import moment from 'moment';
import {
  cmToFeet,
  convertArrayToObject,
  getReducedEle,
  remainingCharacterCount,
} from 'utilities/Utilities';
import AlertDialog from 'components/Shared/Dialog/Dialog';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LANGUAGE_MAPPER } from 'constants/LanguageMapper';
import {
  API_RECORDING_AREA_KEYNAME,
  API_S3_URL_KEYNAME,
  AUDIO_INBOX,
  CAROTID_EVALUATION,
  CHECKBOX_TEXT,
  CNACLE_REQUEST_FIRST,
  CNACLE_REQUEST_SECOND,
  COMPLETED,
  HEART_EVALUATION,
  HEART_SOUNDS,
  INCOMING,
  NOT_STARTED,
  OVERDUE,
  carotidEvaluation,
  heartEvaluation,
  reviewStatus,
} from 'constants/audio';
import { pantoneRed } from '../../../../style/colors';
import Spectrogram from 'components/Spectrogram/Spectrogram';
import { updateStatus } from 'store/actions/audio';
import { ERROR } from 'constants/audio';
import { CRP_REGION } from 'constants/ekgReportMenuOptionsConstants';
import { DATE_FORMAT_WITH_TIME } from '../Constants/constant';

const ReviewAudio = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, request } = useParams();

  const isUsRegion = localStorage.getItem(CRP_REGION) === USER_REGION;

  const [isNetworkError, setIsNetworkError] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [recordingObj, setRecordingObj] = useState(null);

  const { firstName, lastName } = useSelector((state) => state?.user?.profile);
  const patientInfo = useSelector((state) => state?.audio?.patient);
  const audioData = useSelector((state) => state.audio);

  useEffect(() => {
    dispatch(actions.getAudioPatientData(id, request === INCOMING ? false : true));
  }, []);

  // API call toet the particular patient Information
  useEffect(() => {
    function getPatientDataAndUpdateStatus() {
      if (patientInfo) {
        if (
          (patientInfo?.stethioReviewDetails?.status === NOT_STARTED ||
            patientInfo?.stethioReviewDetails?.status === OVERDUE) &&
          request === INCOMING
        ) {
          dispatch(updateStatus(patientInfo?.stethioPatientDetails?.id));
        }
        if (audioData?.error === true || patientInfo?.stethioReviewDetails?.status === ERROR) {
          history.push(DOCTOR_ROUTES.TELEKARDIA_AUDIO);
        }
      }
    }
    getPatientDataAndUpdateStatus();
  }, [patientInfo]);

  // if the patientInfo is empty then push it the audio table view again
  useEffect(() => {
    // These constants belong to API response of recording data
    if (!recordingObj && patientInfo?.recordings?.length > 0) {
      setRecordingObj(
        convertArrayToObject(
          patientInfo?.recordings,
          API_RECORDING_AREA_KEYNAME,
          API_S3_URL_KEYNAME,
        ),
      );
    }
  }, [audioData]);

  //========= Play and pause mode between two audio instances =============//
  const [activeInstance, setActiveInstance] = useState(1); //default first audio instance will be active.
  /**
   * this function will be triggered when an instance is played.
   * will set that instance to active
   * other instances will set to inactive and audio will be paused.
   */
  function pausePlayBetweenMultipleAudioInstances(instance) {
    setActiveInstance(instance);
  }
  //======== end ===========//

  // Open the close modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close the close modal
  const handleClose = () => {
    setOpen(false);
  };

  // To check from the handlechange function for tabs in heart mitral, heart pulmonary, carotid left and right, if the checkbox for heart and carotid should be automatically clicked or not
  const followUpFunc = (tab, value) => {
    if ([reviewStatus?.HEART_PULMONARY, reviewStatus?.HEART_MITRAL].includes(value))
      fillCheckBox(
        tab,
        value,
        reviewStatus?.HEART_MITRAL,
        reviewStatus?.HEART_PULMONARY,
        reviewStatus?.HEART_FOLLOWUP,
      );
    else
      fillCheckBox(
        tab,
        value,
        reviewStatus?.CAROTID_RIGHT,
        reviewStatus?.CAROTID_LEFT,
        reviewStatus?.CAROTID_FOLLOWUP,
      );
  };

  // Logic to check if checkbox for heart and carotid checkbox should be automatically checked or not
  const fillCheckBox = (tab, value, firstData, secondData, storeValue) => {
    if (
      (tab === reviewStatus?.NORMAL &&
        value === firstData &&
        patientInfo?.stethioReviewDetails?.[`${secondData}`] === reviewStatus?.NORMAL) ||
      (value === secondData &&
        tab === reviewStatus?.NORMAL &&
        patientInfo?.stethioReviewDetails?.[`${firstData}`] === reviewStatus?.NORMAL)
    ) {
      // should be unchecked if and only if both the tabs are normal, else checked
      dispatch(actions.changePatientInfo(storeValue, false));
    } else {
      dispatch(actions.changePatientInfo(storeValue, true));
    }
  };

  // API to cancel request
  const cancelRequest = () => {
    dispatch(actions.cancelRequest(id));
    history.push(DOCTOR_ROUTES.TELEKARDIA_AUDIO);
  };
  const closeRequest = () => {
    request === INCOMING
      ? history.push(`${DOCTOR_ROUTES.TELEKARDIA_AUDIO}/0`)
      : history.push(`${DOCTOR_ROUTES.TELEKARDIA_AUDIO}/1`);
  };

  // API to handle submit Review for patient
  const handleSubmit = () => {
    if (!window.navigator.onLine) {
      setIsNetworkError(true);
      return;
    }
    const data = {
      heartPulmonary: patientInfo?.stethioReviewDetails?.heartPulmonary,
      heartMitral: patientInfo?.stethioReviewDetails?.heartMitral,
      heartFollowup: patientInfo?.stethioReviewDetails?.heartFollowup,
      heartNotes: patientInfo?.stethioReviewDetails?.heartNotes,
      carotidLeft: patientInfo?.stethioReviewDetails?.carotidLeft,
      carotidRight: patientInfo?.stethioReviewDetails?.carotidRight,
      carotidFollowup: patientInfo?.stethioReviewDetails?.carotidFollowup,
      carotidNotes: patientInfo?.stethioReviewDetails?.carotidNotes,
      stethioPatientId: id,
      memberFirstName: firstName,
      memberLastName: lastName,
    };
    dispatch(actions.saveReview(JSON.stringify(data))).then((res) => {
      if (res?.status === 200) {
        history.push(DOCTOR_ROUTES.TELEKARDIA_AUDIO);
      }
    });
  };

  // Handle change function for Heart and Carotid data
  const handleData = (tab, value) => {
    dispatch(actions.changePatientInfo(value, tab));
    if (
      value === reviewStatus?.HEART_PULMONARY ||
      value === reviewStatus?.HEART_MITRAL ||
      value === reviewStatus?.CAROTID_LEFT ||
      value === reviewStatus?.CAROTID_RIGHT
    )
      followUpFunc(tab, value);
    if (value === reviewStatus?.HEART_PULMONARY || value === reviewStatus?.HEART_MITRAL) {
      dispatch(actions.changePatientInfo(reviewStatus?.HEART_NOTES, `${getNotes(value, tab)}`));
    }
    if (value === reviewStatus?.CAROTID_LEFT || value === reviewStatus?.CAROTID_RIGHT) {
      dispatch(
        actions.changePatientInfo(reviewStatus?.CAROTID_NOTES, `${getCarotidNotes(value, tab)}`),
      );
    }
    setDisabled();
  };

  // Check if field is not empty also length is greater than 0
  // const checkNotes = (text) => {
  //   if(text === "") return false;
  //   else if((1000 - text?.length) < 1) return false
  //   else return true;
  // }

  // diabled submit buttion on the basis of heart and carotid values
  const setDisabled = () => {
    if (
      !patientInfo?.stethioReviewDetails?.heartPulmonary ||
      !patientInfo?.stethioReviewDetails?.heartMitral ||
      !patientInfo?.stethioReviewDetails?.carotidLeft ||
      !patientInfo?.stethioReviewDetails?.carotidRight ||
      !patientInfo?.stethioReviewDetails?.heartNotes ||
      !patientInfo?.stethioReviewDetails?.carotidNotes
    ) {
      return true;
    }
    return false;
  };

  // Convert the shorthand form of language to full form
  const getLanguage = (language) => {
    let data = LANGUAGE_MAPPER?.filter((lan) => {
      if (lan?.code === language) return lan;
    });
    if (data) {
      return data[0]?.name;
    }
    return language;
  };

  // Automatically populate the data in TExtfields on the basis of tabs we click in heart Section
  const getNotes = (value, tab) => {
    let heartPulmaryData =
      value === reviewStatus?.HEART_PULMONARY
        ? tab
        : patientInfo?.stethioReviewDetails?.heartPulmonary
        ? patientInfo?.stethioReviewDetails?.heartPulmonary
        : '';
    let heartMitralData =
      value === reviewStatus?.HEART_MITRAL
        ? tab
        : patientInfo?.stethioReviewDetails?.heartMitral
        ? patientInfo?.stethioReviewDetails?.heartMitral
        : '';

    if (heartPulmaryData && heartMitralData) {
      return (
        heartEvaluation[`${heartPulmaryData}-${heartMitralData}`][0] +
        ' ' +
        heartEvaluation[`${heartPulmaryData}-${heartMitralData}`][1]
      );
    } else if (heartPulmaryData && !heartMitralData) {
      return heartEvaluation[`${heartPulmaryData}P`];
    } else if (!heartPulmaryData && heartMitralData) {
      return heartEvaluation[`${heartMitralData}M`];
    } else {
      return '';
    }
  };

  // Automatically populate the data in TExtfields on the basis of tabs we click in carotid Section
  const getCarotidNotes = (value, tab) => {
    let carotidLeftData =
      value === reviewStatus?.CAROTID_LEFT
        ? tab
        : patientInfo?.stethioReviewDetails?.carotidLeft
        ? patientInfo?.stethioReviewDetails?.carotidLeft
        : '';
    let carotidRightData =
      value === reviewStatus?.CAROTID_RIGHT
        ? tab
        : patientInfo?.stethioReviewDetails?.carotidRight
        ? patientInfo?.stethioReviewDetails?.carotidRight
        : '';
    if (carotidLeftData && carotidRightData) {
      return (
        carotidEvaluation[`${carotidLeftData}-${carotidRightData}`][0] +
        ' ' +
        carotidEvaluation[`${carotidLeftData}-${carotidRightData}`][1]
      );
    } else if (carotidLeftData && !carotidRightData) {
      return carotidEvaluation[`${carotidLeftData}P`];
    } else if (!carotidLeftData && carotidRightData) {
      return carotidEvaluation[`${carotidRightData}M`];
    } else {
      return '';
    }
  };
  return (
    <Box className={classes.container}>
      <Grid>
        <Grid>
          <Grid item style={{ marginTop: '2.5rem' }}>
            <MuiTypography
              fontSize="24px"
              fontFamily={fontFamily}
              lineHeight="28px"
              letterSpacing="0.25px"
              fontWeight={500}
              color={black}
            >
              {t(AUDIO_INBOX)}
            </MuiTypography>
            <BottomLineTitle bg={'#1976D2'} />
            <div className={classes.breadCrumbStyle}>
              {' '}
              <BasicBreadcrumbs
                data={[
                  { type: 'image', url: DOCTOR_ROUTES.TELEKARDIA_DASHBOARD, src: 'Home' },
                  { type: 'text', name: AUDIO_INBOX, url: DOCTOR_ROUTES.TELEKARDIA_AUDIO },
                  {
                    type: 'text',
                    name: `${moment(patientInfo?.stethioPatientDetails?.createdAt).format(
                      'YYYY-MM-DD HH:mm:ss',
                    )}`,
                  },
                ]}
              />
            </div>
            <div className={classes.tableParent}>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => (request === INCOMING ? cancelRequest() : closeRequest())}
                />
                <MuiTypography
                  fontSize="20px"
                  fontFamily={fontFamily}
                  lineHeight="32px"
                  letterSpacing="0.15px"
                  fontWeight={500}
                  color={black}
                >
                  {t(
                    `${patientInfo?.stethioPatientDetails?.lastName}, ${patientInfo?.stethioPatientDetails?.firstName}`,
                  )}
                </MuiTypography>
              </div>
              <div style={{ display: 'flex', columnGap: '25px', marginTop: '1.3vh' }}>
                <div className={classes.patientInfo}>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'body2'}
                    fontWeight={400}
                  >
                    DOB
                  </MuiTypography>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'subtitle2'}
                    fontWeight={600}
                  >
                    {moment(patientInfo?.stethioPatientDetails?.dob).format('MM-DD-YYYY')}
                  </MuiTypography>
                </div>
                <div className={classes.patientInfo}>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'body2'}
                    fontWeight={400}
                  >
                    Age
                  </MuiTypography>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'subtitle2'}
                    fontWeight={600}
                  >
                    {moment().diff(
                      moment(patientInfo?.stethioPatientDetails?.dob, 'YYYY-MM-DD'),
                      'years',
                    )}
                  </MuiTypography>
                </div>
                <div className={classes.patientInfo}>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'body2'}
                    fontWeight={400}
                  >
                    Sex
                  </MuiTypography>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'subtitle2'}
                    fontWeight={600}
                  >
                    {patientInfo?.stethioPatientDetails?.sex.toUpperCase()}
                  </MuiTypography>
                </div>
                <div className={classes.patientInfo}>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'body2'}
                    fontWeight={400}
                  >
                    Height
                  </MuiTypography>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'subtitle2'}
                    fontWeight={600}
                  >
                    {isUsRegion
                      ? cmToFeet(patientInfo?.stethioPatientDetails?.height)
                      : `${(patientInfo?.stethioPatientDetails?.height / 100).toPrecision(2)} m`}
                    {}
                  </MuiTypography>
                </div>
                <div className={classes.patientInfo}>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'body2'}
                    fontWeight={400}
                  >
                    Weight
                  </MuiTypography>
                  <MuiTypography
                    color={'#00000099'}
                    fontSize="14px"
                    fontFamily={fontFamily}
                    variant={'subtitle2'}
                    fontWeight={600}
                  >
                    {isUsRegion
                      ? `${Math.floor(patientInfo?.stethioPatientDetails?.weight * 2.2046)} lbs`
                      : `${patientInfo?.stethioPatientDetails?.weight} kg`}
                  </MuiTypography>
                </div>
              </div>
              <div className={classes.tableAlign}>
                <div className={classes.tableParent}>
                  <div style={{ display: 'flex', columnGap: '25px' }}>
                    <div className={classes.patientInfo}>
                      <MuiTypography
                        color={'#00000099'}
                        fontSize="14px"
                        fontFamily={fontFamily}
                        variant={'body2'}
                        fontWeight={400}
                      >
                        Report ID:
                      </MuiTypography>
                      <MuiTypography
                        color={'#00000099'}
                        fontSize="14px"
                        fontFamily={fontFamily}
                        variant={'subtitle2'}
                        fontWeight={600}
                      >
                        {patientInfo?.stethioPatientDetails?.reportId}
                      </MuiTypography>
                    </div>
                    <div className={classes.patientInfo}>
                      <MuiTypography
                        color={'#00000099'}
                        fontSize="14px"
                        fontFamily={fontFamily}
                        variant={'body2'}
                        fontWeight={400}
                      >
                        Data Recorded:
                      </MuiTypography>
                      <MuiTypography
                        color={'#00000099'}
                        fontSize="14px"
                        fontFamily={fontFamily}
                        variant={'subtitle2'}
                        fontWeight={600}
                      >
                        {moment(patientInfo?.stethioPatientDetails?.recordedAt).format(
                          'YYYY-MM-DD HH:mm:ss A',
                        )}
                      </MuiTypography>
                    </div>
                  </div>
                  <div className={classes.tableParent} style={{ background: 'white' }}>
                    <MuiTypography
                      color={'#000000DE'}
                      fontWeight={600}
                      fontSize="15px"
                      fontFamily={fontFamily}
                      variant={'subtitle1'}
                    >
                      {HEART_SOUNDS}
                    </MuiTypography>
                    <Spectrogram
                      canvasHeader={reviewStatus.PULMANORY}
                      fileURL={patientInfo?.recordings && recordingObj?.['pulmonary']}
                      canvas={'Heart1'}
                      isActive={activeInstance == 1}
                      pausePlayBetweenMultipleAudioInstances={() =>
                        pausePlayBetweenMultipleAudioInstances(1)
                      }
                    />
                    <Spectrogram
                      canvasHeader={reviewStatus.MITRAL}
                      fileURL={patientInfo?.recordings && recordingObj?.['mitral']}
                      canvas={'Heart2'}
                      isActive={activeInstance == 2}
                      pausePlayBetweenMultipleAudioInstances={() =>
                        pausePlayBetweenMultipleAudioInstances(2)
                      }
                    />
                  </div>
                  <div className={classes.tableParent} style={{ background: 'white' }}>
                    <MuiTypography
                      color={'#000000DE'}
                      fontWeight={600}
                      fontSize="15px"
                      fontFamily={fontFamily}
                      variant={'subtitle1'}
                    >
                      Vascular Sounds
                    </MuiTypography>
                    <Spectrogram
                      canvasHeader={reviewStatus.LEFT_CAROTID}
                      fileURL={patientInfo?.recordings && recordingObj?.['left_carotid']}
                      canvas={'Vascular1'}
                      isActive={activeInstance == 3}
                      pausePlayBetweenMultipleAudioInstances={() =>
                        pausePlayBetweenMultipleAudioInstances(3)
                      }
                    />
                    <Spectrogram
                      canvasHeader={reviewStatus.RIGHT_CAROTID}
                      fileURL={patientInfo?.recordings && recordingObj?.['right_carotid']}
                      canvas={'Vascular2'}
                      isActive={activeInstance == 4}
                      pausePlayBetweenMultipleAudioInstances={() =>
                        pausePlayBetweenMultipleAudioInstances(4)
                      }
                    />
                  </div>
                </div>
                <div className={classes.tableParent} style={{ height: 'fitContent' }}>
                  <div>
                    <span className={classes.boxStyle}>StethIO</span>
                  </div>
                  <div className={classes.tableParent} style={{ background: 'white' }}>
                    <MuiTypography
                      color={'#000000DE'}
                      fontWeight={600}
                      fontSize="15px"
                      fontFamily={fontFamily}
                      variant={'subtitle1'}
                    >
                      Patient Provided Data
                    </MuiTypography>
                    <div className={classes.notes}>
                      <div className={classes.note}>
                        <div style={{ color: '#00000099' }}>Notes: </div>
                        <div> {patientInfo?.stethioPatientDetails?.notes}</div>
                      </div>
                      <div className={classes.note}>
                        <div style={{ color: '#00000099' }}>Tags: </div>
                        <div> {getReducedEle(patientInfo?.stethioPatientDetails?.tags)}</div>
                      </div>
                      <div className={classes.note}>
                        <div style={{ color: '#00000099' }}>Language: </div>
                        <div>{getLanguage(patientInfo?.stethioPatientDetails?.language)}</div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.tableParent} style={{ background: 'white' }}>
                    <div className={classes.flexBox}>
                      <MuiTypography
                        color={'#000000DE'}
                        fontWeight={600}
                        fontSize="15px"
                        fontFamily={fontFamily}
                        variant={'subtitle1'}
                      >
                        {HEART_EVALUATION}
                      </MuiTypography>
                      <MuiTypography
                        fontSize={'14px'}
                        fontFamily={fontFamily}
                        lineHeight={'16px'}
                        color={pantoneRed}
                      >
                        {REQUIRED_FIELD}
                      </MuiTypography>
                    </div>
                    <div style={{ marginTop: '1vh' }}>
                      <MuiTypography
                        color={'#000000DE'}
                        fontWeight={400}
                        fontSize="13px"
                        fontFamily={fontFamily}
                        variant={'body2'}
                      >
                        {reviewStatus?.PULMANORY}
                      </MuiTypography>
                      <SelectBox
                        value={patientInfo?.stethioReviewDetails?.heartPulmonary}
                        getValue={(tab) =>
                          request === INCOMING && handleData(tab, reviewStatus?.HEART_PULMONARY)
                        }
                        tabs={[
                          { tab: reviewStatus?.NORMAL, color: '#dddddd' },
                          { tab: reviewStatus?.MURMUR, color: '#dddddd' },
                          { tab: reviewStatus.INAUDIBLE, color: '#dddddd' },
                        ]}
                      />
                    </div>
                    <div style={{ marginTop: '1vh' }}>
                      <MuiTypography
                        color={'#000000DE'}
                        fontWeight={400}
                        fontSize="13px"
                        fontFamily={fontFamily}
                        variant={'body2'}
                      >
                        {reviewStatus?.MITRAL}
                      </MuiTypography>
                      <SelectBox
                        value={patientInfo?.stethioReviewDetails?.heartMitral}
                        getValue={(tab) =>
                          request === INCOMING && handleData(tab, reviewStatus?.HEART_MITRAL)
                        }
                        tabs={[
                          { tab: reviewStatus?.NORMAL, color: '#dddddd' },
                          { tab: reviewStatus?.MURMUR, color: '#dddddd' },
                          { tab: reviewStatus.INAUDIBLE, color: '#dddddd' },
                        ]}
                      />
                    </div>
                    <div style={{ margin: '1.5vh 0' }}>
                      <CheckboxLabels
                        getValue={(tab) =>
                          request === INCOMING && handleData(tab, reviewStatus?.HEART_FOLLOWUP)
                        }
                        checked={patientInfo?.stethioReviewDetails?.heartFollowup}
                        label={CHECKBOX_TEXT}
                      />
                    </div>
                    <div style={{ margin: '1.5vh 0' }}>
                      <TextField
                        sx={{ width: '100%' }}
                        id="outlined-basic"
                        label="Provider Notes"
                        variant="outlined"
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1000 }}
                        value={`${
                          patientInfo?.stethioReviewDetails?.heartNotes === null
                            ? ''
                            : patientInfo?.stethioReviewDetails?.heartNotes
                        }`}
                        onChange={(event) =>
                          request === INCOMING &&
                          handleData(event.target.value, reviewStatus?.HEART_NOTES)
                        }
                      />
                    </div>
                    {patientInfo?.stethioReviewDetails?.heartNotes?.length > 0 && (
                      <MuiTypography
                        variant={'p'}
                        className={classes.notesLimit}
                        fontFamily={fontFamily}
                        textAlign={'end'}
                        color={pantoneRed}
                        fontSize={'14px'}
                        position={'relative'}
                        top={'-10px'}
                        lineHeight={'16px'}
                      >
                        {remainingCharacterCount(patientInfo?.stethioReviewDetails?.heartNotes)}
                      </MuiTypography>
                    )}
                  </div>
                  <div className={classes.tableParent} style={{ background: 'white' }}>
                    <div className={classes.flexBox}>
                      <MuiTypography
                        color={'#000000DE'}
                        fontWeight={600}
                        fontSize="15px"
                        fontFamily={fontFamily}
                        variant={'subtitle1'}
                      >
                        {CAROTID_EVALUATION}
                      </MuiTypography>
                      <MuiTypography
                        fontSize={'14px'}
                        fontFamily={fontFamily}
                        lineHeight={'16px'}
                        color={pantoneRed}
                      >
                        {REQUIRED_FIELD}
                      </MuiTypography>
                    </div>

                    <div style={{ marginTop: '1vh' }}>
                      <MuiTypography
                        color={'#000000DE'}
                        fontWeight={400}
                        fontSize="13px"
                        fontFamily={fontFamily}
                        variant={'body2'}
                      >
                        {reviewStatus?.LEFT}
                      </MuiTypography>
                      <SelectBox
                        value={patientInfo?.stethioReviewDetails?.carotidLeft}
                        getValue={(tab) =>
                          request === INCOMING && handleData(tab, reviewStatus?.CAROTID_LEFT)
                        }
                        tabs={[
                          { tab: reviewStatus?.NORMAL, color: '#dddddd' },
                          { tab: reviewStatus?.BRUIT, color: '#dddddd' },
                          { tab: reviewStatus.INAUDIBLE, color: '#dddddd' },
                        ]}
                      />
                    </div>
                    <div style={{ marginTop: '1vh' }}>
                      <MuiTypography
                        color={'#000000DE'}
                        fontWeight={400}
                        fontSize="13px"
                        fontFamily={fontFamily}
                        variant={'body2'}
                      >
                        {reviewStatus?.RIGHT}
                      </MuiTypography>
                      <SelectBox
                        value={patientInfo?.stethioReviewDetails?.carotidRight}
                        getValue={(tab) =>
                          request === INCOMING && handleData(tab, reviewStatus?.CAROTID_RIGHT)
                        }
                        tabs={[
                          { tab: reviewStatus?.NORMAL, color: '#dddddd' },
                          { tab: reviewStatus?.BRUIT, color: '#dddddd' },
                          { tab: reviewStatus.INAUDIBLE, color: '#dddddd' },
                        ]}
                      />
                    </div>
                    <div style={{ margin: '1.5vh 0' }}>
                      <CheckboxLabels
                        getValue={(tab) =>
                          request === INCOMING && handleData(tab, reviewStatus?.CAROTID_FOLLOWUP)
                        }
                        checked={patientInfo?.stethioReviewDetails?.carotidFollowup}
                        label={CHECKBOX_TEXT}
                      />
                    </div>
                    <div style={{ margin: '1.5vh 0' }}>
                      <TextField
                        id="outlined-helperText"
                        label="Provider Notes"
                        value={`${
                          patientInfo?.stethioReviewDetails?.carotidNotes === null
                            ? ''
                            : patientInfo?.stethioReviewDetails?.carotidNotes
                        }`}
                        sx={{ width: '100%' }}
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 1000 }}
                        onChange={(event) =>
                          request === INCOMING &&
                          handleData(event.target.value, reviewStatus?.CAROTID_NOTES)
                        }
                      />
                      {/* <TextField sx = {{width:"100%"}} id="outlined-basic" label="Provider Notes" variant="outlined" defaultValue={patientInfo?.stethioReviewDetails?.carotidNotes} value = {patientInfo?.stethioReviewDetails?.carotidNotes} /> */}
                    </div>
                    {patientInfo?.stethioReviewDetails?.carotidNotes?.length > 0 && (
                      <MuiTypography
                        variant={'p'}
                        className={classes.notesLimit}
                        fontFamily={fontFamily}
                        textAlign={'end'}
                        color={pantoneRed}
                        fontSize={'14px'}
                        position={'relative'}
                        top={'-10px'}
                        lineHeight={'16px'}
                      >
                        {remainingCharacterCount(patientInfo?.stethioReviewDetails?.carotidNotes)}
                      </MuiTypography>
                    )}
                  </div>
                  {request === COMPLETED && (
                    <Typography
                      variant="body1"
                      className={classes.reviewAudit}
                    >{`Reviewed summitted ${moment(
                      patientInfo?.stethioReviewDetails?.reviewEndAt,
                    ).format(DATE_FORMAT_WITH_TIME)} by Dr.${
                      patientInfo?.stethioReviewDetails?.memberLastName
                    }, ${patientInfo?.stethioReviewDetails?.memberFirstName}.`}</Typography>
                  )}
                  <div className={classes.buttonGroup}>
                    {request === COMPLETED ? (
                      <MuiButton variant="outlined" text={'Close'} onClick={closeRequest} />
                    ) : (
                      <>
                        <MuiButton
                          variant="outlined"
                          text={CANCEL_MESSAGE}
                          onClick={handleClickOpen}
                        />
                        <MuiButton
                          variant="contained"
                          text={'Submit'}
                          disabled={request === COMPLETED || setDisabled()}
                          onClick={handleSubmit}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {open && (
        <AlertDialog
          open={open}
          onClickFirstButton={() => setOpen(false)}
          onClickSecondButton={cancelRequest}
          buttonNameFirst={CANCEL_MESSAGE}
          buttonSecondName={LEAVE_MESSAGE}
          handleClose={handleClose}
          title={REVIEW_ERROR}
          body={
            <p>
              {CNACLE_REQUEST_FIRST}
              <br />
              {CNACLE_REQUEST_SECOND}
            </p>
          }
        />
      )}
      {isNetworkError && (
        <AlertDialog
          open={isNetworkError}
          onClickFirstButton={() => setIsNetworkError(false)}
          onClickSecondButton={handleSubmit}
          buttonNameFirst={CANCEL_MESSAGE}
          buttonSecondName={TRY_AGAIN_MESSAGE}
          handleClose={() => setIsNetworkError(false)}
          title={NETWORK_ISSUE}
          body={<p>{NETWORK_ISSUE_MESSAGE}</p>}
        />
      )}
    </Box>
  );
};

export default ReviewAudio;
