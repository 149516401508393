import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import React from 'react';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  border: '1px solid #c1c1c1',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '16px',
};

const ModalComponent = (props) => {
  const {
    headerText,
    modalText,
    subText = '',
    open,
    handleClose,
    cancelButtonText,
    confirmButtonText,
    confirmHandler,
    confirmPayload,
  } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            sx={{ mt: 1, fontFamily: 'Work Sans', fontSize: 24, fontWeight: 600 }}
            component="h2"
          >
            {headerText}
          </Typography>
          <Typography
            sx={{ mt: 1, fontFamily: 'Work Sans', fontSize: 16, color: 'rgba(0, 0, 0, 0.6)' }}
          >
            {modalText}
          </Typography>
          <Typography
            sx={{ mt: 1, fontFamily: 'Work Sans', fontSize: 16, color: 'rgba(0, 0, 0, 0.6)' }}
          >
            {subText}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '25px',
            }}
          >
            <Button variant="outlined" size="large" onClick={handleClose}>
              {cancelButtonText}
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ backgroundColor: '#CC3D3F' }}
              onClick={() => confirmHandler(confirmPayload)}
            >
              {confirmButtonText}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalComponent;
