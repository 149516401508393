import React from 'react';

const SectionFooter = ({ buttonInputProps = null, footerLayout = 'flex-end' }) => {
  return (
    <div style={{ display: 'flex', justifyContent: footerLayout, columnGap: 10 }}>
      {buttonInputProps}
    </div>
  );
};

export default SectionFooter;
