import React from 'react';
import styles from './NotFoundPage.module.css';
import DashboardIcon from 'assets/images/poll.svg';
import { useHistory } from 'react-router';
import { ROUTES } from 'constants/app';

const NotFoundPage = () => {
  const history = useHistory();
  const handleRouteToDashboard = () => {
    localStorage.setItem("navText", "");
    localStorage.setItem("navChildText", "");
    history.push(ROUTES.ROOT);
  };
  return (
    <div data-page="not-found-page" className={styles.NotFoundPage}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection:"column"
        }}
      >
        <h1>404</h1>
        <h2>Sorry.</h2>
        <p>Looks like something went wrong.</p>
        <p>Head back to the Dashboard.</p>
        <button className={styles.dashboardBtn} onClick={handleRouteToDashboard}>
          <DashboardIcon />
          Dashboard
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
