import React from 'react';

import KardiaExpandLogo from 'assets/images/logos/kardia_logo.svg';
import KardiaCollapseLogo from 'assets/images/logos/logo_collapse.svg';
import CollapseIcon from 'assets/images/logos/menu_open.svg';
import ExpandIcon from 'assets/images/icons/menu.svg';
import styles from '../NavigationSidebar.module.css';
import { AUTH_TYPE } from 'constants/app';

const TeamProfile = (props) => {
  const { me, sidebarIsCollapsed, handleToggleNavigationSidebar } = props;
  const { data } = me;
  const { isAdmin } = data.profile.permissions;
  const { userType } = data.chatProfile;

  const profileClassName = sidebarIsCollapsed ? 'profileCollapsed' : 'profile';
  const mainLogoClassName = sidebarIsCollapsed ? 'mainLogoCollapsed' : 'mainLogo';

  return (
    <React.Fragment>
      <div className={styles[mainLogoClassName]}>
        {sidebarIsCollapsed ? (
          <ExpandIcon
            onClick={() => {
              handleToggleNavigationSidebar();
            }}
            style={{
              fill: '#1976D2',
            }}
          />
        ) : (
          <React.Fragment>
            <span>
              <CollapseIcon
                onClick={() => {
                  handleToggleNavigationSidebar();
                }}
                style={{
                  fill: '#1976D2',
                  width: '30px',
                  height: '30px',
                }}
              />
            </span>
          </React.Fragment>
        )}
      </div>
      <div className={styles[profileClassName]}>
        {data ? (
          <div>
            {sidebarIsCollapsed ? (
              <KardiaCollapseLogo style={{ width: 60, height: 35 }} />
            ) : (
              <KardiaExpandLogo />
            )}
            {!sidebarIsCollapsed && (
              <div>
                <h2 className={styles.teamName}>
                  {isAdmin ? 'ADMIN' : userType === AUTH_TYPE.DOCTOR ? 'CLINICIAN' : 'COACH'}
                  <br />
                </h2>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.loader}>{/* <Loading /> */}</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TeamProfile;
