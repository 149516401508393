export const acuityMapper = (acuityData) => {
  acuityData.forEach((item) => {
    if (item.acuity === "stable") {
      item.acuityValue = "Stable";
    }
    if (item.acuity === "serious") {
      item.acuityValue = "Serious";
    }
    if (item.acuity === "not_acceptable") {
      item.acuityValue = "Not Acceptable";
    }
    if (item.acuity === "needs_review") {
      item.acuityValue = "Needs Review";
    }
  });
  return acuityData;
};

export const acuitySingleKeyMapper = (acuityKey) => {
  let acuityValue;
  if (acuityKey === "stable") {
    acuityValue = "Stable";
  } else if (acuityKey === "serious") {
    acuityValue = "Serious";
  } else if (acuityKey === "not_acceptable") {
    acuityValue = "Not Acceptable";
  } else if (acuityKey === "needs_review") {
    acuityValue = "Needs Review";
  }
  return acuityValue;
};
