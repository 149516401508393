import { Typography } from '@mui/material';
import React from 'react';
import SponsorPopover from 'screens/coach/MemberList/components/SponsorPopover';

function CustomText(props) {
  const { params, color, weight } = props;
  return (
    <Typography
      variant="body1"
      style={{ fontWeight: weight, color: params.row.status === 3 ? 'lightgray' : color }}
    >
      {params?.row?.lastName.concat(params?.row?.firstName).length > 20 ? (
        <SponsorPopover
          item={`${params?.row?.lastName}, ${params?.row?.firstName}`}
          length={20}
          style={{ fontWeight: weight }}
        />
      ) : (
        `${params?.row?.lastName}, ${params?.row?.firstName}`
      )}
    </Typography>
  );
}

export default CustomText;
