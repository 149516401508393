import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EcgLoader from 'components/Shared/EcgLoader';
import { getUser, getUserStart } from 'store/actions/user';
import { checkAuthentication, clearResetIdleTime, getToken } from './AuthUtilities';
import { ChatProvider } from 'providers/chat';
import Logout from 'components/Logout/Logout';

const withAuth = (parentProps) =>
  function (Comp) {
    return function HasAuth(props) {
      const { profile, chatProfile, team, isAuthenticated, login, loading } = useSelector(
        (state) => state.user,
      );

      const dispatch = useDispatch();

      useEffect(() => {
        dispatch(checkAuthentication());
      }, [dispatch]);

      useEffect(() => {
        if (!login && !profile && !loading) {
          if (getToken()) {
            dispatch(getUserStart());
            dispatch(getUser());
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [login]);

      if (isAuthenticated) {
        if (profile) {
          parentProps.userstate.data.profile = profile;
          parentProps.userstate.data.teams = [team];
          return (
            <ChatProvider
              userId={chatProfile?.kccChatUserID}
              password={chatProfile?.kccChatPassword}
            >
              <Comp {...props} {...parentProps} />
            </ChatProvider>
          );
        }
        return <EcgLoader />;
      }
      clearResetIdleTime();
      return (
        <ChatProvider userId={chatProfile?.kccChatUserID} password={chatProfile?.kccChatPassword}>
          <Logout />
        </ChatProvider>
      );
    };
  };

export default withAuth;
