import React from 'react';
import classes from './Loading.module.css';
import LoadingIcon from 'assets/images/loading.svg';

export const Loading = (props) => {
  const { size = 'small' } = props;

  return (
    <div className={classes.loaderWrapper}>
      <div className={classes[size]}>
        <LoadingIcon />
      </div>
    </div>
  );
};

export default Loading;
