import React from 'react';
import { Dialog, IconButton, Grid, Button, Box } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import MuiTypography from '../Shared/Typography/MuiTypography';
import * as API from '../../Services/API/actions';
import moment from 'moment';
import { getTimezoneName } from 'utilities/Utilities';

const useStyles = makeStyles({
  root: {
    '& .MuiButton-outlinedSizeLarge': {
      width: '164px',
      height: '40px',
      borderRadius: '4px',
      fontFamily: 'Work Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '0.75px',
      textAlign: 'center',
    },
  },
  buttonText: {
    marginLeft: 94,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    color: ' #142A39',
  },
  buttonTextEdit: {
    marginLeft: 80,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    color: ' #142A39',
  },
  outlinebutton: {
    width: '200px',
    backgroundColor: '#067F6F',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#067F6F',
    },
  },
  dottedChip: {
    minWidth: '120px',
    border: '2px dashed rgba(20, 42, 57, 0.5) !important',
    color: '#142A39 !important',
    background: 'rgba(20, 42, 57, 0.1) !important',
  },
  table: {
    width: '100%',
  },
  tablehead: {
    // fontSize: 20,
    whiteSpace: 'nowrap',
    fontWeight: 'bolder',
    backgroundColor: '#FFFFFF',
  },
  tablecell: {
    whiteSpace: 'nowrap',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  filtering: {
    opacity: 0.4,
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: 'none ',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: 'column',
  },
}))(MuiDialogActions);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: 'none',
  },
  warningIconcolor: {
    backgroundColor: '#CC3D3F',
    borderRadius: '50%',
    color: '#FFFFFF',
    margin: '0 5px',
  },
  smallButton: {
    position: 'absolute',
    backgroundColor: 'rgba(20, 42, 57, 0.15)',
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: 'black',
    padding: '10px',
    margin: '0px 0px 0px 10px',
    border: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: '8px',
  },
});
export default function DeleteConfirmationPopup(props) {
  const classes = useStyles();
  const [error, seterror] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const recursiveOngoing =
    moment().format('YYYY-MM-DDTHH:mm:ss.SSS') >
    moment(props.data.recurrenceStartDateTime).format('YYYY-MM-DDTHH:mm:ss.SSS');
  const dateFormat = 'MM/DD/YYYY';
  const timeFormat = 'hh:mm A';
  const toSeparator = ' to ';
  const commaSeparator = ', ';
  const now = moment();
  const {
    recurrenceStartDateTime,
    recurrenceEndDateTime,
    splitStartDateTime,
    splitEndDateTime,
    startDateTime,
    endDateTime,
  } = props.data;
  const recurStartDate = moment(recursiveOngoing ? now : recurrenceStartDateTime).format(
    dateFormat,
  );
  const recurStartTime = moment(recursiveOngoing ? now : recurrenceStartDateTime).format(
    timeFormat,
  );
  const recurEndDate = moment(recurrenceEndDateTime).format(dateFormat);
  const recurEndTime = moment(recurrenceEndDateTime).format(timeFormat);
  const splitStartDate = moment(props.ongoing ? now : splitStartDateTime).format(dateFormat);
  const splitStartTime = moment(props.ongoing ? now : splitStartDateTime).format(timeFormat);
  const splitEndDate = moment(splitEndDateTime).format(dateFormat);
  const splitEndTime = moment(splitEndDateTime).format(timeFormat);
  const startDate = moment(props.ongoing ? now : startDateTime).format(dateFormat);
  const startTime = moment(props.ongoing ? now : startDateTime).format(timeFormat);
  // const timeDate = moment(props.ongoing ? now : startDateTime).format(timeFormat);
  const endDate = moment(endDateTime).format(dateFormat);
  const endTime = moment(endDateTime).format(timeFormat);
  const recurDateTime =
    recurStartDate +
    commaSeparator +
    recurStartTime +
    toSeparator +
    recurEndDate +
    commaSeparator +
    recurEndTime;
  const splitDateTime =
    splitStartDate +
    commaSeparator +
    splitStartTime +
    toSeparator +
    splitEndDate +
    commaSeparator +
    splitEndTime;
  const defaultDateTime =
    startDate + commaSeparator + startTime + toSeparator + endDate + commaSeparator + endTime;
  const timeString =
    props.data.isRecurring && props.series === 1
      ? recurDateTime
      : props.data.isSplit && props.component === 'adminCalendar'
      ? splitDateTime
      : defaultDateTime;
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, title, ...other } = props;
    return error ? (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Grid container justify="flex-start" direction="row" alignItems="flex-start">
          <Grid item>
            <CloseIcon style={{ fontSize: 24 }} className={classes.warningIconcolor} />
          </Grid>
          <Grid item>
            <MuiTypography
              fontSize="24px"
              fontFamily="Work Sans"
              // letterSpacing="0.15px"
              lineHeight="28.15px"
              fontWeight={500}
              color="#000000"
            >
              Cannot delete
            </MuiTypography>
          </Grid>
        </Grid>
      </MuiDialogTitle>
    ) : (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <MuiTypography
          fontSize="24px"
          fontFamily="Work Sans"
          letterSpacing="0.15px"
          lineHeight="28.15px"
          fontWeight={500}
          color="#142A39"
        >
          Delete availability
        </MuiTypography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.smallButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const submitHandler = () => {
    let recursiveParams = {
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      startTime: moment(props.data.instanceDateTime).format('HH:mm'),
      instanceDate: moment(props.data.instanceDateTime).format('YYYY-MM-DD'),
      version: props.data.version,
    };
    let params = {
      version: props.data.version,
    };
    setloading(true);
    if (props.data.isRecurring && props.series === 0) {
      API.deleteAvailabilityRecursive(
        props.data.teamId,
        props.data.memberId,
        props.data.id,
        recursiveParams,
      ).subscribe(
        (res) => {
          setloading(false);
          if ('detailed_error' in res.data) {
            seterror(res.data['detailed_error'] + '.');
          } else {
            props.onCancel({
              isRecurring: props.data.isRecurring,
            });
          }
        },
        (error) => {
          setloading(false);
          seterror('Unable to process this request due to internal server error.');
        },
      );
    } else {
      API.deleteAvailabilityNonRecursive(
        props.data.teamId,
        props.data.memberId,
        props.data.id,
        params,
      ).subscribe(
        (res) => {
          setloading(false);
          if ('detailed_error' in res.data) {
            seterror(res.data['detailed_error'] + '.');
          } else {
            props.onCancel({
              isRecurring: props.data.isRecurring,
            });
            setloading(false);
          }
        },
        (error) => {
          setloading(false);
          seterror('Unable to process this request due to internal server error.');
        },
      );
    }
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={error ? 'xs' : 'sm'}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div className={loading ? classes.filtering : null}>
        <div className="telekardia">
          <div className={loading ? 'spinner-border' : null}></div>
        </div>
        <DialogTitle id="simple-dialog-title" onClose={props.handleClose} />
        <DialogContent>
          {error ? (
            <Box px={2}>
              <MuiTypography
                fontSize="14px"
                fontFamily="Work Sans"
                lineHeight="24px"
                letterSpacing="-0.2px"
                fontWeight={400}
                color="#142A39"
              >
                {error}
              </MuiTypography>
            </Box>
          ) : (
            <Grid container justify="flex-start">
              <Grid item container direction="row">
                <Grid item>
                  <MuiTypography
                    fontSize="14px"
                    fontFamily="Work Sans"
                    lineHeight="24px"
                    letterSpacing="-0.2px"
                    fontWeight={400}
                    color="#000000"
                  >
                    {props.data.isRecurring
                      ? 'You are about to delete the Recurrence availability for'
                      : 'You are about to delete the availability for'}
                  </MuiTypography>
                </Grid>
                <Grid item container direction="row">
                  <Grid item>
                    <MuiTypography
                      fontSize="14px"
                      fontFamily="Work Sans"
                      letterSpacing="0.15px"
                      lineHeight="24px"
                      fontWeight={700}
                      textTransform="camelcase"
                      color="#000000"
                    >
                      {props.selectedDoctorName}
                    </MuiTypography>
                  </Grid>
                  <Grid item>
                    <MuiTypography
                      fontSize="14px"
                      fontFamily="Work Sans"
                      lineHeight="24px"
                      letterSpacing="-0.2px"
                      color="#000000"
                    >
                      <span>&nbsp;</span>from
                    </MuiTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item>
                  <MuiTypography
                    fontSize="14px"
                    fontFamily="Work Sans"
                    lineHeight="24px"
                    letterSpacing="-0.2px"
                    fontWeight={700}
                    color="#000000"
                  >
                    {timeString}.
                  </MuiTypography>
                </Grid>
              </Grid>

              <Grid item container direction="row">
                <Grid item>
                  <MuiTypography
                    fontSize="14px"
                    fontFamily="Work Sans"
                    lineHeight="24px"
                    letterSpacing="-0.2px"
                    fontWeight={400}
                    color="#142A39"
                  >
                    Are you sure you want to continue deleting the availability?
                  </MuiTypography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions disableSpacing={true}>
          {error ? (
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                onClick={props.handleClose}
                className={classes.outlinebutton}
              >
                OKAY
              </Button>
            </Grid>
          ) : (
            <Grid container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <div className={classes.root}>
                  <Button variant="outlined" size="large" onClick={props.handleClose}>
                    No
                  </Button>
                </div>
              </Grid>
              <Grid item>
                <div className="telekardia">
                  <button
                    type="button"
                    className="btn px-4 text-uppercase h40 ml-3 swal_green"
                    onClick={submitHandler}
                  >
                    {'YES, CONTINUE'}
                  </button>
                </div>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}
