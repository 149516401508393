import React from 'react';
import classes from './Grid.scss';
import * as d3 from 'd3';
import * as Lib from 'constants/RecordingConstants';

class Grid extends React.PureComponent {
  render() {
    const { renderSmallGrid, rowSeconds, height, stripWidthPx } = this.props;

    const mult = Lib.PX_PER_MM;
    const secondsScale = () =>
      d3
        .scaleLinear()
        .domain([0, 1])
        .range([0, mult * 25]);

    const renderXGridSmall = (h) => {
      const lines = [];
      let i = 0;
      while (i < rowSeconds) {
        lines.push(
          <line
            key={i}
            className={classes.xGridSmall}
            x1={secondsScale()(i) + Lib.SVG_OFFSET}
            y1={0}
            x2={secondsScale()(i) + Lib.SVG_OFFSET}
            y2={h}
            strokeDasharray={`1, ${mult - 1}`}
          />,
        );
        i += 0.04;
      }
      return lines;
    };

    const renderXGrid = (h) => {
      const lines = [];
      let i = 0;
      while (i < rowSeconds) {
        lines.push(
          <line
            key={i}
            className={classes.xGrid}
            x1={secondsScale()(i) + Lib.SVG_OFFSET}
            y1={Lib.SVG_OFFSET}
            x2={secondsScale()(i) + Lib.SVG_OFFSET}
            y2={h}
          />,
        );
        i += 0.2;
      }
      return lines;
    };

    const renderXGridDark = (h) => {
      const lines = [];
      let i = 0;
      while (i < rowSeconds) {
        lines.push(
          <line
            key={i}
            className={classes.xGridDark}
            x1={secondsScale()(i) + Lib.SVG_OFFSET}
            y1={0}
            x2={secondsScale()(i) + Lib.SVG_OFFSET}
            y2={h}
          />,
        );
        i += 1;
      }
      return lines;
    };

    const renderYGrid = (h) => {
      const lines = [];
      let i = 0;
      const getYClass = (j) => {
        const mod = j % 100;
        if (mod === 0) {
          return 'yGridDark';
        } else {
          return 'yGrid';
        }
      };
      while (i < h) {
        lines.push(
          <line
            key={i}
            className={classes[getYClass(i)]}
            x1={0}
            y1={i + Lib.SVG_OFFSET}
            x2={stripWidthPx + 1}
            y2={i + Lib.SVG_OFFSET}
          />,
        );
        i += mult * 5;
      }
      return lines;
    };

    return (
      // <g className={classes.grid}>
      <g
        style={{
          strokeWidth: '1px',
          stroke: '#AE1E08',
          shapeRendering: 'crispedges',
          strokeOpacity: 0.2,
        }}
      >
        {renderSmallGrid && renderXGridSmall(height)}
        {renderXGrid(height)}
        {renderYGrid(height)}
        {renderXGridDark(height)}
      </g>
    );
  }
}

export default Grid;
