import * as React from 'react';
import MuiButton from 'components/Shared/Buttons/MuiButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style = {{fontSize:23}}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style = {{fontSize:13,color:"#00000099"}}>
         {props.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <div style = {{width:"50%",marginRight:20,display:"flex",gap:"20px"}}>
 {props?.buttonNameFirst && <MuiButton variant="outlined" text = {props?.buttonNameFirst} onClick = {props.onClickFirstButton}/>}
 {props?.buttonSecondName &&  <MuiButton variant="contained" text = {props?.buttonSecondName} onClick = {props.onClickSecondButton} />}
</div>
        </DialogActions>
      </Dialog>
    </div>
  );
}