import React from 'react';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';

import { ROUTES } from 'constants/app';
import HomeIcon from 'assets/images/logos/home.svg';
import styles from './comingSoon.module.css';

const ComingSoonPage = ({ type }) => {
  const history = useHistory();
  const handleRouteToDashboard = () => {
    history.push(ROUTES.ROOT);
  };
  return (
    <div className={styles.NotFoundPage}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <h3>COMING SOON</h3>
        <p>Head back to Home.</p>
        <Button variant="contained" onClick={handleRouteToDashboard}>
          {/* <HomeIcon fill={'#fff'} /> */}
          Home
        </Button>
      </div>
    </div>
  );
};

export default ComingSoonPage;
