/* eslint-disable import/no-unresolved */
import React from 'react';
import { Box, Grid, styled } from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
  margin: '20px',
}));
const ScreenLayout = ({ header, row = 'row', aside = null, ...props }) => {
  return (
    <Root>
      <Grid container mb={2}>
        {header && (
          <Grid item xs={12}>
            {header}
          </Grid>
        )}
      </Grid>

      <Grid container>
        {aside != null && (
          <Grid
            item
            xs={2}
            style={{ display: 'flex', justifyContent: row === 'row-reverse' ? 'end' : 'start' }}
          >
            {aside}
          </Grid>
        )}
        <Grid item xs={aside ? 10 : 12}>
          {props.children}
        </Grid>
      </Grid>
    </Root>
  );
};

export default ScreenLayout;
