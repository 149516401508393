import React from 'react';

import styles from './TableActions.module.css';
import CloseIcon from 'assets/images/icons/close.svg';
import { getSelectedList } from 'utilities/Settings/TableUtilities';

const TableActions = (props) => {
  const {
    selected,
    visible,
    children,
    clearSelection,
    bulkActionComplete,
    successMessage = 'Success!',
  } = props;

  const numberOfSelections = selected ? Object.keys(getSelectedList(selected)).length : 0;

  const isVisible = visible ? styles.isVisible : '';
  const isComplete = bulkActionComplete ? styles.isComplete : '';

  return (
    <div className={`${styles.tableActionContainer} ${isVisible} ${isComplete}`}>
      <div className={`${styles.tableAction}`}>
        <div className={styles.step1}>
          <div className={styles.selection}>{numberOfSelections} Selected</div>
          <div className={styles.actions}>{children}</div>
        </div>
        <div className={styles.step2}>
          <span>Success!</span>&mdash; {successMessage}
        </div>
      </div>
      <button
        className={styles.clearAction}
        onClick={typeof clearSelection === 'function' ? clearSelection : undefined}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default TableActions;
