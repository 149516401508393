import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, IconButton } from "@material-ui/core";

import { useStyles } from "./MultipleSelectBoxStyles";
import CancelIcon from "@material-ui/icons/Cancel";
import SelectBox from "../SelectBox/SelectBox";
import { isClearClickedStatus } from "store/actions";

/* Usage : => 
  menuOptionsData : expects options data
  menuOptionsData : function which will receive the selected items set
  smallPillStyle  : boolean value (true/false), the selectBox on UI will show selected options in a small pill
  placeholder     : Prefilled message 
  keyName         : key in menuOptions which contains option name
  keyTwoName      : (Not mandatory) Useful if you want to return anything else except for option name
*/

function MultipleSelectBox(props) {
  const {
    menuOptionsData,
    getSelectedOptions,
    smallPillStyle,
    placeholder,
    keyName,
    keyTwoName,
    selectAllOption,
    onSelectItemEmptyCheck,
    isClearSelectedItems,
    isDisabled,
  } = props;

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemList, setSelectedItemList] = useState(new Set());
  const [selectedItemIdList, setSelectedItemIdList] = useState(new Set());
  const [isRemoveClicked, setIsRemoveClicked] = useState(false);

  const isClearClicked = useSelector((state) => state.ekgReport.isClearClicked);


  const dispatch = useDispatch();
  const classes = useStyles();

  const selectHandler = (selectedValue) => {
    setSelectedItem(selectedValue);
  };

  useEffect(() => {
    if (onSelectItemEmptyCheck) {
      onSelectItemEmptyCheck(selectedItemList.size);
    }
  }, [selectedItem]);

  useEffect(() => {
    setSelectedItem("");
    setSelectedItemList(new Set());
  }, [isClearSelectedItems]);

  useEffect(() => {
    setSelectedItem("");
    setSelectedItemList(new Set());
    getSelectedOptions(new Set());
    dispatch(isClearClickedStatus(false));
  }, [isClearClicked]);

  useEffect(() => {
    if (isRemoveClicked) {
      setIsRemoveClicked(false);
      setSelectedItemIdList(selectedItemList)
      return;
    }
    if (selectedItem === "") {
      return;
    }
    if (selectedItem === "selectAll") {
      if (!keyTwoName) {
        setSelectedItemList(
          new Set(menuOptionsData.map((item) => item[keyName]))
        );
      }
      if (keyTwoName) {
        setSelectedItemIdList(
          new Set(menuOptionsData.map((item) => item[keyTwoName]))
        );
        setSelectedItemList(
          new Set(menuOptionsData.map((item) => item[keyName]))
        );
      }
      setSelectedItem("");
      return;
    }
    const selectedOptionName = menuOptionsData[selectedItem][keyName];
    const selectedOptionId = keyTwoName
      ? menuOptionsData[selectedItem][keyTwoName]
      : menuOptionsData[selectedItem][keyName];
    setSelectedItemList((prevState) => prevState.add(selectedOptionName));
    setSelectedItemIdList((prevState) => prevState.add(selectedOptionId));
    setSelectedItem("");
  }, [isRemoveClicked, selectedItem]);

  if (keyTwoName) {
    getSelectedOptions(selectedItemIdList);
  }
  if (!keyTwoName) {
    getSelectedOptions(selectedItemList);
  }

  return (
    <Box
      className={`${classes.selectBoxContainer} ${
        selectedItemList.size ? classes.selectBoxBorder : ""
      }`}
    >
      <SelectBox
        value={selectedItem}
        onSelect={selectHandler}
        menuOptionsData={menuOptionsData}
        placeholder={placeholder}
        selectAllOption={selectAllOption}
        scrollableMenu={
          selectAllOption
            ? menuOptionsData.length > 3
            : menuOptionsData.length > 4
        }
        selectedItemList={selectedItemList}
        keyName={keyName}
        isDisabled={isDisabled}
      />
      {selectedItemList.size ? (
        <Box
          className={`${
            smallPillStyle
              ? classes.selectedStatesContainer
              : classes.selectedItemsContainer
          }`}
        >
          {selectedItemList.map((data, idx) => (
            <Box
              key={idx}
              className={`${classes.pill} ${
                smallPillStyle ? classes.smallPill : ""
              }`}
            >
              <Typography className={classes.selectedItem}>{data}</Typography>
              <IconButton
                onClick={() => {
                  selectedItemList.delete(data);
                  setSelectedItemList(selectedItemList);
                  setIsRemoveClicked(true);
                }}
              >
                <CancelIcon className={classes.cancelIcon} />
              </IconButton>
            </Box>
          ))}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

export default MultipleSelectBox;
