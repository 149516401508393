/* eslint-disable*/
import React, { useEffect } from 'react';
import { Dialog, Button, IconButton, Grid, Box, SvgIcon } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import MuiTypography from '../Shared/Typography/MuiTypography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Datepicker from './material_datepicker';
import CircleChecked from '@material-ui/icons/CheckCircle';
import moment from 'moment';
import * as API from '../../Services/API/actions';
import Alert from '../Shared/Alert/Alerts';
import BlockIcon from '@material-ui/icons/Block';
import { getTimezoneName } from 'utilities/Utilities';
import { useTranslation } from 'react-i18next';
import { AUTH_TYPE } from 'constants/app';
import { useSelector } from 'react-redux';
// import { SESSION_DURATION } from 'constants/app';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButton-outlinedSizeLarge': {
      width: '164px',
      height: '40px',
      borderRadius: '4px',
      fontFamily: 'Work Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '0.75px',
      textAlign: 'center',
    },
  },
  nextButton: {
    width: '250px',
    height: '35px',
    margin: '10px',
    '& .MuiButton-label': {
      fontSize: '16px',
      fontFamily: 'Work Sans',
      alignItems: 'center',
      fontWeight: 600,
    },
    backgroundColor: 'FFFFFF',
  },
  buttonText: {
    marginLeft: 94,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    color: ' #142A39',
  },
  buttonTextEdit: {
    marginLeft: 80,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    color: ' #142A39',
  },
  button: {
    border: '1px solid #142A39',
    color: '#142A39',
    justifyContent: 'flex-start',
  },
  checkedIconcolor: {
    color: '#067F6F',
  },

  typeCompany: {
    // padding: 0,
    // "& ~.MuiFormControlLabel-root": {
    //   outline: `1px solid #067F6F`,
    // },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      '& .MuiFormControlLabel-root': {
        outline: `1px solid #067F6F`,
      },
      '& ~.MuiFormControlLabel-label': {
        // outline: `1px solid #067F6F`,
        color: '#067F6F',
        width: '100%',
      },
    },
    '& ~ .MuiFormControlLabel-label': {
      zIndex: 9,
      position: 'relative',
      color: '#142A39',
      width: '100%',
      padding: 10,
      // borderRadius: 50,
      // border: "1px solid #DDDDDD",
      fontSize: 14,
      lineHeight: '16.71px',
      fontWeight: 700,
      textAlign: 'center',
    },
  },
  checkedIcontextcolor: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    justifyContent: 'flex-start',
    minWidth: '395px',
    // '&:hover': {
    //   color: '#067F6F',
    // },
    // "&.Mui-focused": {
    //   color: "#067F6F"
    // },

    border: '2px solid #BABFBD',
    borderRadius:5,
    // boxSizing: "border-box",
    '&, & + $label': {
      // left: 'calc(50% - 16px/2)',
      '&:hover': {
        color: '#067F6F',
        border: '2px solid #067F6F',
        borderRadius:5,
      },
      // "&$checked": {`
      //   color: '#067F6F',
      //   outline: '1px auto #067F6F',

      // },
      // "&$focused": {
      //   outline: "1px auto #067F6F",
      //   color: "#2D9F86",
      // },
      // &.MuiFormControlLabel-root
    },
  },
  scrolltimings: {
    width: '100%',
    maxHeight: 'calc(30vh)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  paddedradiogroup: {
    marginLeft: '12px',
    marginRight: '5px',
  },
  smallButton2: {
    backgroundColor: 'rgba(20, 42, 57, 0.15)',
    marginTop: '15px',
    color: 'black',
    // padding: "10px",
    // margin: "0px 0px 0px 10px",
    border: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: '8px',
    '&$buttonDisabled': {
      backgroundColor: 'rgba(20, 42, 57, 0.15)',
      color: 'rgba(20, 42, 57, 0.15)',
    },
  },
  paddedpicker: {
    // paddingLeft: "5px",
    // paddingRight: "5px",
  },
  filtering: {
    opacity: 0.4,
  },
  buttonDisabled: {},
}));

const StyledRadio = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<CircleChecked className={classes.checkedIconcolor} />}
      {...props}
    />
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: 'none ',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: 'column',
  },
}))(MuiDialogActions);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: 'none',
  },
  smallButton: {
    position: 'absolute',
    backgroundColor: 'rgba(20, 42, 57, 0.15)',
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: 'black',
    padding: '10px',
    margin: '0px 0px 0px 10px',
    border: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: '8px',
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MuiTypography
        fontSize="24px"
        fontFamily="Work Sans"
        fontWeight={600}
        lineHeight="28px"
        color="#142A39"
      >
        {title}
      </MuiTypography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.smallButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function ReScheduleAppointment(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const authProfile = useSelector((state) => state.user.chatProfile.userType);
  const [selectedDate, setselectedDate] = React.useState(moment(props.data.scheduledStartTime)._d);
  const [availableSlots, setavailableSlots] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const [selectedSlot, setselectedSlot] = React.useState(null);
  const [rescheduleConfirmModal, setrescheduleConfirmModal] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const startDate_handleChange = (date) => {
    setselectedDate(date);
  };
  const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
  useEffect(() => {
    setloading(true);
    let queryParams = {
      startDate:
        moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
          ? moment().add(2, 'minutes').set({ second: 0, millisecond: 0 }).format(pattern)
          : moment(moment(selectedDate).startOf('day')._d).format(pattern),
      endDate: moment(moment(selectedDate).endOf('day')._d).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      appointmentId: props.data.appointmentId,
    };
    if (authProfile === AUTH_TYPE.COACH) queryParams.forCoach = true;
    else queryParams.forDoctor = true;
    API.getClinicianAvailabilityslotsv2(
      props.adminId,
      props.teamId,
      props.memberId,
      queryParams,
    ).subscribe(
      (res) => {
        let slots = [];
        setloading(false);
        if ('detailed_error' in res.data)
          setAlert(
            <Alert
              date={new Date()}
              title={
                'detailed_error' in res.data ? 'Unable to process the request' : 'Technical Issue'
              }
              message={
                'detailed_error' in res.data
                  ? res.data['detailed_error']
                  : 'Unable to process this request due to internal server error.'
              }
            />,
          );
        else {
          setAlert(null);
          for (let key in res.data) {
            for (let slot in res.data[key]) {
              if ('availableSlots' in res.data[key][slot])
                slots.push(...res.data[key][slot].availableSlots);
            }
          }
          setavailableSlots(slots);
        }
      },
      (error) => {
        console.log(error);
        setloading(false);
      },
    );
  }, [selectedDate]);
  const dateIncrementHandler = () => {
    setselectedDate(moment(selectedDate).add(1, 'days'));
  };
  const dateDecrementHandler = () => {
    setselectedDate(moment(selectedDate).subtract(1, 'days'));
  };
  const submitHandler = () => {
    let body = {
      isImmediateAppointment: false,
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      updatedStartDateTime: moment(selectedSlot).format(pattern),
    };
    let params = {
      version: props.data.version,
    };
    setloading(true);
    API.RescheduleAppointment(
      props.teamId,
      props.memberId,
      props.data.appointmentId,
      body,
      params,
    ).subscribe((res) => {
      setloading(false);
      if ('detailed_error' in res.data) {
        setAlert(
          <Alert
            date={new Date()}
            title={
              'detailed_error' in res.data ? 'Unable to process the request' : 'Technical Issue'
            }
            message={
              'detailed_error' in res.data
                ? res.data['detailed_error']
                : 'Unable to process this request due to internal server error.'
            }
          />,
        );
      } else {
        setAlert(null);
        props.onSubmit();
      }
    });
  };
  const selectedSlotHandler = (event) => {
    setselectedSlot(event.target.value);
  };
  const rescheduleConfirmationHandler = () => {
    if (selectedSlot) {
      setAlert(null);
      setrescheduleConfirmModal(true);
    } else {
      setAlert(
        <Alert
          date={new Date()}
          title={t('Select slot')}
          message={t('Select slot to Reschedule consultation') + '.'}
        />,
      );
    }
  };

  const SESSION_DURATION = moment(props.data.scheduledEndTime).diff(
    moment(props.data.scheduledStartTime),
    'minutes',
  );

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.show}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div className={loading ? classes.filtering : null}>
        <div className="telekardia">
          <div className={loading ? 'spinner-border' : null}></div>
        </div>
        <DialogTitle
          id="simple-dialog-title"
          onClose={props.handleClose}
          title={t('Reschedule consultation')}
        />
        <div className="telekardia">
          <div className="m-3">{alert}</div>
        </div>
        <DialogContent>
          <Grid container justify="flex-start">
            <Grid item container>
              <MuiTypography
                fontSize="14px"
                fontFamily="Work Sans"
                lineHeight="16px"
                letterSpacing="-0.2px"
                fontWeight={600}
                color="#000000"
              >
                {t('Current date and time')}
              </MuiTypography>
            </Grid>
            <Box pt={1} />
            <Grid item container>
              <MuiTypography
                fontSize="14px"
                fontFamily="Work Sans"
                lineHeight="16.42px"
                letterSpacing="0.75px"
                fontWeight={400}
                color="#000000"
              >
                {moment(props.data.scheduledStartTime).format('MM/DD/YYYY') +
                  ', ' +
                  moment(props.data.scheduledStartTime).format('hh:mm A') +
                  ' to ' +
                  moment(props.data.scheduledEndTime).format('hh:mm A')}
              </MuiTypography>
            </Grid>
            {rescheduleConfirmModal && selectedSlot ? (
              <>
                <Box pt={6} />
                <Grid item container>
                  <MuiTypography
                    fontSize="14px"
                    fontFamily="Work Sans"
                    lineHeight="16px"
                    letterSpacing="-0.2px"
                    fontWeight={600}
                    color="#000000"
                  >
                    {t('New date and time')}
                  </MuiTypography>
                </Grid>
                <Grid item container>
                  <MuiTypography
                    fontSize="14px"
                    fontFamily="Work Sans"
                    lineHeight="16.42px"
                    letterSpacing="0.75px"
                    fontWeight={400}
                    color="#000000"
                  >
                    {moment(selectedSlot).format('MM/DD/YYYY') +
                      ', ' +
                      moment(selectedSlot).format('hh:mm A') +
                      ' to ' +
                      moment(selectedSlot).add('minute', SESSION_DURATION).format('hh:mm A')}
                  </MuiTypography>
                </Grid>
                <Box pt={6} />
                <Grid item container>
                  <MuiTypography
                    fontSize="14px"
                    fontFamily="Work Sans"
                    lineHeight="16.42px"
                    letterSpacing="0.75px"
                    fontWeight={400}
                    color="#000000"
                  >
                    {t('Are you sure you want to reschedule this consultation')}?
                  </MuiTypography>
                </Grid>
              </>
            ) : (
              <>
                <Box pt={6} />
                <Grid item container>
                  <MuiTypography
                    fontSize="14px"
                    fontFamily="Work Sans"
                    lineHeight="16px"
                    letterSpacing="-0.2px"
                    fontWeight={600}
                    color="#000000"
                  >
                    {t('Select the new date and time')}
                  </MuiTypography>
                </Grid>

                <Grid item container justify="space-between">
                  <Grid item>
                    <IconButton
                      disabled={
                        new Date(selectedDate).getDate() - 1 === new Date().getDate() - 1
                          ? true
                          : false
                      }
                      aria-label="close"
                      classes={{
                        root: classes.smallButton2,
                        disabled: classes.buttonDisabled,
                      }}
                      // className={classes.smallButton2}
                      onClick={dateDecrementHandler}
                    >
                      <SvgIcon viewBox="0 0 8 12" style={{ fontSize: 20 }}>
                        <path d="M7.4 1.4L6 0L0 6L6 12L7.4 10.6L2.8 6L7.4 1.4Z" fill="#142A39" />
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Datepicker
                      label={
                        moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
                          ? 'Today'
                          : false
                      }
                      padding="10px"
                      date={selectedDate}
                      changeHandle={startDate_handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="close"
                      className={classes.smallButton2}
                      onClick={dateIncrementHandler}
                    >
                      <SvgIcon viewBox="0 0 8 12" style={{ fontSize: 20 }}>
                        <path d="M1.4 0L0 1.4L4.6 6L0 10.6L1.4 12L7.4 6L1.4 0Z" fill="#142A39" />
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                </Grid>
                <Box pt={4} />
                <Grid item container className={classes.scrolltimings}>
                  {/* component="fieldset" */}
                  <FormControl className={classes.paddedradiogroup}>
                    <RadioGroup
                      //defaultValue={Object.keys(availableSlots).length>0 ?moment(availableSlots[0].slotStartTime).format("hh:mm A"):""}
                      aria-label="gender"
                      name="customized-radios"
                      onChange={selectedSlotHandler}
                    >
                      {Object.keys(availableSlots).length > 0 &&
                        availableSlots.map((item, index) => (
                          <div key={index}>
                            <Box mt={1} />
                            <FormControlLabel
                              value={item.slotStartTime}
                              className={classes.checkedIcontextcolor}
                              control={<StyledRadio className={classes.typeCompany} />}
                              label={moment(item.slotStartTime).format('hh:mm A')}
                            />
                            <Box mt={1} />
                          </div>
                        ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {Object.keys(availableSlots).length === 0 && !loading && (
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <IconButton disabled={true}>
                      <BlockIcon style={{ fontSize: 80, textAlign: 'center' }} />
                    </IconButton>
                    <MuiTypography
                      fontSize="16px"
                      fontFamily="Work Sans"
                      lineHeight="16px"
                      letterSpacing="-0.2px"
                      fontWeight={400}
                      color="#DADADA"
                    >
                      {t('Sorry')},{t('no timeslots available')}
                    </MuiTypography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {Object.keys(availableSlots).length === 0 && !loading ? (
            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={dateIncrementHandler}
                  className={classes.nextButton}
                >
                  {t('CHECK THE NEXT DAY')}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" justify="flex-end" spacing={2}>
              {rescheduleConfirmModal && selectedSlot ? (
                <>
                  <Grid item>
                    <div className={classes.root}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={props.handleClose}
                        className={classes.outlinebutton}
                      >
                        {t('No')}
                      </Button>
                    </div>
                  </Grid>

                  <Grid item>
                    <div className="telekardia">
                      <button
                        type="button"
                        className="btn px-4 text-uppercase h40 ml-3 swal_green"
                        onClick={submitHandler}
                      >
                        {t('YES')}, {t('RESCHEDULE')}
                      </button>
                    </div>
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <div className="telekardia">
                    <button
                      type="button"
                      className="btn px-4 text-uppercase h40 ml-3 swal_green"
                      onClick={rescheduleConfirmationHandler}
                    >
                      {t('CONFIRM')}
                    </button>
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}
