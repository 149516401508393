/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  IconButton,
  Grid,
  Button,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import { makeStyles } from '@material-ui/core/styles';
import MuiTypography from '../Shared/Typography/MuiTypography';
import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonText: {
    fontFamily: 'Work Sans',
    marginLeft: 94,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    color: ' #142A39',
  },
  buttonTextEdit: {
    marginLeft: 80,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
    color: ' #142A39',
  },
  button: {
    border: '1px solid #142A39',
    color: '#142A39',
    justifyContent: 'flex-start',
  },
  dottedChip: {
    minWidth: '120px',
    border: '2px dashed rgba(20, 42, 57, 0.5) !important',
    color: '#142A39 !important',
    background: 'rgba(20, 42, 57, 0.1) !important',
  },
  table: {
    width: '100%',
  },
  tablehead: {
    // fontSize: 20,
    whiteSpace: 'nowrap',
    fontWeight: 'bolder',
    backgroundColor: '#FFFFFF',
  },
  tablecell: {
    whiteSpace: 'nowrap',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  gridPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: 'none ',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: 'column',
  },
}))(MuiDialogActions);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: 'none',
  },
  smallButton: {
    position: 'absolute',
    backgroundColor: 'rgba(20, 42, 57, 0.15)',
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: 'black',
    padding: '10px',
    margin: '0px 0px 0px 10px',
    border: 'none',
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: '8px',
  },
  gridPaddingtitle: {
    paddingLeft: theme.spacing(4),
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.gridPaddingtitle} {...other}>
      <MuiTypography
        fontSize="12px"
        fontFamily="Work Sans"
        letterSpacing="0.15px"
        fontWeight={600}
        textTransform="uppercase"
        color="#415B6A"
      >
        Details
      </MuiTypography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.smallButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function ProviderPopUp(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [doctoravailability, setdoctoravailability] = useState([]);
  const [physicianInfo, setphysicianInfo] = useState({});
  useEffect(() => {
    if (
      props.data &&
      props.data.length > 0 &&
      props.providerInfo &&
      props.providerInfo.length > 0 &&
      props.providerInfo[0] &&
      props.providerInfo[0].physicianModel &&
      props.providerInfo[0].physicianModel.memberId
    ) {
      let doctor = props.data.filter((c) => {
        // console.log("cccc", props.providerInfo[0].physicianModel.memberId);
        return c.physicianModel.memberId === props.providerInfo[0].physicianModel.memberId;
        // return `Dr. ${c.physicianModel.firstName} ${c.physicianModel.lastName}` === props.providername
      });
      if (doctor.length > 0) {
        setphysicianInfo(doctor[0].physicianModel);
        setdoctoravailability(doctor[0].appointmentCounts);
      }
    }
  }, [props.providerInfo]);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="simple-dialog-title"
        onClose={props.handleClose}
        title={props.providername}
      />
      <DialogContent>
        <Grid container justify="flex-start" className={classes.gridPadding}>
          <Grid container direction="row">
            <Grid item>
              <MuiTypography
                fontSize="24px"
                fontFamily="Work Sans"
                lineHeight="28px"
                // letterSpacing="0.15px"
                fontWeight={500}
                color="#142A39"
              >
                {props.providername}
              </MuiTypography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        {/* <Grid item container direction="row">
            <Grid item>
              <MuiTypography
                fontSize="14px"
                fontFamily="Work Sans"
                lineHeight="24px"
                letterSpacing="-0.2px"
                fontWeight={400}
                color="#536063"
              >
                MBBS MD
              </MuiTypography>
            </Grid>
            
          </Grid> */}
        <Grid
          container
          direction="row"
          justify="flex-start"
          spacing={1}
          className={classes.gridPadding}
        >
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  Mobile
                </MuiTypography>
              </Grid>

              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t('Email')}
                </MuiTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {_get(physicianInfo, 'phone') ? `+${_get(physicianInfo, 'phone')}` : '-'}
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {_get(physicianInfo, 'email') || '-'}
                </MuiTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider />
        <Grid container direction="row">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.tablehead}
                  style={{ width: 180, fontFamily: 'Work Sans' }}
                >
                  {t('Today’s availability')}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tablehead}
                  style={{ fontFamily: 'Work Sans' }}
                >
                  {t('Consultations')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doctoravailability &&
                //   adminConsultationDetails.length > 0 &&
                doctoravailability.map((row, index) => (
                  <StyledTableRow key={index}>
                    <TableCell align="left" style={{ width: 180 }}>
                      {row.availabilityDuration}
                    </TableCell>
                    <TableCell align="center">{row.noOfAppointments}</TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </DialogContent>
      <DialogActions disableSpacing={true}>
        <>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            className={classes.button}
            startIcon={<EventIcon />}
            onClick={props.showCalendarView}
          >
            <span className={classes.buttonTextEdit}>{t('SHOW FULL CALENDAR')}</span>
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
