import moment from 'moment'

import {
  SORT_TYPES,
  BLOOD_PRESSURE_TABLE_COLUMN_HEADERS,
  BLOOD_PRESSURE_SOURCES,
  MIN_DIASTOLIC_VALUE,
  MAX_DIASTOLIC_VALUE,
  MIN_SYSTOLIC_VALUE,
  MAX_SYSTOLIC_VALUE,
  MIN_BPM_VALUE,
  MAX_BPM_VALUE,
  KARDIA_PRO_START_DATE,
  KARDIA_PRO_END_DATE,
} from 'constants/app'
import { StandardCompare } from '../../Utilities/Utilities'

export const sortBloodPressureRecordingIds = (
  sorts = {
    sortBy: BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.RECORDED_AT,
    sortDirection: SORT_TYPES.DESC,
  },
  recIds = [],
  recByIds = {}
) => {
  const {
    sortBy,
    sortDirection,
  } = sorts
  const recordingIds = [ ...recIds ]
  let sortedRecordingIds = recordingIds

  if (recordingIds) {
    switch (sortBy) {
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.RECORDED_AT:
      sortedRecordingIds = recordingIds.sort((a, b) => StandardCompare.dates(recByIds[a][sortBy], recByIds[b][sortBy], sortDirection))
      break
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SOURCE:
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.CATEGORY:
      sortedRecordingIds = recordingIds.sort((a, b) => StandardCompare.strings(recByIds[a][sortBy], recByIds[b][sortBy], sortDirection))
      break
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.BPM:
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.SYSTOLIC:
    case BLOOD_PRESSURE_TABLE_COLUMN_HEADERS.DIASTOLIC:
      if (sortDirection === SORT_TYPES.DESC) {
        sortedRecordingIds = recordingIds.sort((a, b) => recByIds[b][sortBy] - recByIds[a][sortBy])
      } else if (sortDirection === SORT_TYPES.ASC) {
        sortedRecordingIds = recordingIds.sort((a, b) => recByIds[a][sortBy] - recByIds[b][sortBy])
      }
      break
    default:
      // No sort, keep previous sort
      break
    }
  }

  return sortedRecordingIds
}

export const filterBloodPressureRecordingIds = (
  filters = {
    selectedSource: undefined,
    selectedSeverity: undefined,
    selectedStartDate: undefined,
    selectedEndDate: undefined,
    selectedBpmStartValue: undefined,
    selectedBpmEndValue: undefined,
    selectedSystolicStartValue: undefined,
    selectedSystolicEndValue: undefined,
    selectedDiastolicStartValue: undefined,
    selectedDiastolicEndValue: undefined,
  },
  recordingIds,
  recordingByIds,
) => {
  const {
    selectedSource,
    selectedSeverity,
    selectedStartDate,
    selectedEndDate,
    selectedBpmStartValue,
    selectedBpmEndValue,
    selectedSystolicStartValue,
    selectedSystolicEndValue,
    selectedDiastolicStartValue,
    selectedDiastolicEndValue,
  } = filters
  let filteredRecordings = recordingIds

  if (recordingIds) {
    if (selectedStartDate !== KARDIA_PRO_START_DATE
      || selectedEndDate !== KARDIA_PRO_END_DATE
    ) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]
          if (moment(r.recordedAt).isBefore(moment(selectedStartDate).startOf('day'))
            || moment(r.recordedAt).isAfter(moment(selectedEndDate).endOf('day'))) {
            return false
          }

          return true
        })
    }

    if (selectedSource.length > 0) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return selectedSource.some(source => {
            switch (source.id) {
            case BLOOD_PRESSURE_SOURCES.OMRON:
              return StandardCompare.tableStringFilter(r.source, source.id)
            case BLOOD_PRESSURE_SOURCES.MANUAL:
              return StandardCompare.tableStringFilter(r.source, source.id)
                  // Old source field options => manual
                  || StandardCompare.tableStringFilter(r.source, BLOOD_PRESSURE_SOURCES.KARDIA_MOBILE)
                  || StandardCompare.tableStringFilter(r.source, BLOOD_PRESSURE_SOURCES.KARDIA_ANDROID_MANUAL_ENTRY)
            default:
              return false
            }
          })
        })
    }

    if (selectedSeverity.length > 0) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return selectedSeverity.some(severity => severity.id === r.severity)
        })
    }

    if (selectedBpmStartValue !== MIN_BPM_VALUE
      || selectedBpmEndValue !== MAX_BPM_VALUE
    ) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return StandardCompare.tableNumberRangeFilter({
            values: {
              min: selectedBpmStartValue,
              current: r.pulse,
              max: selectedBpmEndValue,
            },
            limits: {
              min: MIN_BPM_VALUE,
              max: MAX_BPM_VALUE,
            },
          })
        })
    }

    if (selectedSystolicStartValue !== MIN_SYSTOLIC_VALUE
      || selectedSystolicEndValue !== MAX_SYSTOLIC_VALUE
    ) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return StandardCompare.tableNumberRangeFilter({
            values: {
              min: selectedSystolicStartValue,
              current: r.systolic,
              max: selectedSystolicEndValue,
            },
            limits: {
              min: MIN_SYSTOLIC_VALUE,
              max: MAX_SYSTOLIC_VALUE,
            },
          })
        })
    }

    if (selectedDiastolicStartValue !== MIN_DIASTOLIC_VALUE
      || selectedDiastolicEndValue !== MAX_DIASTOLIC_VALUE
    ) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return StandardCompare.tableNumberRangeFilter({
            values: {
              min: selectedDiastolicStartValue,
              current: r.diastolic,
              max: selectedDiastolicEndValue,
            },
            limits: {
              min: MIN_DIASTOLIC_VALUE,
              max: MAX_DIASTOLIC_VALUE,
            },
          })
        })
    }
  }

  return filteredRecordings
}
