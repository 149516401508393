const { TeleKardiaAPI, ChatKardiaAPI, GoAPI, KccGoAPI } = require('./Axios/axios');

class Configuration {
  constructor() {
    // this.DoctorPortalAPI = DoctorPortalAPI;
    this.ChatKardiaAPI = ChatKardiaAPI;
    this.GoAPI = GoAPI;
    this.KccGoAPI = KccGoAPI;
  }
  setTeleKardiaAPI(type) {
    this.TeleKardiaAPI = TeleKardiaAPI;
    this.TeleKardiaBaseUrl = process.env.REACT_APP_TELEKARDIA_API_BASE;
  }
}
export default new Configuration();
