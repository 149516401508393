import { getToken } from 'auth/AuthUtilities';
import { EscalationAPI, GoAPI, IntakeKardiaAPI, KccGoAPI, TeleKardiaAPI } from '../../Axios/axios';
import { getTimezoneName } from 'utilities/Utilities';
import * as actionTypes from './actionTypes';
import { ALERT_NOTE } from 'constants/app';
import { intakePrograms } from 'utilities/Constants';

const getFullfilmentMLStart = () => ({
  type: actionTypes.FULLFILMENT_MEMBER_LIST_LOADING,
});

const getFullfilmentMLSuccess = (data) => ({
  type: actionTypes.FULLFILMENT_MEMBER_LIST_SUCCESS,
  payload: data,
});

const getFullfilmentMLFail = (err) => ({
  type: actionTypes.FULLFILMENT_MEMBER_LIST_FAILURE,
  payload: { error: err },
});

export const getFullfilmentML = () => (dispatch, getState) => {
  dispatch(getFullfilmentMLStart());
  KccGoAPI.get(`/i/v1/kcc/fullfilment/list`, {
    headers: { Authorization: ` Bearer ${getToken()}` },
  })
    .then((res) => {
      let resultData = res.data;
      if (res.status === 200) {
        dispatch(getFullfilmentMLSuccess(resultData));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(getFullfilmentMLFail(err.message));
    });
};

export const updateShippingDetails = (memberId, data) => {
  return KccGoAPI.put(
    `/i/v1/kcc/fullfilment/list/${memberId}/shipping`,
    { status: data.status, note: data.note },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  )
    .then((res) => {
      if (res.status == 200) {
        return true;
      }
      return false;
    })
    .catch((err) => {
      return false;
    });
};

export const getActivityTimelineForUser = (memberId) => {
  console.log('here');
  return KccGoAPI.get(`/i/v1/kcc/fullfilment/list/${memberId}/timeline`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  })
    .then((res) => {
      if (res.status == 200) {
        return res.data;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
};

export const getIntakeDetailsForUser = (userId) => {
  let retVal = {};
  return IntakeKardiaAPI.get(
    `/i/v1/intakeCategory/${intakePrograms.PP}/progress?userId=${userId}`,
    {
      headers: { Authorization: ` Bearer ${getToken()}` },
    },
  )
    .then((res) => {
      if (res.status == 200) {
        retVal['pp'] = res.data;
        return IntakeKardiaAPI.get(
          `/i/v1/intakeCategory/${intakePrograms.HHA}/progress?userId=${userId}`,
          {
            headers: { Authorization: ` Bearer ${getToken()}` },
          },
        ).then((res) => {
          retVal['hha'] = res.data;
          return retVal;
        });
      }
      return false;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};
