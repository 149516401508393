import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import * as actions from 'store/actions/index';
import 'assets/styles/popup.scss';
import 'assets/styles/react-big-calendar.scss';
import 'assets/styles/react-date-picker.scss';
import 'assets/styles/bootstrap.scss';
import PopOver from './PopOver';
import Alert from '../Shared/Alert/Alerts';
import CustomToolbar from './toolbar';
import { withTranslation } from 'react-i18next';
import { getTimezoneName } from 'utilities/Utilities';
import 'assets/styles/style.scss';
import 'moment/locale/es';
import 'moment/locale/fr';
moment.locale(navigator.language);
const localizer = momentLocalizer(moment); // or globalizeLocalizer
const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
const start = moment();
const remainder = 15 - (start.minute() % 15);
const date = moment(start).add(remainder, 'minutes').format(pattern);
class MyCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'day',
      onNavigate: date,
      isOpen: false,
      isFetching: true,
      startDate: date,
      endDate: date,
      startTime: date,
      endTime: date,
      startDateRange: moment(this.props.date).startOf('day')._d,
      endDateRange: moment(this.props.date).endOf('day')._d,
      alert: null,
    };
    this.baseState = this.state;
  }

  componentDidUpdate(prevProps, prevState) {
    let queryParams = {
      endDate: moment(this.state.endDateRange).format(pattern),
      startDate: moment(this.state.startDateRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      splitBasedOnDays: true,
    };
    if (
      prevState.onNavigate !== this.state.onNavigate ||
      prevState.view !== this.state.view ||
      prevProps.date !== this.props.date
    ) {
      this.props.onFetchEvents(this.props.profileId, this.props.teamId, queryParams);
    }
  }
  componentDidMount() {
    let queryParams = {
      endDate: moment(this.state.endDateRange).format(pattern),
      startDate: moment(this.state.startDateRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      splitBasedOnDays: true,
    };
    this.props.onFetchEvents(this.props.profileId, this.props.teamId, queryParams);
  }
  componentWillUnmount() {
    this.setState({
      isOpen: false,
    });
  }

  modalHandler = () => {
    this.setState({
      isOpen: false,
    });
  };
  //EVENT STYLE GETTER FOR SLYLING AN EVENT ITEM
  eventStyleGetter = (event, view) => {
    let background = '#885F85';
    let cursor = view === 'month' ? 'default' : 'pointer';
    // eslint-disable-next-line no-prototype-builtins
    if (event.hasOwnProperty('custom')) {
      return {
        className: 'patient_' + view,
        style: { background: background, cursor: cursor },
      };
    } else {
      return {
        className: 'doctors_' + view,
        style: {
          cursor: cursor,
        },
      };
    }
  };

  DateHandler = (slots) => {
    if (slots.length === 7) {
      this.setState({
        startDateRange: slots[0],
        endDateRange: slots[6].setHours(23, 59, 59, 999),
      });
    } else if (slots.length === 1) {
      let now = new Date(slots[0]);
      this.setState({
        startDateRange: slots[0],
        endDateRange: now.setHours(23, 59, 59, 999),
      });
    } else {
      this.setState({
        startDateRange: slots.start,
        endDateRange: slots.end,
      });
    }
  };
  viewHandler = (view) => {
    this.setState({
      view: view,
    });
  };
  startDate_handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  endDate_handleChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  startTime_handleChange = (date) => {
    this.setState({
      startTime: date,
    });
  };
  endTime_handleChange = (date) => {
    this.setState({
      endTime: date,
    });
  };
  calculateDifferenceInHours = (a, b) => {
    let dif = a.getTime() - b.getTime();
    return dif;
  };

  CustomDateHeader = (props) => {
    if (this.state.view === 'day') {
      return (
        <div>
          <div className="d-flex flex-row cal_header">
            <div className="availability_header text-center text-uppercase">
              {this.props.t('Availability')}
            </div>
            <div className="appointment_header text-left text-uppercase pl-3">
              {this.props.t('Consultations')}
            </div>
          </div>
        </div>
      );
    } else if (this.state.view === 'week') {
      return (
        <div>
          <div className="d-flex flex-column calweek_header">
            <div className="text-left text-uppercase px-1">
              {moment(props.date).format('dddd, D ')}
            </div>
            <div className="d-flex flex-row border_top">
              <div className="availability_header text-center">{this.props.t('Availability')}</div>
              <div className="appointment_header text-center">{this.props.t('Consultations')}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>{moment(props.date).format('dddd')}</div>;
    }
  };

  CustomTimeHeader() {
    return <div className="headtime_day text-center text-uppercase">Time</div>;
  }

  render() {
    let cEvents = this.props.events;
    let fEvents = this.props.events;
    if (this.props.events.length > 0 && this.state.view === 'month') {
      fEvents = [];
      let dayElement = {};
      cEvents.forEach((element) => {
        if (!dayElement['start']) {
          dayElement['start'] = element.start;
          dayElement['end'] = element.end;
          dayElement['availableMinutes'] = 0;
          dayElement['plannedApps'] = 0;
        }
        if (
          new Date(dayElement['start']).toDateString() !== new Date(element['start']).toDateString()
        ) {
          fEvents.push(dayElement);
          dayElement = {};
          dayElement['start'] = element.start;
          dayElement['end'] = element.end;
          dayElement['availableMinutes'] = 0;
          dayElement['plannedApps'] = 0;
        }

        if (element.custom === 'patient') dayElement['plannedApps']++;
        else
          dayElement['availableMinutes'] += this.calculateDifferenceInHours(
            element.end,
            element.start,
          );
      });
      if (dayElement['availableMinutes'] > 0) fEvents.push(dayElement);
    }

    let calendar = (
      <div className={this.props.isLoading ? 'filtering' : null}>
        <div className={this.props.isLoading ? 'spinner-border' : null}></div>
        <Calendar
          popup
          getNow={() => this.props.date}
          selectable={this.state.view === 'month' ? true : false}
          onRangeChange={(slots) => this.DateHandler(slots)}
          onView={(view) => this.viewHandler(view)}
          onNavigate={(slot) => this.setState({ onNavigate: slot })}
          localizer={localizer}
          step={30}
          formats={{ timeGutterFormat: 'h A' }}
          resizable
          defaultView={'day'}
          views={{
            day: true,
            week: true,
            month: true,
          }}
          tooltipAccessor={null}
          style={{ height: 'calc(100vh - 200px)' }}
          events={fEvents}
          components={{
            event: (prps) => (
              <PopOver
                {...prps}
                {...this.props}
                sRange={this.state.startDateRange}
                view={this.state.view}
                eRange={this.state.endDateRange}
                teamId={this.props.teamId}
                clinicianId={this.props.profileId}
              />
            ),
            toolbar: (prps) => (
              <CustomToolbar
                {...prps}
                {...this.props}
                start={this.state.startDateRange}
                end={this.state.endDateRange}
              />
            ),
            week: {
              header: this.CustomDateHeader,
            },
            day: { header: this.CustomDateHeader },
            month: { header: this.CustomDateHeader },
            timeGutterHeader: this.CustomTimeHeader,
          }}
          eventPropGetter={(event) => this.eventStyleGetter(event, this.state.view)}
          // onSelectSlot={false}
        />
      </div>
    );
    let error = this.props.error ? <Alert severity="error" message={this.props.error} /> : null;
    return (
      <div className="telekardia">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 overflow-auto">
              <div className="calendar-container full_calendar">
                {error}
                {this.state.alert}
                {calendar}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if ('events' in state.events && !Array.isArray(state.events.events))
    return {
      events: [],
    };
  return {
    events: state.events.events,
    error: state.events.error || state.appointments.error,
    isLoading: state.events.loading,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onAddingEvent: (teamId, clinicianid, eventData, queryParams) =>
      dispatch(actions.addAvailability(teamId, clinicianid, eventData, queryParams)),
    onFetchEvents: (clinicianid, teamId, queryParams) =>
      dispatch(actions.admingetAvailabilities(clinicianid, teamId, queryParams)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyCalendar));
