import React, { memo, useState } from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { lightWhite, paleBlue } from 'style/colors';

const Tab = styled.div`
  margin-right: 15px;
  border-radius: 20px;
  background-color: ${(props) => props?.bg};
  color: ${(props) => props?.color};
  display: block;
  margin-top: 8px;
  padding: 12px 20px;
  cursor: pointer;
`;

const SelectBox = (props) => {
  // const [selectedState,setSelectedState] = useState(props.tab);

  const handleTab = (tab) => {
    // setSelectedState(tab?.tab);
    props.getValue(tab?.tab);
  };
  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      {props.tabs &&
        props?.tabs?.map((tab, index) => {
          return (
            <Tab
              bg={props.value === tab?.tab ? paleBlue : lightWhite}
              color={props.value === tab?.tab ? 'white' : 'black'}
              onClick={() => handleTab(tab)}
            >
              {tab?.tab}
            </Tab>
          );
        })}
    </Box>
  );
};

export default memo(SelectBox);
