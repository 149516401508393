import React from 'react';

import Slider from '../../Metrics/Slider';
import Popover from '../components/Popover';
import styles from './SliderPopover.module.css';

const SliderPopover = (props) => {
  const { handleOnChange, handleOnOutsidePopoverClick, label, min, max, minValue, maxValue } =
    props;

  return (
    <Popover className={styles.sliderPopover} onOutsidePopoverClick={handleOnOutsidePopoverClick}>
      <h2 className={styles.sliderTitle}>{label}</h2>
      {/* className={styles.slider} */}
      <div >
        <Slider
          min={min}
          max={max}
          minValue={minValue}
          maxValue={maxValue}
          handleOnChange={handleOnChange}
        />
      </div>
    </Popover>
  );
};

export default SliderPopover;
