import { styled } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import CountdownTimer from 'react-component-countdown-timer';

const Root = styled('div')(({ theme }) => ({
  '& span': {
    color: 'white',
    fontSize: '20px',
    marginTop: '12px',
  },
}));
const Countdown_Timer = (props) => {
  const [settings, setsettings] = useState({
    count: props?.count,
  });

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    setsettings({
      count: props.count,
    });
    // let timer = setTimeout(() => {
    //   props?.endTimer(settings.count);
    // }, props.count * 1000);
    forceUpdate();
    // return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.count]);

  return (
    <Root>
      <CountdownTimer
        responsive
        {...settings}
        size={40}
        hideDay
        hideHours
        showTitle
        border
        noPoints={false}
        backgroundColor={props.color}
      />
    </Root>
  );
};

export default Countdown_Timer;
