/* eslint-disable */
import * as API from '../../Services/API/actions';
import moment from 'moment';
//import { useDispatch } from "react-redux";
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Statistics from './statistics';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    '& .MuiAppBar-root': {
      borderBottom: '1px solid #BABFBD',
      backgroundColor: '#fff',
      boxShadow: 'none',
      zIndex: 'unset',
    },
  },
  customTabRoot: {
    color: '#BABFBD',
    '& .Mui-selected': {
      color: '#142A39 !important',
    },
  },
  customTabIndicator: {
    backgroundColor: '#2d9f86',
    height: '4px',
  },
  tabRoot: {
    fontSize: '14px !important',
    // textTransform: 'initial',
    // minWidth: 100,
    minWidth: 'auto',
    fontWeight: '700',
    fontFamily: ['Work Sans'].join(','),
    // '&:hover': {
    //   color: '#40a9ff',
    //   opacity: 1,
    // },
    // '&$tabSelected': {
    //   color: '#1890ff',
    //   fontWeight: '500',
    //   borderBottom: 'none !important',
    // },
    // '&:focus': {
    //   color: '#40a9ff',
    // },
  },
});

const ScrollableTabsButtonAuto = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="relative" color="default">
        <Tabs
          // className="custom_tab"
          classes={{
            root: classes.customTabRoot,
            indicator: classes.customTabIndicator,
          }}
          value={props.value}
          onChange={props.handleChange}
          scrollButtons="auto"
          centered
          variant="fullWidth"
        >
          <Tab label={t('DAY')} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
          <Tab label={t('WEEK')} classes={{ root: classes.tabRoot }} {...a11yProps(1)} />
          <Tab label={t('MONTH')} classes={{ root: classes.tabRoot }} {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <>
        <TabPanel value={props.value} index={0}>
          <Statistics stats={props.stats} label="day" />
        </TabPanel>
        <TabPanel value={props.value} index={1}>
          <Statistics stats={props.stats} label="week" />
        </TabPanel>
        <TabPanel value={props.value} index={2}>
          <Statistics stats={props.stats} label="month" />
        </TabPanel>
      </>
    </div>
  );
};
export default ScrollableTabsButtonAuto;
