import { connect } from 'react-redux';

import {
  // getDataSource,
  handleToggleNavigationSidebar,
  user_logout as handleLogout,
} from 'store/actions';
import { updatePrevLocation } from 'store/reducers/app';

import NavigationSidebar from './NavigationSidebar';

const mapActionCreators = {
  handleLogout,

  handleToggleNavigationSidebar,
  updatePrevLocation,
};

const mapStateToProps = (state) => ({
  sidebarIsCollapsed: state.sidebar.sidebarIsCollapsed,
  me: { data: state.user },
});

export default connect(mapStateToProps, mapActionCreators)(NavigationSidebar);
