import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxLabels(props) {
  const [isChecked, setIsChecked] = React.useState(props.checked);

  const handleCheckboxChange = (event) => {
    // setIsChecked(!isChecked);
    props.getValue(event.target.checked)
  };

  React.useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  return (
    <FormGroup>
    {
      isChecked===true ? <FormControlLabel control={<Checkbox checked={props.checked} onChange={handleCheckboxChange}  />} label={`${props.label}`} /> : <FormControlLabel control={<Checkbox checked={false} onChange={handleCheckboxChange}  />} label={`${props.label}`} />
    } 
    </FormGroup>
  );
}