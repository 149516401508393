import * as actionTypes from './actionTypes';
import { getToken } from 'auth/AuthUtilities';
import moment from 'moment';
import { getTimezoneName } from 'utilities/Utilities';
import { TeleKardiaAPI } from 'Axios/axios';

export const addAvailabilityStart = () => {
  return {
    type: actionTypes.ADD_AVAILABILITY_START,
  };
};

export const addAvailabilitySuccess = (availabilityData) => {
  return {
    type: actionTypes.ADD_AVAILABILITY_SUCCESS,
    availabilityData: availabilityData,
  };
};

export const addAvailabilityFail = (error) => {
  return {
    type: actionTypes.ADD_AVAILABILITY_FAIL,
    error: error,
  };
};

export const refreshAvailability = () => (dispatch, getState) => {
  const { user, events } = getState();
  const { queryParams } = events || {};
  const {
    profile: { id: clinicianid },
    team: { id: teamId },
  } = user || {};
  // const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
  // let queryParams = {
  //   endDate: moment(moment().endOf('day')._d).format(pattern),
  //   startDate: moment(moment().startOf('day')._d).format(pattern),
  //   offsetInMinutes: new Date().getTimezoneOffset(),
  //   timeZone: getTimezoneName(),
  //   splitBasedOnDays: true,
  // };
  dispatch(getAvailabilities(clinicianid, teamId, queryParams));
};

export const addAvailability = (teamId, clinicianid, availabilityData, queryParams) => {
  return (dispatch) => {
    dispatch(addAvailabilityStart());
    TeleKardiaAPI.post(
      `/api/v1/teams/${teamId}/clinicians/${clinicianid}/availability-blocks/`,
      availabilityData,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
        params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data) dispatch(addAvailabilityFail(res.data['detailed_error']));
        else {
          dispatch(addAvailabilitySuccess(res.data));
          dispatch(getAvailabilities(clinicianid, teamId, queryParams));
        }
      })
      .catch((err) => {
        dispatch(addAvailabilityFail(err));
      });
  };
};

export const addAvailability_AvailabilityForm = (teamId, clinicianid, availabilityData) => {
  return (dispatch) => {
    dispatch(addAvailabilityStart());
    TeleKardiaAPI.post(
      `/api/v1/teams/${teamId}/clinicians/${clinicianid}/availability-blocks/`,
      availabilityData,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
        params: { timeZoneOffset: new Date().getTimezoneOffset(), timeZone: getTimezoneName() },
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data) dispatch(addAvailabilityFail(res.data['detailed_error']));
        else {
          dispatch(addAvailabilitySuccess(res.data));
        }
      })
      .catch((err) => {
        dispatch(addAvailabilityFail(err));
      });
  };
};

export const getAvailabilitiesStart = ({ queryParams }) => {
  return {
    type: actionTypes.GET_AVAILABILITY_START,
    payload: { queryParams },
  };
};

export const getAvailabilitiesSuccess = (availabilityData) => {
  return {
    type: actionTypes.GET_AVAILABILITY_SUCCESS,
    availabilityData: availabilityData,
  };
};

export const getAvailabilitiesFail = (error) => {
  return {
    type: actionTypes.GET_AVAILABILITY_FAIL,
    error: error,
  };
};

export const getAvailabilities = (clinicianid, teamId, queryParams) => {
  return (dispatch) => {
    dispatch(getAvailabilitiesStart({ queryParams }));
    TeleKardiaAPI.get(`/api/v1/teams/${teamId}/clinicians/${clinicianid}/calendar/`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
      params: queryParams,
    })
      .then((res) => {
        if ('detailed_error' in res.data) {
          dispatch(getAvailabilitiesFail(res.data['detailed_error']));
        } else {
          const fetchedEvents = [];
          for (let key in res.data.availabilities) {
            fetchedEvents.push({
              eventType: 'availability',
              id: res.data.availabilities[key].id,
              title: 'My Availability ',
              isSplit: res.data.availabilities[key].isSplit,
              start: res.data.availabilities[key].isSplit
                ? moment(res.data.availabilities[key].splitStartDateTime)._d
                : moment(res.data.availabilities[key].startDateTime)._d,
              end: res.data.availabilities[key].isSplit
                ? moment(res.data.availabilities[key].splitEndDateTime)._d
                : moment(res.data.availabilities[key].endDateTime)._d,
              isRecurring: res.data.availabilities[key].isRecurring,
              RecurrsiveStart: res.data.availabilities[key].recurrenceStartDateTime,
              RecurrsiveEnd: res.data.availabilities[key].recurrenceEndDateTime,
              recurrenceStartTime: res.data.availabilities[key].recurrenceStartTime,
              recurrenceEndTime: res.data.availabilities[key].recurrenceEndTime,
              RecurrencePattern: res.data.availabilities[key].isRecurring
                ? res.data.availabilities[key].recurrencePattern
                : 'NotRecursive',
              splitStartDateTime: res.data.availabilities[key].isSplit
                ? moment(res.data.availabilities[key].startDateTime)._d
                : null,
              splitEndDateTime: res.data.availabilities[key].isSplit
                ? moment(res.data.availabilities[key].endDateTime)._d
                : null,
              instanceDateTime: res.data.availabilities[key].instanceDateTime,
              version: res.data.availabilities[key].version,
            });
          }

          for (let key in res.data.appointments) {
            fetchedEvents.push({
              eventType: 'appointment',
              id: res.data.appointments[key].appointmentId,
              isSplit: false,
              patient: res.data.appointments[key].patient,
              patientId: res.data.appointments[key].patient.patientId,
              title: res.data.appointments[key].appointmentStatus,
              start: moment(res.data.appointments[key].scheduledStartTime)._d,
              end: moment(res.data.appointments[key].scheduledEndTime)._d,
              visitReason: res.data.appointments[key].visitReason,
              custom: 'patient',
              type: res.data.appointments[key].appointmentTypeId,
              status: res.data.appointments[key].appointmentStatusId,
              statusName: res.data.appointments[key].appointmentStatusDisplayName,
              ekgId:
                res.data.appointments[key].ekgResults &&
                res.data.appointments[key].ekgResults.length > 0
                  ? res.data.appointments[key].ekgResults[0].ekgRecordId
                  : null,
              version: res.data.appointments[key].version,
              appointmentChannelType: res.data.appointments[key].appointmentChannelType
                ? res.data.appointments[key].appointmentChannelType
                : '',
              appointmentIndex: res.data.appointments[key].appointmentIndex,
              followUpDuration: res.data.appointments[key].followUpDuration,
            });
          }
          fetchedEvents.sort(function (a, b) {
            return new Date(a.start) - new Date(b.end);
          });
          dispatch(getAvailabilitiesSuccess(fetchedEvents));
        }
      })
      .catch((err) => {
        dispatch(getAvailabilitiesFail(err));
      });
  };
};
export const admingetAvailabilities = (clinicianid, teamId, queryParams) => {
  return (dispatch) => {
    dispatch(getAvailabilitiesStart({ queryParams }));
    TeleKardiaAPI.get(`/api/v1/admin/teams/${teamId}/clinicians/${clinicianid}/calendar/`, {
      headers: { Authorization: ` Bearer ${getToken()}` },
      params: queryParams,
    })
      .then((res) => {
        if ('detailed_error' in res.data) {
          dispatch(getAvailabilitiesFail(res.data['detailed_error']));
        } else {
          const fetchedEvents = [];
          for (let key in res.data.availabilities) {
            fetchedEvents.push({
              eventType: 'availability',
              id: res.data.availabilities[key].id,
              title: 'My Availability ',
              isSplit: res.data.availabilities[key].isSplit,
              start: res.data.availabilities[key].isSplit
                ? moment(res.data.availabilities[key].splitStartDateTime)._d
                : moment(res.data.availabilities[key].startDateTime)._d,
              end: res.data.availabilities[key].isSplit
                ? moment(res.data.availabilities[key].splitEndDateTime)._d
                : moment(res.data.availabilities[key].endDateTime)._d,
              isRecurring: res.data.availabilities[key].isRecurring,
              RecurrsiveStart: res.data.availabilities[key].recurrenceStartDateTime,
              RecurrsiveEnd: res.data.availabilities[key].recurrenceEndDateTime,
              recurrenceStartTime: res.data.availabilities[key].recurrenceStartTime,
              recurrenceEndTime: res.data.availabilities[key].recurrenceEndTime,
              RecurrencePattern: res.data.availabilities[key].isRecurring
                ? res.data.availabilities[key].recurrencePattern
                : 'NotRecursive',
              splitStartDateTime: res.data.availabilities[key].isSplit
                ? moment(res.data.availabilities[key].startDateTime)._d
                : null,
              splitEndDateTime: res.data.availabilities[key].isSplit
                ? moment(res.data.availabilities[key].endDateTime)._d
                : null,
              instanceDateTime: res.data.availabilities[key].instanceDateTime,
              version: res.data.availabilities[key].version,
            });
          }

          for (let key in res.data.appointments) {
            fetchedEvents.push({
              eventType: 'appointment',
              id: res.data.appointments[key].appointmentId,
              isSplit: false,
              patient: res.data.appointments[key].patient,
              patientId: res.data.appointments[key].patient.patientId,
              title: res.data.appointments[key].appointmentStatus,
              start: moment(res.data.appointments[key].scheduledStartTime)._d,
              end: moment(res.data.appointments[key].scheduledEndTime)._d,
              visitReason: res.data.appointments[key].visitReason,
              custom: 'patient',
              status: res.data.appointments[key].appointmentStatusId,
              statusName: res.data.appointments[key].appointmentStatusDisplayName,
              type: res.data.appointments[key].appointmentTypeId,
              ekgId:
                res.data.appointments[key].ekgResults &&
                res.data.appointments[key].ekgResults.length > 0
                  ? res.data.appointments[key].ekgResults[0].ekgRecordId
                  : null,
              version: res.data.appointments[key].version,
              appointmentChannelType: res.data.appointments[key].appointmentChannelType
                ? res.data.appointments[key].appointmentChannelType
                : '',
            });
          }
          fetchedEvents.sort(function (a, b) {
            return new Date(a.start) - new Date(b.end);
          });
          dispatch(getAvailabilitiesSuccess(fetchedEvents));
        }
      })
      .catch((err) => {
        dispatch(getAvailabilitiesFail(err));
      });
  };
};
export const updateAvailabilityStart = () => {
  return {
    type: actionTypes.UPDATE_AVAILABILITY_START,
  };
};

export const updateAvailabilitySuccess = (id, availabilityData) => {
  return {
    type: actionTypes.UPDATE_AVAILABILITY_SUCCESS,
    availabilityData: availabilityData,
    id: id,
  };
};

export const updateAvailabilityFail = (error) => {
  return {
    type: actionTypes.UPDATE_AVAILABILITY_FAIL,
    error: error,
  };
};

export const updateAvailability = (
  id,
  teamId,
  clinicianid,
  availabilityData,
  params,
  queryParams,
) => {
  return (dispatch) => {
    dispatch(updateAvailabilityStart());
    TeleKardiaAPI.put(
      `/api/v1/teams/${teamId}/clinicians/${clinicianid}/availability-blocks/${id}`,
      availabilityData,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
        params: params,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data)
          dispatch(updateAvailabilityFail(res.data['detailed_error']));
        else {
          dispatch(updateAvailabilitySuccess(id, availabilityData));
          dispatch(getAvailabilities(clinicianid, teamId, queryParams));
        }
      })
      .catch((err) => {
        dispatch(updateAvailabilityFail(err));
      });
  };
};

export const updateRecurrsiveAvailability = (
  id,
  teamId,
  clinicianid,
  availabilityData,
  params,
  queryParams,
) => {
  return (dispatch) => {
    dispatch(updateAvailabilityStart());
    TeleKardiaAPI.put(
      `/api/v1/teams/${teamId}/clinicians/${clinicianid}/availability-blocks/${id}/recurring-single`,
      availabilityData,
      {
        headers: { Authorization: ` Bearer ${getToken()}` },
        params: params,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data)
          dispatch(updateAvailabilityFail(res.data['detailed_error']));
        else {
          dispatch(updateAvailabilitySuccess(id, availabilityData));
          dispatch(getAvailabilities(clinicianid, teamId, queryParams));
        }
      })
      .catch((err) => {
        dispatch(updateAvailabilityFail(err));
      });
  };
};
export const deleteAvailabilityStart = () => {
  return {
    type: actionTypes.DELETE_AVAILABILITY_START,
  };
};
export const deleteAvailabilitySuccess = (id) => {
  return {
    type: actionTypes.DELETE_AVAILABILITY_SUCCESS,
    id: id,
  };
};

export const deleteAvailabilityFail = (error) => {
  return {
    type: actionTypes.DELETE_AVAILABILITY_FAIL,
    error: error,
  };
};
export const deleteAvailability = (id, teamId, clinicianid, queryParams, deleteparams) => {
  return (dispatch) => {
    dispatch(deleteAvailabilityStart());
    TeleKardiaAPI.delete(
      `/api/v1/teams/${teamId}/clinicians/${clinicianid}/availability-blocks/${id}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: deleteparams,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data)
          dispatch(deleteAvailabilityFail(res.data['detailed_error']));
        else {
          dispatch(deleteAvailabilitySuccess(id));
          dispatch(getAvailabilities(clinicianid, teamId, queryParams));
        }
      })
      .catch((err) => {
        dispatch(deleteAvailabilityFail(err));
      });
  };
};
export const deleteRecurrenceAvailability = (id, teamId, clinicianid, params, queryParams) => {
  return (dispatch) => {
    dispatch(deleteAvailabilityStart());
    TeleKardiaAPI.delete(
      `/api/v1/teams/${teamId}/clinicians/${clinicianid}/availability-blocks/${id}/recurring-single`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: params,
      },
    )
      .then((res) => {
        if ('detailed_error' in res.data) {
          dispatch(deleteAvailabilityFail(res.data['detailed_error']));
        } else {
          dispatch(deleteAvailabilitySuccess(id));
          dispatch(getAvailabilities(clinicianid, teamId, queryParams));
        }
      })
      .catch((err) => {
        dispatch(deleteAvailabilityFail(err));
      });
  };
};
