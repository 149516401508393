import { makeStyles } from '@material-ui/core/styles';
import { darkBluishGrey, white, lightGreen } from '../../style/colors';
import { fontFamily } from '../../style/commonStyles';

export const useStyles = makeStyles({
  snackBarStyle: {
    width: '100%',
    // position: "absolute",
    // height: "50px",
    // top: "0",
  },
  checkIconCustom: {
    // backgroundColor: '#067F6F',
    borderRadius: '50%',
    // fill: 'white',
  },
  toastMessage: {
    fontFamily,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    width: '100%',
  },
  toastSuccessMessage: {
    width: '100%',
    // backgroundColor: lightGreen,
    // color: 'white',
    fontFamily,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
  },
});
