import metaJson from './metadata.json';

// check teleconnect enabled
export function checkTKFeatureEnabled(me) {
  if (me.data) {
    const { teams } = me.data;
    const features = teams ? teams[0].features : null;
    const idtfFeature = features && features.find((feat) => feat.feature === 'teleconnect');
    return idtfFeature && idtfFeature.enabled;
  }

  return me.isFetching;
}

export function checkReferralsOnlyFeatureEnabled(me) {
  if (me.data) {
    const { teams } = me.data;
    const features = teams ? teams[0].features : null;
    const rpm = features && features.find((feat) => feat.feature === 'rpm');
    const referral = features && features.find((feat) => feat.feature === 'referral');
    const rpmEnabled = rpm ? rpm.enabled : false;
    const referralEnabled = referral ? referral.enabled : false;

    return referralEnabled && !rpmEnabled;
  }

  return me.isFetching;
}

export function getBuildVersion() {
  return metaJson.buildVersion;
}

export function getBuildDateTime() {
  return metaJson.buildDateTime;
}

export function getAppVersion() {
  return metaJson.release;
}
