import { makeStyles } from "@material-ui/core/styles";
import {
  darkBluishGrey,
  slateGrey,
  lightAquaGreen,
  pantoneRed,
} from "../../style/colors";
import { fontFamily } from "../../style/commonStyles";

export const useStyles = makeStyles({
  container: {
    marginTop: "2rem",
  },
  headingContainer: {
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  heading: {
    fontFamily,
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.25px",
  },
  subHeadingContainer: {
    marginBottom: "0.4rem",
  },
  subHeading: {
    fontFamily,
    fontSize: "14px",
    lineHeight: "16px",
    color: slateGrey,
  },
  errorSubHeading: {
    color: pantoneRed,
  },
  dateFilterContainer: {
    display: "flex",
  },
  dateRangePickerContainer: {
    height: "40px",
    marginTop:"-8px"
  },
  buttonStyle: {
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
    color: darkBluishGrey,
    fontWeight: "normal",
    border: "1px solid #BAC0C4",
    borderRadius: "0px",
    textTransform: "capitalize",
    letterSpacing: "-0.2px",
    height: "40px",
    minWidth: "100px",
    "&:hover": {
      backgroundColor: lightAquaGreen,
    },
  },
  leftMostButtonStyle: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  rightMostButtonStyle: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  selectedDateFilter: {
    background: lightAquaGreen,
  },
  errorMessage: {
    fontFamily,
    color: pantoneRed,
    marginTop: "1.25rem",
    fontSize: "14px",
  },
});
