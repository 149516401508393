import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CrossIcon from '../../assets/images/Vector (3).png';
import Grid from '@mui/material/Grid';
import styles from './EkgModal.module.css';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import styled from '@mui/material/styles/styled';
import { fetchSamples } from 'utilities/ApiUrls';
import * as Lib from '../../constants/RecordingConstants';
import { calcRowSeconds } from 'components/Utilities';
import EkgRecording from 'components/chart/EkgRecording';
import Loading from 'components/Loading';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 873,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  minHeight: 812,
  background: '#F1F2F3 !important',
  borderRadius: 3,
  border: 'none !important',
};

const StyledText = styled(
  Typography,
  {},
)(() => ({
  fontWeight: 400,
  fontSize: '15px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  letterSpacing: '0.17px',
}));

const EkgModal = (props) => {
  const { ecgData } = props;
  const [sampleData, setSampleData] = useState(ecgData);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  let contentHeight = 650;
  let ekgHeight = contentHeight;
  const mainContentWidth = 730;
  const MIN_SECONDS = 1;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0 ? calcRowSeconds(mainContentWidth) : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND;
  const calpulse = true;

  useEffect(() => {
    Object.keys(ecgData).length > 0 && getSamples(ecgData?.id);
  }, [ecgData]);

  async function getSamples(id) {
    try {
      setLoading(true);
      let data = await fetchSamples(id);
      setSampleData({ ...sampleData, [`enhanced`]: data?.data?.enhanced });
      setLoading(false);
    } catch (err) {
      setErrorMsg('No ECG Sample found');
    }
  }

  return (
    <Box sx={props.close ? style : { width: '100%' }}>
      {props.close && (
        <>
          <Grid container spacing={2} className={styles.gridHeader}>
            <Grid item xs={6}>
              <Typography variant="h5" className={styles.headerText}>
                {`ECG Details`}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <img src={CrossIcon} style={{ cursor: 'pointer' }} onClick={props.close} />
            </Grid>
          </Grid>
          <hr className={styles.hrStyle} />
        </>
      )}
      {loading ? (
        <div className={styles.loader}>
          <Loading />
        </div>
      ) : errorMsg !== null ? (
        <div className={styles.loader}>{errorMsg}</div>
      ) : (
        <Paper
          elevation={4}
          style={{
            position: 'relative',
            margin: props.close && '10px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            overflow: 'auto',
          }}
        >
          {props.close && (
            <>
              <Grid container sx={{ p: 3 }}>
                <Grid item xs={12}>
                  <StyledText
                    variant="body2"
                    className={styles.ecgDetails}
                    style={{ marginBottom: '14px' }}
                  >
                    {`Heart Rate: ${ecgData?.bpm} BPM`}
                  </StyledText>
                </Grid>
                <Grid item xs={12}>
                  <StyledText
                    variant="body2"
                    className={styles.ecgDetails}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span>{`Recorded: ${moment(ecgData?.recordedAt).format(
                      'MM-DD-YYYY',
                    )} at ${moment(ecgData?.recordedAt).format('hh:mm A')}`}</span>
                    {ecgData?.is6l && <span className={styles.smallBox}>KM6L</span>}
                  </StyledText>
                </Grid>
              </Grid>
            </>
          )}
          <div className={styles.recordingInfo} style={{ top: props.close && '100px !important' }}>
            <span>25 mm/s</span>
            <span>10 mm/mV</span>
          </div>
          <EkgRecording
            height={ekgHeight}
            width={ekgWidth}
            recording={sampleData?.enhanced}
            rowSeconds={rowSeconds}
            calpulse={calpulse}
            recordingSample={sampleData?.enhanced}
            inverted={sampleData.inverted} //inverted flag would depend on recorded device thus taken from api response
            sampleData={sampleData}
            is6l={ecgData.is6l}
          />
        </Paper>
      )}
    </Box>
  );
};

export default EkgModal;
