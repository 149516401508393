import React, { useCallback, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import { Checkbox, List, ListItem } from '@mui/joy';

import { useSelector } from 'react-redux';
import moment from 'moment';

import Timepicker from 'components/Appointments/Calendar/material_timepicker';
import Datepicker from 'components/Appointments/Calendar/material_datepicker';
import { DAYS_MAP, DAYS, HEART_HABIT_FIELDS } from '../data/constants';
import { fontSizes, fonts, spacing } from '../../../../style/styleSettings';
import { AUTH_TYPE } from 'constants/app';

const Root = styled(Card)(({ theme }) => ({
  padding: '0 10px',
  margin: '0 5px',
  border: '1px solid #c1c1c1',
  fontFamily: `${fonts.SESSION}`,
  '& .MuiSelect-outlined': {
    border: '1px solid rgba(20, 42, 57, 0.16)',
    padding: '10px',
  },
  '& .MuiButton-root': {
    // border: `1px solid ${colors.SPRUCE}`,
    borderRadius: '4px',
    fontFamily: `${fonts.SESSION}`,
    width: '100%',
  },
  '& .MuiIconButton-root': {
    padding: '0px',
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '5px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '18px, 10px',
    fontSize: '14px',
  },
  '& .MuiFormControl-root': {
    marginTop: '10px',
    paddingRight: '5px',
    width: '100%',
  },
  '& .css-108m3iu-JoyCheckbox-action': {
    backgroundColor: 'rgba(99, 135, 197, 0.3) !important',
  },
  '& .MuiTypography-h6': {
    fontWeight: '600 !important',
    color: '#000 !important',
    fontSize: `${fontSizes.MEDIUM} !important`,
    marginTop: '10px !important',
    fontFamily: 'Work Sans !important',
  },
  '& .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    fontSize: `${fontSizes.BASE}`,
    '& p:first-child': {
      fontWeight: '400',
      marginRight: `${spacing.SMALL}`,
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& > p ': {
      margin: '8px 0',
      color: 'rgba(0, 0, 0, 0.87)',
      marginRight: `${spacing.SESSION_MARGIN_SMALL}`,
    },
  },
  '& .MuiGrid-container': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
}));

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  border: '1px solid #c1c1c1',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '16px',
};

const HeartHabitCard = ({
  index,
  item,
  deleteCard,
  editCard,
  addCardHandlerSaveToApi,
  userType,
}) => {
  //redux state
  const clinician = `${useSelector((state) => state.user.profile.lastName)}, ${useSelector(
    (state) => state.user.profile.firstName,
  )}`;

  const [edit, setEditing] = useState(false);
  //local states
  const [hhData, sethhData] = useState({
    focus: '',
    focusNote: '',
    durationStartDate: moment().format(),
    durationEndDate: moment().format(),
    frequencyType: '',
    frequencyPattern: {
      days: [],
      date: moment().format(),
    },
    notificationType: '',
    notificationPattern: {
      days: [],
      date: moment().format(),
      time: moment().format(),
    },
  });
  const parseRecurrencePattern = (item) => {
    let hhFormatData = { ...item };
    hhFormatData.durationStartDate = item?.startDate;
    hhFormatData.durationEndDate = item?.endDate;
    //frequency
    if (item.frequencyRecurrencePattern) {
      let recursiveElements = item?.frequencyRecurrencePattern.split('=');
      let recurrencepattern = recursiveElements[1].split(';');
      let days = recursiveElements[3].split(':')[0].split(',');
      if (recurrencepattern[0] === 'DAILY') {
        hhFormatData.frequencyPattern = {
          days: Object.keys(DAYS_MAP),
          date: null,
        };
        hhFormatData.frequencyType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY;
      } else if (recurrencepattern[0] === 'WEEKLY') {
        hhFormatData.frequencyPattern = {
          days: days,
          date: null,
        };
        hhFormatData.frequencyType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY;
      } else {
        hhFormatData.frequencyPattern = {
          date: item.frequencyOnceDate,
          days: [],
        };
        hhFormatData.frequencyType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE;
      }
    } else {
      hhFormatData.frequencyType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY;
      hhFormatData.frequencyPattern = {
        days: Object.keys(DAYS_MAP),
        date: null,
      };
    }
    //notification
    if (item.notificationRecurrencePattern) {
      let recursiveElements = item?.notificationRecurrencePattern.split('=');
      let recurrencepattern = recursiveElements[1].split(';');
      let days = recursiveElements[3].split(':')[0].split(',');
      if (recurrencepattern[0] === 'DAILY') {
        hhFormatData.notificationPattern = {
          days: Object.keys(DAYS_MAP),
          time: item.notificationTime,
          date: null,
        };
        hhFormatData.notificationType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY;
      } else if (recurrencepattern[0] === 'WEEKLY') {
        hhFormatData.notificationPattern = {
          days: days,
          time: item.notificationTime,
          date: null,
        };
        hhFormatData.notificationType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY;
      } else if (recurrencepattern[0] === 'ONCE') {
        hhFormatData.notificationPattern = {
          date: item.notificationOnceDate,
          time: item.notificationTime,
          days: [],
        };
        hhFormatData.notificationType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE;
      } else hhFormatData.notificationType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.NONE;
    } else {
      hhFormatData.notificationType = HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY;
      hhFormatData.notificationPattern = {
        days: Object.keys(DAYS_MAP),
        date: null,
        time: null,
      };
    }
    return hhFormatData;
  };
  useEffect(() => {
    let it = parseRecurrencePattern(item);
    sethhData((prev) => ({
      ...prev,
      ...it,
    }));
    //checking if it is a new card
    if (!item.id) {
      setEditing(true);
    }
  }, [item]);
  const hhChangeHandler = (val, type) => {
    let tempData = { ...hhData };

    if (type === HEART_HABIT_FIELDS.FREQUENCY_TYPE) {
      if (val === HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY) {
        tempData.frequencyPattern.days = Object.keys(DAYS_MAP);
      }
      tempData[type] = val;
    } else if (type === HEART_HABIT_FIELDS.NOTIFICATION_TYPE) {
      if (val === HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY) {
        tempData.notificationPattern.days = Object.keys(DAYS_MAP);
      }
      tempData[type] = val;
    } else tempData[type] = val;

    sethhData((t) => tempData);
  };

  const [durationValidation, setDurationValidation] = useState(true);
  const [notificationWeeklyValidation, setNotificationWeeklyValidation] = useState(true);
  const [notificationDailyValidation, setNotificationDailyValidation] = useState(true);
  const [frequencyWeeklyValidation, setFrequencyWeeklyValidation] = useState(true);
  const [notificationOnceValidation, setNotificationOnceValidation] = useState(true);
  const [frequencyOnceValidation, setFrequencyOnceValidation] = useState(true);
  useEffect(() => {
    if (!moment(hhData.durationEndDate).isAfter(hhData.durationStartDate))
      setDurationValidation(false);
    else setDurationValidation(true);

    if (hhData.frequencyType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE) {
      if (
        moment(hhData.frequencyPattern.date).isBetween(
          hhData.durationStartDate,
          hhData.durationEndDate,
        ) ||
        moment(moment(hhData.frequencyPattern.date).format('MM-DD-YYYY')).isSame(
          moment(hhData.durationStartDate).format('MM-DD-YYYY'),
        ) ||
        moment(moment(hhData.frequencyPattern.date).format('MM-DD-YYYY')).isSame(
          moment(hhData.durationEndDate).format('MM-DD-YYYY'),
        )
      ) {
        setFrequencyOnceValidation(true);
      } else {
        setFrequencyOnceValidation(false);
      }
    } else setFrequencyOnceValidation(true);

    if (
      hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE &&
      (!moment(hhData.notificationPattern.date).isBetween(
        hhData.durationStartDate,
        hhData.durationEndDate,
      ) ||
        hhData.notificationPattern.time == null)
    )
      setNotificationOnceValidation(false);
    else setNotificationOnceValidation(true);

    if (
      hhData.frequencyType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY &&
      hhData.frequencyPattern.days.length < 1
    )
      setFrequencyWeeklyValidation(false);
    else setFrequencyWeeklyValidation(true);

    if (
      hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY &&
      (hhData.notificationPattern.days.length < 1 || hhData.notificationPattern.time == null)
    )
      setNotificationWeeklyValidation(false);
    else setNotificationWeeklyValidation(true);

    if (
      hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY &&
      hhData.notificationPattern.time == null
    )
      setNotificationDailyValidation(false);
    else setNotificationDailyValidation(true);
  }, [hhData]);

  const submitHandler = () => {
    if (edit) {
      //make api call
      const data = {
        startDate:
          hhData.durationStartDate &&
          moment(hhData.durationStartDate).format().split('T')[0] + 'T00:00:00.000Z',
        endDate:
          hhData.durationEndDate &&
          moment(hhData.durationEndDate).format().split('T')[0] + 'T00:00:00.000Z',
        focusNote: hhData.focusNote,
        frequencyRecurrencePattern: hhData.frequencyType,
        frequencyDays:
          hhData.frequencyType !== HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE
            ? hhData.frequencyPattern.days.reduce((acc, cur) => {
                return [...acc, DAYS_MAP[cur]];
              }, [])
            : [],
        frequencyOnceDate:
          hhData.frequencyType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE
            ? hhData.frequencyPattern.date
            : null,
        notificationRecurrencePattern: hhData.notificationType,
        notificationDays:
          hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY ||
          hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY
            ? hhData.notificationPattern.days.reduce((acc, cur) => {
                return [...acc, DAYS_MAP[cur]];
              }, [])
            : [],
        notificationOnceDate:
          hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE
            ? hhData.notificationPattern.date
            : null,
        notificationTime:
          hhData.notificationType !== HEART_HABIT_FIELDS.RECURRENCE_TYPE.NONE
            ? hhData.notificationPattern.time
            : null,
      };
      if (!item.id) {
        data['focus'] = hhData.focus;
        let ok = addCardHandlerSaveToApi(data);
        if (ok) {
          setEditing((e) => !e);
        }
      } else {
        setEditing((e) => !e);
        editCard(item.id, data);
      }
    } else {
      setEditing(true);
    }
  };

  const [open, setOpen] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({
    id: '',
    description: '',
  });
  const handleClose = () => setOpen(false);
  const deleteConfirm = useCallback((id, focus) => {
    setOpen(true);
    let description = `${focus} has been deleted by ${clinician}`;
    setDeleteData({
      id,
      description,
    });
  }, []);

  const [programFocus, setprogramFocus] = useState([]);
  const programFocusData = useSelector((state) => state.session.programFocus);
  useEffect(() => {
    setprogramFocus(programFocusData);
  }, [programFocusData]);

  return (
    <>
      <Root>
        <CardContent>
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              Heart Habit {index + 1}: {!edit && hhData.focus}
            </Typography>
            {edit && item.id !== null && (
              <CancelIcon style={{ cursor: 'pointer' }} onClick={() => setEditing(false)} />
            )}
          </Grid>
          {edit && (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Program Focus</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="programFocus"
                label="Program Focus"
                value={hhData.focus && hhData.focus.split('-')[0].trim()}
                onChange={(e) => hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.FOCUS)}
                disabled={item.id}
              >
                {programFocus.length > 0 ? (
                  programFocus.map((d, index) => {
                    return (
                      <MenuItem key={index} value={d.focus}>
                        {d.focus}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value="">Program focus yet to create</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {!edit ? (
            <Box>
              <p>{'Habit'}:</p>
              <p>{hhData.focusNote}</p>
            </Box>
          ) : (
            <TextField
              fullWidth
              label="Enter Heart Habit"
              name="heartHabit"
              disabled={item.id}
              value={hhData.focusNote}
              onChange={(e) => hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.FOCUS_NOTE)}
            />
          )}

          {!edit ? (
            <Box>
              <p>Duration: </p>
              <p>{`${moment(hhData.durationStartDate?.split('T')[0]).format(
                'MM-DD-YYYY',
              )} - ${moment(hhData.durationEndDate?.split('T')[0]).format('MM-DD-YYYY')}`}</p>
            </Box>
          ) : (
            <>
              <Typography variant="h6">Duration</Typography>
              <FormControl>
                <Grid container>
                  <Grid item xs={6}>
                    <Datepicker
                      date={hhData.durationStartDate}
                      changeHandle={(e) =>
                        hhChangeHandler(moment(e).format(), HEART_HABIT_FIELDS.DURATION_START_DATE)
                      }
                      disable={item.id}
                    />
                    {/* <TextField
                      fullWidth
                      // id="date"
                      variant="outlined"
                      label="Start"
                      type="date"
                      disabled={item.id}
                      format="MM-dd-yyyy"
                      defaultValue={moment(hhData.notificationPattern.date).format()}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        hhChangeHandler(
                          moment(e.target.value).format(),
                          HEART_HABIT_FIELDS.DURATION_START_DATE,
                        )
                      }
                    /> */}
                  </Grid>
                  <Grid item xs={6}>
                    <Datepicker
                      date={hhData.durationEndDate}
                      changeHandle={(e) =>
                        hhChangeHandler(moment(e).format(), HEART_HABIT_FIELDS.DURATION_END_DATE)
                      }
                    />
                  </Grid>
                  {!durationValidation && (
                    <p style={{ color: 'red' }}>End date should be greater than start date</p>
                  )}
                </Grid>
              </FormControl>
            </>
          )}

          {!edit ? (
            <Box>
              <p>Frequency:</p>
              <p>
                {Object.keys(HEART_HABIT_FIELDS.RECURRENCE_TYPE).find(
                  (key) => HEART_HABIT_FIELDS.RECURRENCE_TYPE[key] === hhData.frequencyType,
                ) || '-'}
              </p>
              {hhData.frequencyType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY && (
                <p>{` ( ${hhData.frequencyPattern.days} )`}</p>
              )}
              {hhData.frequencyType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE && (
                <p>{` ( ${moment(hhData.frequencyPattern.date).format('LL')} )`}</p>
              )}
            </Box>
          ) : (
            <>
              <Typography variant="h6">Frequency</Typography>
              <FormControl>
                <RadioGroup
                  fullWidth
                  // row
                  aria-label="frequency"
                  name="frequency"
                  value={hhData.frequencyType}
                  onChange={(e) =>
                    hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.FREQUENCY_TYPE)
                  }
                >
                  <FormControlLabel
                    value={HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE}
                    control={<Radio />}
                    disabled={item.id}
                    label="This habit repeats once"
                  />
                  <FormControlLabel
                    value={HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY}
                    control={<Radio />}
                    disabled={item.id}
                    label="This habit repeats daily"
                  />
                  <FormControlLabel
                    value={HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY}
                    control={<Radio />}
                    disabled={item.id}
                    label="This habit repeats on"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
          {edit && hhData.frequencyType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY && (
            <>
              <List
                fullWidth
                orientation="horizontal"
                wrap
                sx={{
                  '--List-gap': '10px',
                  '--ListItem-radius': '20px',
                }}
              >
                {DAYS.map((days, index) => (
                  <ListItem key={index}>
                    <Checkbox
                      disabled={item.id && index !== -1}
                      overlay
                      disableIcon
                      variant="solid"
                      label={days.day.substring(0, 1)}
                      checked={
                        hhData.frequencyPattern?.days.filter((day) => days.day == day).length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let tempData = { ...hhData };
                          tempData.frequencyPattern.days.push(days.day);
                          sethhData(tempData);
                        } else {
                          let index = hhData.frequencyPattern.days.indexOf(days.day);
                          let tempData = { ...hhData };
                          tempData.frequencyPattern.days.splice(index, 1);
                          sethhData(tempData);
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>

              {!frequencyWeeklyValidation && (
                <p style={{ color: 'red' }}>Atleast one day should be selected</p>
              )}
            </>
          )}
          {edit && hhData.frequencyType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE && (
            <FormControl>
              <Grid container>
                <Grid item xs={12}>
                  <Datepicker
                    date={hhData.frequencyPattern.date}
                    changeHandle={(e) => {
                      let tempData = { ...hhData };
                      tempData.frequencyPattern.date = moment(e).format();
                      sethhData(tempData);
                    }}
                    disabled={item.id}
                  />
                  {!frequencyOnceValidation && (
                    <p style={{ color: 'red' }}>
                      Frequency once date should be between Duration dates
                    </p>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          )}
          {!edit ? (
            <Box>
              {/* <p>Notification:</p>
              <p>
                {Object.keys(HEART_HABIT_FIELDS.RECURRENCE_TYPE).find(
                  (key) => HEART_HABIT_FIELDS.RECURRENCE_TYPE[key] === hhData.notificationType,
                ) || '-'}
              </p>
              {hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY && (
                <p>{` ( ${hhData.notificationPattern.days} )`}</p>
              )}
              {hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE && (
                <p>{` ( ${moment(hhData.notificationPattern.date).format('LL')} )`}</p>
              )} */}
            </Box>
          ) : (
            <>
              <Typography variant="h6">Notification</Typography>
              <FormControl>
                <RadioGroup
                  // row
                  aria-label="frequency"
                  name="notification"
                  value={hhData.notificationType}
                  // onChange={handleChange}
                  onChange={(e) =>
                    hhChangeHandler(e.target.value, HEART_HABIT_FIELDS.NOTIFICATION_TYPE)
                  }
                >
                  <FormControlLabel
                    value={HEART_HABIT_FIELDS.RECURRENCE_TYPE.NONE}
                    control={<Radio />}
                    label="No notification"
                  />
                  <FormControlLabel
                    value={HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE}
                    control={<Radio />}
                    label="Notify member once"
                  />
                  <FormControlLabel
                    value={HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY}
                    control={<Radio />}
                    label="Notify member daily"
                  />
                  <FormControlLabel
                    value={HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY}
                    control={<Radio />}
                    label="Repeat notifications on"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
          {edit && hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.WEEKLY && (
            <>
              <List
                fullWidth
                orientation="horizontal"
                wrap
                sx={{
                  '--List-gap': '10px',
                  '--ListItem-radius': '20px',
                }}
              >
                {DAYS.map((item, index) => (
                  <ListItem key={index}>
                    <Checkbox
                      // disabled={index === 0}
                      overlay
                      disableIcon
                      variant="solid"
                      label={item.day.substring(0, 1)}
                      checked={
                        hhData.notificationPattern?.days != undefined &&
                        hhData.notificationPattern?.days.filter((day) => item.day == day).length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let tempData = { ...hhData };
                          tempData.notificationPattern.days.push(item.day);
                          sethhData(tempData);
                        } else {
                          let index = hhData.notificationPattern.days.indexOf(item.day);
                          let tempData = { ...hhData };
                          tempData.notificationPattern.days.splice(index, 1);
                          sethhData(tempData);
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
              <Timepicker
                fullWidth
                label="Time"
                // valid={dateValidation}
                time={moment(hhData.notificationPattern.time)
                  .utc()
                  .format('YYYY-MM-DDTHH:mm:ss.SSS')}
                changeHandle={(time) => {
                  let tempData = { ...hhData };
                  tempData.notificationPattern.time = moment(time).format();
                  sethhData(tempData);
                }}
              />
              {!notificationWeeklyValidation && (
                <p style={{ color: 'red' }}>Atleast one day and time should be selected </p>
              )}
            </>
          )}
          {edit && hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.ONCE && (
            <FormControl>
              <Grid container>
                <Grid item xs={6}>
                  <Datepicker
                    date={hhData.notificationPattern.date}
                    changeHandle={(e) => {
                      let tempData = { ...hhData };
                      tempData.notificationPattern.date = moment(e).format();
                      sethhData(tempData);
                    }}
                    disabled={item.id}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Timepicker
                    fullWidth
                    label="Time"
                    time={moment(hhData.notificationPattern.time)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss.SSS')}
                    changeHandle={(time) => {
                      let tempData = { ...hhData };
                      tempData.notificationPattern.time = moment(time).format();
                      sethhData(tempData);
                    }}
                  />
                </Grid>
                {!notificationOnceValidation && (
                  <p style={{ color: 'red' }}>Date and time should be between Duration dates</p>
                )}
              </Grid>
            </FormControl>
          )}
          {edit && hhData.notificationType === HEART_HABIT_FIELDS.RECURRENCE_TYPE.DAILY && (
            <FormControl>
              <Grid container>
                <Grid item xs={12}>
                  <Timepicker
                    fullWidth
                    label="Time"
                    time={moment(hhData.notificationPattern.time)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss.SSS')}
                    changeHandle={(time) => {
                      let tempData = { ...hhData };
                      tempData.notificationPattern.time = moment(time).format();
                      sethhData(tempData);
                    }}
                  />
                </Grid>
                {!notificationDailyValidation && (
                  <p style={{ color: 'red' }}>Time should be selected </p>
                )}
              </Grid>
            </FormControl>
          )}
        </CardContent>
        <CardActions>
          {edit ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  // color="danger"
                  variant="outlined"
                  size="large"
                  sx={{ color: '#CC3D3F', borderColor: '#CC3D3F' }}
                  onClick={() => deleteConfirm(item.id, item.focus)}
                >
                  DELETE
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  // sx={{ backgroundColor: '#6387C5' }}
                  disabled={
                    !durationValidation ||
                    !notificationOnceValidation ||
                    !notificationWeeklyValidation ||
                    !notificationDailyValidation ||
                    !frequencyWeeklyValidation ||
                    !frequencyOnceValidation ||
                    hhData.focus == '' ||
                    hhData.focusNote == ''
                  }
                  onClick={submitHandler}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          ) : (
            userType === AUTH_TYPE.COACH && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    size="large"
                    // sx={{ color: '#6387C5' }}
                    onClick={submitHandler}
                  >
                    {item.loading ? (
                      <CircularProgress fontSize="small" color="primary" />
                    ) : (
                      'EDIT HEART HABIT'
                    )}
                  </Button>
                </Grid>
              </Grid>
            )
          )}
        </CardActions>
      </Root>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              sx={{ mt: 1, fontFamily: 'Work Sans', fontSize: 24, fontWeight: 600 }}
              component="h2"
            >
              Confirm
            </Typography>
            <Typography
              sx={{ mt: 1, fontFamily: 'Work Sans', fontSize: 16, color: 'rgba(0, 0, 0, 0.6)' }}
            >
              This will delete the Heart Habit.
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '25px',
              }}
            >
              <Button variant="outlined" size="large" onClick={handleClose}>
                CANCEL
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{ backgroundColor: '#CC3D3F' }}
                onClick={() => deleteCard(deleteData.id, deleteData.description)}
              >
                DELETE HEART HABIT
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default HeartHabitCard;
