import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

function SkeletonLoader(props) {
  const { isloading, children } = props;
  return (
    <>
      {isloading ? (
        <Skeleton animation="wave" height={10} width="40%" style={{ marginBottom: 6 }} />
      ) : (
        children
      )}
    </>
  );
}

export default SkeletonLoader;
