import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  recording: [],
  preview: [],
  patient: [],
  error: false,
  loading: false,
  showToast: { show: false, cancel: false, submit: false },
  loadingPatientData: false,
  count: 0,
  errorMessage: null,
  playbackSpeed: 1,
  pageSize:10,
  page:0
};
const getAudioStart = (state, action) => {
  return updateObject(state, {
    loading: action.payload,
  });
};

const getAudioSuccess = (state, action) => {
  return updateObject(state, {
    preview: action.payload,
  });
};

const getAudioRecordingSuccess = (state, action) => {
  return updateObject(state, {
    recording: action.payload,
  });
};

const getPatientStart = (state, action) => {
  return updateObject(state, {
    loadingPatientData: action.payload,
  });
};

const getPatientSuccess = (state, action) => {
  return updateObject(state, {
    patient: action.payload,
  });
};

const getPatientDataEmpty = (state, action) => {
  return updateObject(state, {
    patient: action.payload,
  });
};

const getPatientFail = (state, action) => {
  return updateObject(state, {
    error: action.payload,
  });
};

const getAudioFail = (state, action) => {
  return updateObject(state, null);
};

const getPatientCount = (state, action) => {
  return updateObject(state, {
    count: action.payload,
  });
};

const showToast = (state, action) => {
  return updateObject(state, {
    showToast: action.payload,
  });
};

const showErrorMessage = (state, action) => {
  return updateObject(state, {
    errorMessage: action.payload,
  });
};

const showPageSize = (state, action) => {
  return updateObject(state, {
    pageSize: action.payload,
  });
};

const showPage = (state, action) => {
  return updateObject(state, {
    page: action.payload,
  });
};

const getPatientInfoChange = (state, action) => {
  return {
    ...state,
    patient: {
      ...state?.patient,
      stethioReviewDetails: { ...state?.patient?.stethioReviewDetails, [action.key]: action.value },
    },
  };
};

const setPlayBackSpeed = (state, action) => {
  return updateObject(state, {
    playbackSpeed: parseFloat(action.payload),
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AUDIO_START:
      return getAudioStart(state, action);
    case actionTypes.GET_AUDIO_SUCCESS:
      return getAudioSuccess(state, action);
    case actionTypes.GET_AUDIO_FAIL:
      return getAudioFail(state, action);
    case actionTypes.GET_REVIEW_DATA:
      return getAudioRecordingSuccess(state, action);

    case actionTypes.IS_FETCHING_PATIENT_RESULTS:
      return getPatientStart(state, action);
    case actionTypes.GETS_PATIENTDATA_SUCCESS:
      return getPatientSuccess(state, action);
      case actionTypes.GETS_PATIENTDATA_EMPTY:
        return getPatientDataEmpty(state, action);
    case actionTypes.ERROR_PATIENT_RESULTS:
      return getPatientFail(state, action);
    case actionTypes.CHANGE_PATIENT_INFO:
      return getPatientInfoChange(state, action);
    case actionTypes.GET_AUDIO_COUNT:
      return getPatientCount(state, action);
    case actionTypes.SHOW_TOAST:
      return showToast(state, action);
    case actionTypes.SHOW_ERROR_MESSAGE:
      return showErrorMessage(state, action);
    case actionTypes.UPDATE_PLAYBACK_SPEED:
      return setPlayBackSpeed(state, action);
      case actionTypes.CHANGE_PAGE_SIZE:
        return showPageSize(state, action);
        case actionTypes.CHANGE_PAGE:
          return showPage(state, action);

    default:
      return state;
  }
};

export default reducer;
