import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { useStyles } from "./SelectBoxStyles";
import CheckIcon from "@material-ui/icons/Check";

/* Usage : 
  value            : variable which will hold the selected option
  onSelect         : function that will update the state
  menuOptionsData  : options data/array
  keyName          : key in menuOptions which contains option name
  placeholder      : prefilled message
  scrollableMenu   : boolean (true/false)
  bigScrollableMenu: boolean (true/false)
  errorState       : boolean (true/false)
  underLinedBottom : boolean (true/false)
  selectedItemList : (optional) 
*/

function SelectBox(props) {
  const {
    value,
    onSelect,
    menuOptionsData,
    keyName,
    placeholder,
    selectAllOption,
    scrollableMenu,
    bigScrollableMenu,
    errorState,
    underLinedBottom,
    selectedItemList,
    isDisabled,
  } = props;
  const classes = useStyles();

  const onChangeHandler = (e) => {
    onSelect(e.target.value);
  };

  return (
    <Select
      displayEmpty
      renderValue={value !== "" ? undefined : () => placeholder}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        classes: {
          paper: `${
            scrollableMenu
              ? classes.scrollableMenu
              : bigScrollableMenu
              ? classes.bigScrollableMenu
              : classes.selectMenu
          }`,
        },
      }}
      classes={{
        root: classes.selectRoot,
      }}
      className={`${classes.inputBox} ${
        errorState ? classes.errorInputBox : ""
      }`}
      value={value}
      onChange={onChangeHandler}
      disabled={!menuOptionsData.length || isDisabled ? true : false}
      disableUnderline
    >
      {selectAllOption ? (
        <MenuItem
          className={`${classes.menuOptions} ${
            underLinedBottom ? classes.underLinedMenuOptions : ""
          }`}
          value="selectAll"
        >
          Select All
        </MenuItem>
      ) : (
        ""
      )}
      {menuOptionsData.length
        ? menuOptionsData.map((data, idx) => (
            <MenuItem
              classes={{
                root: classes.menuItemsRoot,
              }}
              key={idx}
              className={`${classes.menuOptions} ${
                underLinedBottom ? classes.underLinedMenuOptions : ""
              }`}
              value={data.id}
            >
              {data[keyName]}
              {selectedItemList ? (
                selectedItemList.has(data[keyName]) ? (
                  <CheckIcon className={classes.selectIcon} />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </MenuItem>
          ))
        : ""}
    </Select>
  );
}

export default SelectBox;
