import { Box, Button, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash.get';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { DOCTOR_ROUTES } from 'constants/app';
import Authorize from '../Authorize';

const useClass = makeStyles({
  root: {
    fontFamily: 'Work Sans',
    flex: 1,
    padding: '20px 16px',
    minHeight: '280px',
    '& .MuiButton-root': {
      width: '100px',
      height: '40px',
      padding: '10px 20px',
      fontWeight: 700,
      border: '1px solid #2D9F86',
      borderRadius: '4px',
      color: '#2D9F86',
    },
    '& .MuiTypography-h3': {
      fontFamily: 'Work Sans',
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'none',
    },
    '& .MuiTypography-h5': {
      fontFamily: 'Work Sans',
      fontSize: '20px',
      fontWeight: 500,
    },
  },
  title: {
    fontFamily: 'Work Sans',
    fontSize: '20px',
    fontWeight: 500,
  },
  section_header: {
    fontSize: '16px',
    fontWeight: '300',
  },
  time: {
    fontFamily: 'Work Sans',
    color: '#2D9F86',
    fontSize: '20px !important',
    fontWeight: '700 !important',
  },
  availability: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 10px',
  },
  slots: {
    margin: '20px 10px',
  },
});

function AvailabilitySection(props) {
  const { availability } = props;
  const classes = useClass();
  let history = useHistory();
  const navigate = () => {
    history.push(DOCTOR_ROUTES.TELEKARDIA_CONSULTATION);
  };
  return (
    <Box>
      {availability && availability.availableSlots && availability.availableSlots.length > 0 ? (
        <Card className={classes.root}>
          <Typography variant="h5">My Availability Today</Typography>
          <hr style={{ margin: '5px -16px' }} />
          <div className={classes.availability}>
            <div>
              <Typography variant="h5" className={classes.section_header}>
                Available Time
              </Typography>
              <Typography className={classes.time}>
                {(Number(_get(availability, 'totalAvailableHours')) < 10
                  ? _get(availability, 'totalAvailableHours')
                  : _get(availability, 'totalAvailableHours')) +
                  '.' +
                  (Number(_get(availability, 'totalAvailableMins')) < 10
                    ? _get(availability, 'totalAvailableMins')
                    : _get(availability, 'totalAvailableMins')) || '-'}{' '}
                Hours
              </Typography>
            </div>
            <Authorize>
              <Button onClick={navigate}>EDIT</Button>
            </Authorize>
          </div>
          <div className={classes.slots}>
            <Typography variant="h5" className={classes.section_header}>
              Available slots for today:
            </Typography>
            {availability.availableSlots.map((item, index) => {
              return (
                <div className={classes.availability} key={index}>
                  <div style={{ display: 'flex' }}>
                    <EventAvailableIcon />

                    <Typography style={{ marginLeft: '5px', fontFamily: 'Work Sans' }}>
                      {`${item.slotTiming.split('-')[0]} - ${item.slotTiming.split('-')[1]}` || '-'}
                    </Typography>
                  </div>
                  <Typography variant="body1">
                    <strong>
                      {(Number(_get(item, 'availableHours')) < 10
                        ? _get(item, 'availableHours')
                        : _get(item, 'availableHours')) +
                        '.' +
                        (Number(_get(item, 'availableMinutes')) < 10
                          ? _get(item, 'availableMinutes')
                          : _get(item, 'availableMinutes')) || '-'}{' '}
                      Hours
                    </strong>
                  </Typography>
                </div>
              );
            })}
          </div>
        </Card>
      ) : (
        <Card className={classes.root}>
          <Typography variant="body1">No Availability is set for the day</Typography>
          <Authorize>
            <Button onClick={navigate}>EDIT</Button>
          </Authorize>
        </Card>
      )}
    </Box>
  );
}

export default AvailabilitySection;
