const buttonText = {
  REDIAL: 'REDIAL',
  HANGUP: 'HANG UP',
  CALL_MEMBER: 'YES, CALL MEMBER',
  CANCEL_CALL: 'CANCEL',
  COMPLETE_SESSION_NOTES: 'COMPLETE SESSION NOTES',
};

const callProgressConfirmationText = {
  CONFIRM_CALLING: 'Are you sure you want to call',
  CALLING: 'Calling',
};

const callType = {
  ADHOC: 'AdHoc',
  APPOINTMENT: 'Appointment',
};

export { buttonText, callProgressConfirmationText, callType };
