import React from 'react'
import classes from './TimelineGraph.module.css'
import YGrid from '../YGrid'

class TimelineGraph extends React.PureComponent {
  render() {
    const {
      chart: {
        height, paddingLeft, paddingBottom, paddingTop,
      },
      chartWidth,
      svgWidth,
      yTicks,
      returnY,
      renderRecordings,
    } = this.props

    return (
      <React.Fragment>
        <svg
          className={classes.timeline}
          height={height + paddingTop + paddingBottom}
          width={svgWidth}
        >
          <YGrid
            yTicks={yTicks}
            returnY={returnY}
            chartWidth={chartWidth}
            paddingLeft={paddingLeft}
            paddingTop={paddingTop}
          />
          <g className={classes.recordings} transform={`translate(${paddingLeft}, ${paddingTop})`}>
            {renderRecordings()}
          </g>
        </svg>
      </React.Fragment>
    )
  }
}

export default TimelineGraph
