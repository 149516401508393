export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};
const authMap = {
  doc: 'DOCTOR',
  coa: 'COACH',
  ops: 'OPERATIONS',
};
const sessionMap = {
  doc: 'consultation',
  coa: 'session',
};
export const getUserType = (type) => {
  let userType = null;
  let sessionText = null;
  if (type) {
    let auth = type.split('_')[1];

    userType = authMap[auth];
    sessionText = sessionMap[auth];
  } else {
    userType = 'DOCTOR'; //admin doctor does not have a chat id, so this is a way to differentiate the user types
    sessionText = 'consultation';
  }
  return [userType, sessionText];
};
