import * as actions from './actionTypes';

export const startAdhocCall = (data, id = '', appId = '', isNote = true, memberType = '') => ({
  type: actions.START_PHONE_CALL,
  payload: data,
  isNote: isNote,
  appointmentId: appId,
  member: id,
  memberType: memberType,
});
export const closeAdhocCall = () => ({
  type: actions.CLOSE_PHONE_CALL,
});

export const changeCallStatus = (data) => ({
  type: actions.CHANGE_CALL_STATUS,
  payload: data,
});
export const updateNotesId = (data) => ({
  type: actions.NOTES_UPDATE,
  payload: data,
});
