import { Typography } from '@mui/material';
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useSelector } from 'react-redux';

const SettingsHeader = (props) => {
  const { headerText, isSideBar } = useSelector((state) => state.settings);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {!isSideBar && (
        <ArrowBackIosNewIcon
          style={{ marginRight: 10, color: '#6387C5', fontSize: 25, cursor: 'pointer' }}
          onClick={() => props.goBack()}
        />
      )}
      <Typography
        style={{
          fontSize: '20px',
          fontFamily: 'Work Sans',
          color: 'rgb()0,0,0,60',
          fontWeight: 500,
        }}
      >
        <b>{headerText}</b>
        {/* todo- add gender here in the header */}
        <span
          style={{
            width: '35px',
            height: '6px',
            borderRadius: '8px',
            backgroundColor: '#6387c5',
            display: 'block',
            margin: '10px 0',
          }}
        />
      </Typography>
    </div>
  );
};

export default SettingsHeader;
