import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'style/styleSettings';
import AddAddendums from './AddAddendums';
import AddEditNotes from './AddEditNotes';
import StatusHeader from './StatusHeader';
import moment from 'moment';
import ViewNotesSkeleton from './ViewNotes.skeleton';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { ADHOC_NOTES } from 'constants/app';

const useStyle = makeStyles({
  root: {
    fontFamily: 'Work Sans !important',
    '& .MuiTypography-body1': {
      fontFamily: 'Work Sans !important',
    },
    '& .MuiTypography-h5': {
      fontFamily: 'Work Sans !important',
    },
  },
  draftChild: {
    border: '2px solid #E5E5E5',
    padding: '10px 20px',
    marginTop: '15px',
    // cursor: 'pointer',
  },
});
function ViewNotes({ data, memberId, snackBarDataHandler, refetch, onClose, type, ...other }) {
  const classes = useStyle();
  const [selectedAddendum, setSelectedAddendum] = useState(null);
  const programData = useSelector((state) => state.memberProfile.programData);
  const profile = useSelector((state) => state.user.profile);
  useEffect(() => {
    if (data && data.addendumDetails && data.addendumDetails.length > 0) {
      let draft = null;
      data.addendumDetails.map((d) => {
        if (d.status == 'Draft') draft = d;
      });
      if (draft) setSelectedAddendum((d) => draft);
    }
    return () => setSelectedAddendum(null);
  }, [data]);

  return (
    /**
     * CASES FOR OPENING NOTE
     * 1. Coach admin can create note and coach for the member can edit
     * 2. Coach can create note and coach admin can edit
     * 3. Coach can create and edit (Originator)
     */
    <Box className={classes.root}>
      {data ? (
        data.signedByDetail == null && data.signedAt == null ? (
          profile.id === data.createdByDetail.memberId ||
          profile?.permissions?.isAdmin ||
          programData?.coachId == profile.id ? (
            <AddEditNotes
              memberId={memberId}
              snackBarDataHandler={snackBarDataHandler}
              onClose={onClose}
              type={type === null ? ADHOC_NOTES : type}
              row={data}
              {...other}
            />
          ) : (
            <StatusHeader row={data}>
              <Box className={classes.draftChild}>
                <Typography variant="body1" style={{ lineHeight: '40px' }}>
                  Original:{' '}
                  {data.createdDate ? moment(data.createdDate).format('YYYY-DD-MM hh:mm A') : '-'}
                </Typography>
                {data.extras?.score && (
                  <Typography variant="body1">Score: {data.extras?.score}</Typography>
                )}
                <Typography variant="body1">{data.description}</Typography>
              </Box>
            </StatusHeader>
          )
        ) : (
          <>
            <StatusHeader row={data} refetch={refetch}>
              <Grid container className={classes.draftChild}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Original:{' '}
                    {data.createdDate ? moment(data.createdDate).format('YYYY-DD-MM hh:mm A') : '-'}
                  </Typography>
                </Grid>
                {data.extras?.score && (
                  <Grid item xs={12}>
                    <Typography variant="body1">Score: {data.extras?.score}</Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="body1">{data.description}</Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ maxHeight: '120px', marginTop: '20px', overflow: 'auto' }}
              >
                {data.attachmentDetails.length > 0 &&
                  data.attachmentDetails.map((item, i) => {
                    return (
                      <Grid container key={i} style={{ alignItems: 'center', padding: '5px 20px' }}>
                        <Grid item xs={1}>
                          <NoteAddIcon
                            style={{
                              color: '#1976D2',
                              width: '20px',
                              height: '20px',
                              textAlign: 'center',
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          onClick={() => window.open(item.preSignedUrl, '_blank')}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <Typography>{item.filename}</Typography>
                          <div
                            style={{
                              display: 'flex',
                            }}
                          >
                            <Typography>
                              {item.fileLength ? `${Math.round(item.fileLength / 1024)} KB` : '-'}
                            </Typography>
                            <Typography
                              style={{
                                marginLeft: '10px',
                              }}
                            >
                              {'•'}
                            </Typography>
                            <Typography
                              style={{
                                marginLeft: '10px',
                              }}
                            >
                              {'Completed'}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid container className={classes.draftChild}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    Signed:{' '}
                    {data.signedAt ? moment(data.signedAt).format('YYYY-DD-MM hh:mm A') : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    by: {data.signedByDetail.firstName} {data.signedByDetail.lastName}
                  </Typography>
                </Grid>
              </Grid>
            </StatusHeader>
            <div>
              {data.addendumDetails &&
                data.addendumDetails.length > 0 &&
                data.addendumDetails.map((item, i) => {
                  if (item.status != 'Draft') {
                    return (
                      <>
                        <Paper
                          key={i}
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'white',
                            borderRadius: 5,
                            boxShadow: '10px, 5px #c1c1c1',
                            padding: '20px',
                            textAlign: 'left',
                          }}
                        >
                          <Grid
                            container
                            className={classes.draftChild}
                            style={{ cursor: 'pointer' }}
                            key={item.id}
                          >
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                Name: {item.createdByDetail.firstName}{' '}
                                {item.createdByDetail.lastName}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                Status:{' '}
                                <b
                                  style={{
                                    color: item.status && colors.SPRUCE,
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {item.status}
                                </b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                Addendum:{' '}
                                {item.createdDate
                                  ? moment(item.createdDate).format('YYYY-DD-MM hh:mm A')
                                  : '-'}
                              </Typography>
                              <Typography variant="body1">{item.description}</Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ maxHeight: '120px', marginTop: '20px', overflow: 'auto' }}
                          >
                            {item.attachmentDetails.length > 0 &&
                              item.attachmentDetails.map((item, i) => {
                                return (
                                  <Grid
                                    container
                                    key={i}
                                    style={{ alignItems: 'center', padding: '5px 20px' }}
                                  >
                                    <Grid item xs={1}>
                                      <NoteAddIcon
                                        style={{
                                          color: '#1976D2',
                                          width: '20px',
                                          height: '20px',
                                          textAlign: 'center',
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={8}
                                      onClick={() => window.open(item.preSignedUrl, '_blank')}
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <Typography>{item.filename}</Typography>
                                      <div
                                        style={{
                                          display: 'flex',
                                        }}
                                      >
                                        <Typography>
                                          {item.fileLength
                                            ? `${Math.round(item.fileLength / 1024)} KB`
                                            : '-'}
                                        </Typography>
                                        <Typography
                                          style={{
                                            marginLeft: '10px',
                                          }}
                                        >
                                          {'•'}
                                        </Typography>
                                        <Typography
                                          style={{
                                            marginLeft: '10px',
                                          }}
                                        >
                                          {'Completed'}
                                        </Typography>
                                      </div>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                          </Grid>
                          {item.signedAt && (
                            <Grid container className={classes.draftChild}>
                              <Grid item xs={6}>
                                <Typography variant="body1">
                                  Signed:{' '}
                                  {item.signedAt
                                    ? moment(item.signedAt).format('YYYY-DD-MM hh:mm A')
                                    : '-'}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body1">
                                  By: {item.signedByDetail.firstName} {item.signedByDetail.lastName}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Paper>
                      </>
                    );
                  }
                })}
            </div>

            <AddAddendums
              noteId={data.id}
              row={selectedAddendum}
              snackBarDataHandler={snackBarDataHandler}
              refetch={refetch}
              onClose={onClose}
            />
          </>
        )
      ) : (
        <>
          <ViewNotesSkeleton />
        </>
      )}
    </Box>
  );
}

export default ViewNotes;
