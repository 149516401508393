import * as React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
      width:"100%"
    },
  }));

export default function MuiButton(props) {
  const { text, icon, type = 'button', variant = 'contained', loading, noMargin,disabled, ...rest } = props;
  const classes = useStyles();

  return (
    <Button className={classes.button} variant= {props.variant} disabled = {props.disabled} onClick={props.onClick}>{props.text}</Button>
  );
}
