import React, { useCallback, useEffect, useState } from 'react';
import {
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Popover,
  makeStyles,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';

import MuiTypography from 'components/Shared/Typography/MuiTypography';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
  root: {
    display: '-ms-flexbox',
    background: '#fff',
    marginTop: '5px',
    '& .MuiTypography-root': {
      fontFamily: 'Work Sans',
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Work Sans',
    },
  },
  chipfont: {
    fontFamily: 'Work Sans !important',
    color: '#000000 !important',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
    fontWeight: 400,
    marginRight: '5px',
    '& .MuiChip-label': {
      fontFamily: 'Work Sans !important',
      color: '#000000 !important',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '-0.2px',
      fontWeight: 400,
    },
  },
  popper: { display: 'flex', justifyContent: 'space-evenly', alignContent: 'center' },
  popovergrid2: { padding: '5px', height: 'auto', background: 'white', width: 'auto' },
  formControlchip2: {
    background: '#FFFFFF',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '5px',
    // minHeight: 280,
    width: 'auto',
    height: 'auto',
    '& .MuiTypography-body1': {
      fontFamily: 'Work Sans !important',
    },
  },
  formControllabelstyle2: {
    marginBottom: '15px',
    marginRight: '5px',
    fontFamily: 'Work Sans !important',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'black',
    '& .MuiTypography-body1': {
      fontFamily: 'Work Sans !important',
    },
  },
  buttonstyle: { marginBottom: '10px', color: '#F25F5F' },
});
const GreenCheckbox = withStyles({
  root: {
    color: '#DADADA',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Filter({
  filterVariableMap,
  filterMetaData,
  filterSeedData,
  filterAndRefetch,
  filtersOnChange,
  saveFilters,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorE2, setAnchorE2] = useState(null);
  const [chipArray, setchipArray] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const { filters } = useSelector((state) => state.memberProfile.sessionLogs);
  //preparing data
  useEffect(() => {
    if (filterMetaData) {
      // console.log(filterMetaData);
      if (filterMetaData.error == null) {
        setMetaData(filterMetaData);
      }
    }
  }, [filterMetaData]);

  // loading from redux / initiasing data
  useEffect(() => {
    if (filters) {
      setchipArray(filters);
      // props.fetchAdminSessionsHandler();
    } else {
      if (metaData) {
        let data = filterSeedData(metaData);
        setchipArray(data);
        dispatch(saveFilters(data));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, metaData]);

  /**
   * Anchors for functions for managing buttons
   */
  const popoveropen2 = Boolean(anchorE2);
  const popoverid2 = popoveropen2 ? 'simple-popover' : undefined;

  const handlepopover2openClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handlepopovercloseclick = () => {
    setAnchorE2(null);
  };

  const handlepopoverresetclick2 = useCallback(() => {
    if (metaData) {
      setchipArray(filterSeedData(metaData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chipArray]);

  const dispatch = useDispatch();
  const [changeFilters, setChangeFilters] = useState(false);

  useEffect(() => {
    if (changeFilters) {
      onsubmitfilter();
      setChangeFilters((m) => !m);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeFilters]);

  /**
   * Handler to submit filter
   */
  const onsubmitfilter = () => {
    filterAndRefetch(chipArray); //redux push
    handlepopovercloseclick();
    return;
  };

  /**
   * Handler to remove tag
   * @param {*} value
   */
  const removetag = (value) => {
    let temp = { ...chipArray };
    temp[value].checked = false;
    if (value.includes('all')) {
      let typeOfFilter = temp[value].type; //blood
      Object.values(temp).map((f) => {
        if (f.type === typeOfFilter) {
          f.checked = false;
        }
        return f;
      });
    }
    setchipArray(temp);
  };
  /**
   * On change function for coach
   * @param {*} item
   * @param {*} e
   */

  /**
   * On change function for all other checkboxes
   * @param {*} value
   * @param {*} event
   */
  const handleChange = (value, event) => {
    // console.log(value);
    let temp = { ...chipArray };
    //case - a normal element is removed from filter
    temp[value.id] = {
      ...value,
      checked: event.target.checked,
    };
    let typeOfFilter = value.type;
    let temp2 = Object.values(temp).filter((f) => f.type === typeOfFilter);
    let flag = true;
    //case - if all is unchecked
    if (temp[`all-${typeOfFilter}`] && !event.target.checked) {
      flag = false;
    }
    // //case - if all is checked
    // if (temp[`all-${typeOfFilter}`] && event.target.checked) {
    //   flag = true;
    // }
    //case - uncheck 'all'
    temp2.map((t) => {
      if (!t.checked && !t.id.includes('all-')) {
        flag = false;
      }
      return t;
    });
    if (flag) {
      temp[`all-${typeOfFilter}`] = {
        //this part is a todo
        ...temp[`all-${typeOfFilter}`],
        checked: true,
      };
      // temp.map((t) => {
      //   if (!t.id.includes(`all-${typeOfFilter}`)) {
      //     if (t.type == typeOfFilter) t.checked = true;
      //   }
      //   return t;
      // });
    }
    if (!flag) {
      temp[`all-${typeOfFilter}`] = {
        //this part is a todo
        ...temp[`all-${typeOfFilter}`],
        checked: false,
      };
    }

    //call custom functions overloaded from the parent element
    let finalFilterValues = filtersOnChange(temp,value);

    setchipArray(finalFilterValues);
  };

  /**
   * Show chip array that has 'all' attribute
   * @returns
   */
  const showAllIfAny = () => {
    let temp = { ...chipArray };

    return Object.entries(filterVariableMap).map((d, i) => {
      let key = `all-${d[0]}`;
      if (temp[key].checked) {
        return (
          <Chip
            variant="outlined"
            className={classes.chipfont}
            key={i}
            label={temp[key].id.includes('all') && `${d[1]} - All`}
            onDelete={() => removetag(key)}
          />
        );
      }
    });
  };

  /**
   * Show chip array with all other attributes
   * @param {*} value
   * @returns
   */
  const showChip = (value) => {
    let typeOfFilter = value[1]?.type;
    let temp = { ...chipArray };
    let allTrue = false;
    let allTrueKey = `all-${typeOfFilter}`;
    if (typeOfFilter) {
      allTrue = temp[allTrueKey].checked;
    }
    if (value[1].checked === true && !allTrue) {
      return (
        <>
          <Chip
            variant="outlined"
            className={classes.chipfont}
            key={value[0]}
            label={
              value[0].includes(typeOfFilter) &&
              `${filterVariableMap[typeOfFilter]} - ${value[1].name}`
            }
            onDelete={() => removetag(value[0])}
          />
          &nbsp;
        </>
      );
    }
  };
  return (
    <>
      <Grid
        alignItems="flex-start"
        spacing={0}
        container
        justify="space-between"
        className={classes.root}
      >
        <Grid item>
          <Grid
            alignItems="center"
            spacing={2}
            container
            justify="flex-start"
            style={{ margin: '5px' }}
          >
            <Grid item>
              <MuiTypography
                fontFamily="Work Sans"
                fontSize="16px"
                fonStyle="normal"
                fontWeight={600}
                lineHeight="20px"
                color="#142A39"
              >
                Filter
              </MuiTypography>
            </Grid>
            <Grid item>
              <IconButton onClick={handlepopover2openClick} aria-label="Search">
                <FilterListIcon />
              </IconButton>
            </Grid>
            <Grid item>
              {chipArray &&
                Object.entries(chipArray).map((value) => {
                  return showChip(value);
                })}
              {chipArray && showAllIfAny()}
            </Grid>
          </Grid>
          <Popover
            id={popoverid2}
            open={popoveropen2}
            anchorEl={anchorE2}
            className={classes.popper}
            modifiers={{
              preventOverflow: {
                enabled: false,
                boundariesElement: 'window',
              },
              arrow: {
                enabled: true,
                //element: arrowRef,
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Grid
              item
              container
              justify="flex-end"
              alignItems="flex-end"
              direction="row"
              spacing={2}
            >
              <Grid item>
                <IconButton aria-label="close" onClick={handlepopovercloseclick}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              direction="row"
              className={classes.popovergrid2}
            >
              {chipArray &&
                Object.entries(filterVariableMap).map((d, i) => {
                  return (
                    <Grid item key={i}>
                      <InputLabel className={classes.formControllabelstyle2}>{d[1]}</InputLabel>
                      <FormControl variant="outlined" className={classes.formControlchip2}>
                        {Object.values(chipArray).map((item, j) => {
                          if (item.type == d[0]) {
                            return (
                              <FormGroup column key={j}>
                                <FormControlLabel
                                  control={
                                    <GreenCheckbox
                                      onChange={(e) => handleChange(item, e)}
                                      name={item.name}
                                      checked={chipArray[item.id].checked}
                                      disabled={chipArray[item.id].disabled}
                                    />
                                  }
                                  label={item.name}
                                  disabled={chipArray[item.id].disabled}
                                />
                              </FormGroup>
                            );
                          }
                        })}
                      </FormControl>
                    </Grid>
                  );
                })}

              <Grid
                item
                container
                justify="flex-end"
                alignItems="flex-end"
                direction="row"
                spacing={2}
              >
                <Grid item>
                  <Button className={classes.buttonstyle} onClick={handlepopoverresetclick2}>
                    <MuiTypography
                      fontSize="14px"
                      lineHeight="16.42px"
                      fontFamily="Work Sans"
                      letterSpacing="0.75px"
                      fontWeight={700}
                      color="#2D9F86"
                    >
                      {t('CLEAR ALL')}
                    </MuiTypography>
                  </Button>
                </Grid>
                <Grid item>
                  <div className="telekardia">
                    <button
                      type="button"
                      className="btn m-1 btn_teal py-2 px-4"
                      onClick={onsubmitfilter}
                    >
                      {t('APPLY')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Popover>
        </Grid>
      </Grid>
    </>
  );
}

export default Filter;
