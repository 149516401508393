import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid, Box, Paper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import AvailabilitySection from 'components/doctor/AvailabilitySection';
import MaterialTabs from 'components/Dashboard/tabs';

import * as actions from 'store/actions';
import EcgLoader from 'components/Shared/EcgLoader';
import * as zone from 'utilities/Utilities';

function DoctorDashboardKPIs() {
  const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
  const dispatch = useDispatch();

  const [value, setvalue] = React.useState(0);
  // const [appointments, setappointments] = React.useState([]);
  const [statistics, setstatistics] = React.useState([]);
  const [availability, setavailability] = React.useState([]);

  const clinicianAppointments = useSelector((state) => state.appointments.clinicianAppointments);
  const clinicianAppointmentsData = useSelector(
    (state) => state.appointments.clinicianAppointmentsData,
  );
  const clinicianAvailability = useSelector((state) => state.appointments.clinicianAvailabilities);
  const isLoading = useSelector((state) => state.appointments.loading);
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);

  useEffect(() => {
    let queryParams = {
      startDateTime: moment(moment().startOf('day')._d).format(pattern),
      endDateTime: moment(moment().endOf('day')._d).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: zone.getTimezoneName(),
    };
    let appointmentqueryParams = {
      startDateTime: moment(moment().startOf('day')._d).format(pattern),
      endDateTime: moment(moment().endOf('day')._d).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: zone.getTimezoneName(),
      appointmentStatus: '1,2,3,6,7,9',
    };
    dispatch(actions.getAppointments(teamId, profileId, appointmentqueryParams));
    handleChange(null, 0);
    dispatch(actions.getClinicianAvailability(profileId, teamId, queryParams));
  }, []);
  useEffect(() => {
    clinicianAppointments.sort((a, b) => a.start - b.start);
    // setappointments(clinicianAppointments);
    setavailability(clinicianAvailability);
    setstatistics(clinicianAppointmentsData);
  }, [clinicianAvailability, clinicianAppointmentsData, clinicianAppointments]);

  const handleChange = (event, newValue) => {
    setvalue(newValue);
    let range = '';
    let flag = {};
    switch (newValue) {
      case 0:
        range = 'day';
        flag = true;
        break;
      case 1:
        range = 'week';
        flag = true;
        break;
      case 2:
        range = 'month';
        flag = true;
        break;
    }

    let queryParams = {
      startDate: moment(moment().startOf(range)._d).format(pattern),
      endDate: moment(moment().endOf(range)._d).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: zone.getTimezoneName(),
    };
    dispatch(actions.getCliniciansAppointments(teamId, profileId, queryParams));
  };
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Paper>
            <MaterialTabs value={value} handleChange={handleChange} stats={statistics} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            {isLoading ? <EcgLoader /> : <AvailabilitySection availability={availability} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DoctorDashboardKPIs;
