import * as Screens from 'screens/common';
import { ROUTES } from 'constants/app';
import TeamMemberInvite from 'components/TeamMemberInvite';

export const OpenRoutes = [
  {
    exact: true,
    path: ROUTES.LOGIN,
    component: Screens.Login,
  },
  {
    path: `${ROUTES.TEAM_MEMBER_INVITE}/:inviteCode`,
    component: TeamMemberInvite,
  },
  {
    path: `${ROUTES.REQUEST_NEW_PASSWORD}`,
    component: Screens.RequestNewPassword,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:token`,
    component: Screens.ResetPassword,
  },
  {
    path: `${ROUTES.RESET_PASSWORD_EMAIL_LINK}/:token`,
    component: Screens.ResetPasswordEmailLink,
  },
];
