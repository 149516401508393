export const HEART_HABIT_FIELDS = {
  FOCUS: 'focus',
  FOCUS_NOTE: 'focusNote',
  DURATION_START_DATE: 'durationStartDate',
  DURATION_END_DATE: 'durationEndDate',
  FREQUENCY_TYPE: 'frequencyType',
  FREQUENCY_DATE: 'frequencyDate',
  NOTIFICATION_TYPE: 'notificationType',
  NOTIFICATION_DATE: 'notificationDate',
  NOTIFICATION_TIME: 'notificationTime',
  RECURRENCE_TYPE: {
    DAILY: '0',
    WEEKLY: '1',
    ONCE: '2',
    NONE: '3',
  },
};
export const DAYS = [
  { day: 'SU', color: 'success' },
  { day: 'MO', color: 'success' },
  { day: 'TU', color: 'success' },
  { day: 'WE', color: 'success' },
  { day: 'TH', color: 'success' },
  { day: 'FR', color: 'success' },
  { day: 'SA', color: 'success' },
];
export const DAYS_MAP = {
  SU: '0',
  MO: '1',
  TU: '2',
  WE: '3',
  TH: '4',
  FR: '5',
  SA: '6',
};
