import { Grid, Typography } from '@material-ui/core';
import InputFieldComponent from 'components/Dashboard/SessionComponents/InputFieldComponent';
import React, { useEffect, useState } from 'react';
import { postGoalBaseLineBP } from '../PatientFile/module';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

const BpBaseGoalLine = (props) => {
  const dispatch = useDispatch();

  // component state
  const [systolic, setsystolic] = useState('');
  const [diastolic, setdiastolic] = useState('');
  const [edit, setedit] = useState(false);

  // redux state
  const baselineBP = useSelector((state) => state.patientFile.baseLineBP);
  const goallineBP = useSelector((state) => state.patientFile.goalLineBP);

  // update component state once redux state gets updated
  useEffect(() => {
    if (props.title === 'Baseline') {
      setsystolic(baselineBP?.systolic);
      setdiastolic(baselineBP?.diastolic);
    } else {
      setsystolic(goallineBP?.systolic);
      setdiastolic(goallineBP?.diastolic);
    }
  }, [baselineBP, goallineBP]);

  // update component state once corresponding input field updates
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'systolic') {
      setsystolic(value);
    } else setdiastolic(value);
  };

  // once form save button hits below functions gets triggered
  const editBP = () => {
    setedit(!edit);
    if (edit) {
      const bpValues = {
        systolic: systolic,
        diastolic: diastolic,
        type: props.title === 'Baseline' ? 1 : 2,
      };
      dispatch(postGoalBaseLineBP(bpValues));
    }
  };

  return (
    <Grid item xs={6} style={{ padding: '10px 40px' }}>
      <Typography
        variant="body1"
        style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '16px' }}
      >
        {props.title} Blood Pressure
      </Typography>
      {edit ? (
        <>
          <Typography>Systolic</Typography>
          <InputFieldComponent
            style={{ margin: '10px 0 20px' }}
            name="systolic"
            value={systolic}
            onChange={handleChange}
            type="number"
            fullWidth={true}
          />
          <Typography>Diastolic</Typography>
          <InputFieldComponent
            style={{ margin: '10px 0 20px' }}
            name="diastolic"
            value={diastolic}
            onChange={handleChange}
            type="number"
            fullWidth={true}
          />
        </>
      ) : (
        <>
          <Typography>Systolic: {systolic}</Typography>
          <Typography>Diastolic: {diastolic}</Typography>
        </>
      )}
      <Grid item xs={12}>
        <Button
          fullWidth
          style={{
            margin: '20px 0',
            border: edit ? 'none' : '1px solid black',
            backgroundColor: edit ? 'black' : 'white',
            color: edit ? 'white' : 'black',
            fontWeight: 700,
          }}
          onClick={editBP}
        >
          {edit ? 'Save' : 'Edit readings'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default BpBaseGoalLine;
