import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React from 'react';

function TextInputs({ label, iconPosition = null, variant = 'outlined', icon = null, ...props }) {
  return (
    <FormControl fullWidth variant={variant}>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <OutlinedInput
        {...props}
        id={label}
        endAdornment={
          <InputAdornment position={iconPosition}>
            <IconButton
              aria-label={label}
              // onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              edge={iconPosition}
            >
              {icon}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
}

export default TextInputs;
