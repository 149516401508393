import * as OpScreens from 'screens/operations';
import React from 'react';
import { OPS_ROUTES } from 'constants/app';
import Logout from 'components/Logout/Logout';

export const Admin = [
  {
    exact: true,
    authenticatedRoute: true,
    path: OPS_ROUTES.OPS_SHIPPING,
    component: OpScreens.ShippingScreen,
  },
  {
    path: `/logout`,
    component: () => <Logout />,
  },
];
