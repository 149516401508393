import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useStyles } from './ToastStyles';
import CheckIcon from '@mui/icons-material/Check';
import { darkBluishGrey, lightGreen } from '../../style/colors';
import { fontFamily } from '../../style/commonStyles';

//Usage :
// Expects 3 props =>
// open: whether to show Toast message or not (true/false)
// closeHandler: function which will set state passed to open to false
// alertMessage: Toast Message

/* 
Note : to prevent closing of Snackbar on clickaway event, please add below condition in your closeHandler Function =>
const closeHandler = (event, reason) => {
  if(reason === 'clickaway){
    return;
  }
  //below your closing code 
}
*/

function Toast(props) {
  const classes = useStyles();
  const { open, closeHandler, alertMessage, severity } = props;

  return (
    <Snackbar
      open={open}
      className={classes.snackBarStyle}
      autoHideDuration={8000}
      onClose={closeHandler}
      sx={{ width: '100%', position: 'relative', top: '12px', left: '0 !important' }}
    >
      <Alert
        onClose={closeHandler}
        severity={severity !== '' ? severity : ''}
        iconMapping={{
          success: (
            <CheckIcon
              className={classes.checkIconCustom}
              style={{ color: 'white' }}
              fontSize="inherit"
            />
          ),
        }}
        className={`${severity === 'success' ? classes.toastSuccessMessage : classes.toastMessage}`}
        sx={{
          width: '100%',
          fontFamily,
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '20px',
          backgroundColor: severity === 'error' ? '#d94d4d' : '#067F6F',
          color: 'white',
          letterSpacing: '-0.2px',
        }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
