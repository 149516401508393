import { makeStyles } from "@material-ui/core/styles";
import { slateGrey, pantoneRed } from "../../style/colors";
import { fontFamily } from "../../style/commonStyles";

export const useStyles = makeStyles({
  headingContainer: {
    marginTop: "4rem",
    marginBottom: "1rem",
  },
  heading: {
    fontFamily,
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.25px",
  },
  subHeading: {
    fontFamily,
    fontSize: "14px",
    lineHeight: "16px",
    color: slateGrey,
  },
  errorSubHeading: {
    color: pantoneRed,
  },
  gridItem: {
    marginLeft: "4rem",
  },
});
