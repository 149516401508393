import moment from 'moment'

import { SORT_TYPES, WEIGHT_TABLE_COLUMN_HEADERS, MIN_BMI_VALUE, MAX_BMI_VALUE, MIN_WEIGHT_VALUE, MAX_WEIGHT_VALUE, KARDIA_PRO_START_DATE, KARDIA_PRO_END_DATE } from 'constants/app'
import {
  WEIGHT_SOURCES,
  getWeightFormat,
  StandardCompare,
} from '../../Utilities'

export const sortWeightRecordingIds = (
  sorts = {
    sortBy: WEIGHT_TABLE_COLUMN_HEADERS.RECORDED_AT,
    sortDirection: SORT_TYPES.DESC,
  },
  recIds = [],
  recByIds = {}
) => {
  const {
    sortBy,
    sortDirection,
  } = sorts
  const recordingIds = [ ...recIds ]
  let sortedRecordingIds = recordingIds

  if (recordingIds) {
    switch (sortBy) {
    case WEIGHT_TABLE_COLUMN_HEADERS.RECORDED_AT:
      sortedRecordingIds = recordingIds.sort((a, b) => StandardCompare.dates(recByIds[a][sortBy], recByIds[b][sortBy], sortDirection))
      break
    case WEIGHT_TABLE_COLUMN_HEADERS.SOURCE:
    case WEIGHT_TABLE_COLUMN_HEADERS.CATEGORY:
      sortedRecordingIds = recordingIds.sort((a, b) => StandardCompare.strings(recByIds[a][sortBy], recByIds[b][sortBy], sortDirection))
      break
    case WEIGHT_TABLE_COLUMN_HEADERS.BMI:
    case WEIGHT_TABLE_COLUMN_HEADERS.WEIGHT:
      if (sortDirection === SORT_TYPES.DESC) {
        sortedRecordingIds = recordingIds.sort((a, b) => recByIds[b][sortBy] - recByIds[a][sortBy])
      } else if (sortDirection === SORT_TYPES.ASC) {
        sortedRecordingIds = recordingIds.sort((a, b) => recByIds[a][sortBy] - recByIds[b][sortBy])
      }
      break
    default:
      // No sort, keep previous sort
      break
    }
  }

  return sortedRecordingIds
}

export const filterWeightRecordingIds = (
  filters = {
    selectedSource: undefined,
    selectedSeverity: undefined,
    selectedStartDate: undefined,
    selectedEndDate: undefined,
    selectedBmiStartValue: undefined,
    selectedBmiEndValue: undefined,
    selectedWeightStartValue: undefined,
    selectedWeightEndValue: undefined,
  },
  recordingIds,
  recordingByIds,
  me,
) => {
  const {
    selectedSource,
    selectedSeverity,
    selectedStartDate,
    selectedEndDate,
    selectedBmiStartValue,
    selectedBmiEndValue,
    selectedWeightStartValue,
    selectedWeightEndValue,
  } = filters
  let filteredRecordings = recordingIds

  if (recordingIds) {
    if (selectedStartDate !== KARDIA_PRO_START_DATE
      || selectedEndDate !== KARDIA_PRO_END_DATE
    ) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]
          if (moment(r.recordedAt).isBefore(moment(selectedStartDate).startOf('day'))
            || moment(r.recordedAt).isAfter(moment(selectedEndDate).endOf('day'))) {
            return false
          }

          return true
        })
    }

    if (selectedSource.length > 0) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return selectedSource.some(source => {
            switch (source.id) {
            case WEIGHT_SOURCES.MANUAL:
              return StandardCompare.tableStringFilter(r.source, source.id)
                // Android's manual
                || StandardCompare.tableStringFilter(r.source, WEIGHT_SOURCES.KARDIA_MOBILE)
            case WEIGHT_SOURCES.HEALTH_KIT:
              return r.isHealthkit
            default:
              return false
            }
          })
        })
    }

    if (selectedSeverity.length > 0) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return selectedSeverity.some(severity => severity.id === r.severity)
        })
    }

    if (selectedBmiStartValue !== MIN_BMI_VALUE
      || selectedBmiEndValue !== MAX_BMI_VALUE
    ) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return StandardCompare.tableNumberRangeFilter({
            values: {
              min: selectedBmiStartValue,
              current: r.bmi,
              max: selectedBmiEndValue,
            },
            limits: {
              min: MIN_BMI_VALUE,
              max: MAX_BMI_VALUE,
            },
          })
        })
    }

    if (selectedWeightStartValue !== MIN_WEIGHT_VALUE
      || selectedWeightEndValue !== MAX_WEIGHT_VALUE
    ) {
      filteredRecordings = filteredRecordings
        .filter(recId => {
          const r = recordingByIds[recId]
          const weightInPounds = getWeightFormat(r.weight, me)

          return StandardCompare.tableNumberRangeFilter({
            values: {
              min: selectedWeightStartValue,
              current: weightInPounds,
              max: selectedWeightEndValue,
            },
            limits: {
              min: MIN_WEIGHT_VALUE,
              max: MAX_WEIGHT_VALUE,
            },
          })
        })
    }
  }

  return filteredRecordings
}
