import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import SignOutIcon from 'assets/images/logos/logout.svg';

import NavLink from './NavLink';
import styles from '../NavigationSidebar.module.css';
import { getAppVersion, getBuildDateTime, getBuildVersion } from 'configs';

const NavigationBuilder = ({ main, footer, isLoading, sidebarIsCollapsed }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {isLoading ? (
        <ul className={styles.navList}>
          {'loader'.split('').map((m, i) => {
            return (
              <React.Fragment key={i}>
                <li data-action="page-navigation">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      padding: 10,
                    }}
                  >
                    <Skeleton animation="wave" variant="circle" width={30} height={30} />
                    <Skeleton animation="wave" variant="rect" width={'70%'} height={40} />
                  </div>
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      ) : (
        <ul className={styles.navList}>
          {main.map((m, i) => {
            if (m.condition) {
              return (
                <React.Fragment key={i}>
                  <li data-action="page-navigation" data-label={m.title}>
                    <NavLink
                      className={styles.sidebarItem}
                      activeClassName={styles.sidebarItemActive}
                      to={m.navigateTo} //prop
                      Icon={m.icon} //prop
                      href={m.href}
                      text={m.title} //prop
                      style={{ ...m.style }} //prop
                      status={m.status} //prop
                      unreadCount={m.unreadCount} //prop
                      target={m.target} //prop
                      alertCount={m.alertCount}
                      ekgCount={m.ekgCount}
                      audioCount={m.audioCount}
                    />
                  </li>
                  {/* <div className={styles.spacing}></div> */}
                </React.Fragment>
              );
            }
          })}
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '20px' }}></div>
        </ul>
      )}
      <div className={styles.sidebarFooter}>
        {isLoading ? (
          <div>
            {[1, 1].map((f, i) => (
              <div
                key={i}
                className={styles.sidebarFooterItem}
                data-action="page-navigation"
                data-label="Coach Resources"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: 10,
                  }}
                >
                  <Skeleton animation="wave" variant="circle" width={30} height={30} />
                  <Skeleton animation="wave" variant="rect" width={'70%'} height={40} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {footer.map((f, i) => (
              <div
                key={i}
                className={styles.sidebarFooterItem}
                data-action="page-navigation"
                data-label="Coach Resources"
              >
                <NavLink
                  className={styles.settings}
                  activeClassName={styles.sidebarItemActive}
                  to={f.navigateTo}
                  Icon={f.icon}
                  text={f.title}
                />
              </div>
            ))}
          </div>
        )}

        <div style={{ border: '1px solid rgba(0, 0, 0, 0.12)', margin: '20px' }}></div>

        <div
          className={styles.sidebarFooterItem}
          data-action="page-navigation"
          data-label="Signout"
        >
          <NavLink
            className={styles.settings}
            activeClassName={styles.sidebarItemActive}
            to={'/logout'}
            Icon={() => (
              <div className={styles.navIcon}>
                <SignOutIcon style={{ fill: '#1976D2' }} />
              </div>
            )}
            text={'Log out'}
          />
        </div>
        {!sidebarIsCollapsed && (
          <div className={styles.buildInfo}>
            Build No: {getBuildVersion()}
            <br />
            Ver: {getAppVersion()}
            <br />
            D: {getBuildDateTime()}
          </div>
        )}
        {/* <div className={styles.navIcon} style={{ position: 'absolute', bottom: 0 }}></div> */}
      </div>
    </React.Fragment>
  );
};

export default NavigationBuilder;
