//prepare the data\
export let constants = {
  atrialFibrillationHistory: 'Atrial Fibrillation History',
  medications: 'Medications',
  careDelivery: 'Care Delivery',
  lifeStyleDetails: 'Lifestyle Details',
  medicalHistory: 'Medical History',
  symptoms: 'Symptoms',
  episodeManagement: 'Episode Management',
  overallManagement: 'Overall Management',
  triggers: 'Triggers',
  episodes: 'Episodes', //not there
  duration: 'Duration', //not there
  heartMeds: 'Heart Meds',
  medicationList: 'Medication list',
  adherence: 'Adherence',
  newMeds: 'New Meds',
  hospitalAdmissions: 'Hospital Admissions',
  providers: 'Providers',
  emergencyRoomVisits: 'Emergency room visits',
  surgicalHistory: 'Surgical history',
  heartConditions: 'Heart conditions',
  familyHistory: 'Family History',
  otherMedicalConditions: 'Other Medical conditions',
  pregnant: 'Pregnant',
  sleep: 'Sleep',
  nutrition: 'Nutrition',
  wellbeing: 'Overall Wellbeing',
  physicalLimitations: 'Physical limitations',
  exercise: 'Exercise',
  behavior: 'Behaviour',
  title: null,
};
