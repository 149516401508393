import queryString from 'query-string';
import { ValidatePassword } from 'utilities/Utilities';
// import history from '../History';
import { authRegisterFromInviteScreen } from 'store/actions/user';
import { postAcceptTeamMember } from 'store/reducers/app';
import { CLEAR_STATE, setRegion } from 'constants/app';
import { VALID_AUTH_REGIONS } from 'screens/common/Login/authConstants';
// import { getToken } from 'auth/AuthUtilities';
// import { GoAPI } from '../../Axios/axios';
// ------------------------------------
// Constants
// ------------------------------------
const PREFIX = 'TeamMemberInvite.';
export const UPDATE_EMAIL = `${PREFIX}UPDATE_EMAIL`;
export const UPDATE_INVITE_CODE = `${PREFIX}UPDATE_INVITE_CODE`;
export const DATA_REQUEST = `${PREFIX}DATA_REQUEST`;
export const DATA_SUCCESS = `${PREFIX}DATA_SUCCESS`;
export const DATA_FAILURE = `${PREFIX}DATA_FAILURE`;
export const UPDATE_INPUT = `${PREFIX}UPDATE_INPUT`;
export const UPDATE_VALIDATION = `${PREFIX}UPDATE_VALIDATION`;
export const DISPLAY_VALIDATION_ERROR = `${PREFIX}DISPLAY_VALIDATION_ERROR`;

export const emptyStringError = 'All fields are required';
export const noValidateError = 'Your password must meet all the criteria, please try again';

// ------------------------------------
// Actions
// ------------------------------------
export const updateEmail = (email) => ({
  type: UPDATE_EMAIL,
  payload: email,
});
export const updateInviteCode = (inviteCode) => ({
  type: UPDATE_INVITE_CODE,
  payload: inviteCode,
});
export const dataRequest = () => ({
  type: DATA_REQUEST,
});
export const dataSuccess = () => ({
  type: DATA_SUCCESS,
});
export const dataFailure = () => ({
  type: DATA_FAILURE,
});
export function displayValidationError(msg) {
  return {
    type: DISPLAY_VALIDATION_ERROR,
    payload: msg,
  };
}

export function updateValidation(validationObj) {
  return {
    type: UPDATE_VALIDATION,
    payload: validationObj,
  };
}
export function updateInput(field) {
  return {
    type: UPDATE_INPUT,
    payload: field,
  };
}
export const setInitialTeamMemberInviteData = (match, location) => (dispatch, getState) => {
  const { inviteCode } = match.params;

  const queryParams = queryString.parse(location.search);
  const { email, region } = queryParams;

  if (region && Object.values(VALID_AUTH_REGIONS).includes(region)) {
    setRegion(region);
  }
  dispatch(updateEmail(email));
  dispatch(updateInviteCode(inviteCode));
};
export const initialUpdate=(field) => (dispatch, getState) => {
  
    dispatch(updateInput(field));
  
}
export const handleChange = (evt) => (dispatch, getState) => {
  const field = {
    [evt.target.name]: evt.target.value,
  };

  if (field.password || field.password === '') {
    const result = ValidatePassword(field.password);
    dispatch(updateValidation(result));
  }
  dispatch(updateInput(field));
};

export const handleKeyDown = (evt) => (dispatch, getState) => {
  if (evt.key === 'Enter') {
    return dispatch(submitForm());
  }
};

export const submitForm = () => (dispatch, getState) => {
  const {
    form: { firstName, lastName, password,userType },
    email,
    inviteCode,
    validationTests,
  } = getState().teamMemberInvite;

  let passedTests = false;
  const testResults = Object.values(validationTests);
  for (let i = 0; i < testResults.length; i += 1) {
    if (!testResults[i]) {
      passedTests = false;
      break;
    } else {
      passedTests = true;
    }
  }

  const required = [firstName, lastName, password];
  const emptyFields = required.filter((r) => r === '' || !r);

  const hasEmptyFields = emptyFields && emptyFields.length > 0;

  if (hasEmptyFields) {
    return dispatch(displayValidationError(emptyStringError));
  }

  if (!passedTests) {
    return dispatch(displayValidationError(noValidateError));
  }

  if (!hasEmptyFields && passedTests) {
    const reqObj = {
      email,
      firstName,
      inviteCode,
      lastName,
      password,
      userType,
      canManageAvailability: true,
    };

    dispatch(submitTeamMemberInvite(reqObj));
  }
};

export const submitTeamMemberInvite = (reqObj) => (dispatch, getState) => {
  dispatch(dataRequest());

  return dispatch(postAcceptTeamMember(reqObj))
    .then(() => dispatch(dataSuccess()))
    .then(() => {
      // Login user
      // const credentials = {
      //   email: reqObj.email,
      //   password: reqObj.password,
      // };

      return dispatch(authRegisterFromInviteScreen(reqObj));
    })
    .catch((err) => dispatch(dataFailure()));
};

const initialState = {
  email: undefined,
  inviteCode: undefined,
  form: {},
  validationTests: {},
  error: false,
  errorMsg: '',
  dataIsFetching: false,
  dataLastUpdated: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UPDATE_EMAIL]: (state, action) => ({
    ...state,
    email: action.payload,
  }),
  [UPDATE_INVITE_CODE]: (state, action) => ({
    ...state,
    inviteCode: action.payload,
  }),
  [DATA_REQUEST]: (state, action) => ({
    ...state,
    dataIsFetching: true,
  }),
  [DATA_SUCCESS]: (state, action) => ({
    ...state,
    dataIsFetching: false,
    dataLastUpdated: new Date(),
  }),
  [DATA_FAILURE]: (state, action) => ({
    ...state,
    dataIsFetching: false,
    dataLastUpdated: new Date(),
  }),
  [UPDATE_INPUT]: (state, action) => ({
    ...state,
    form: Object.assign({}, state.form, action.payload),
  }),
  [DISPLAY_VALIDATION_ERROR]: (state, action) => ({
    ...state,
    error: true,
    errorMsg: action.payload,
  }),
  [UPDATE_VALIDATION]: (state, action) => ({
    ...state,
    validationTests: action.payload,
  }),
  [CLEAR_STATE]: (state, action) => ({ ...initialState }),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function teamMemberInviteReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
