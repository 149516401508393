import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES } from 'constants/app';
import withStartConsultation from '../Dashboard/withStartConsultation';

const ToastButton = ({
  patientDetails,
  patientDetailsLoader,
  id,
  alertHandler,
  buttonProps,
  reviewSession,
  memberInWaitingRoom,
  alertCloseFlag,
  hideToast,
}) => {
  const [clicked, setClicked] = useState(false);
  const userType = useSelector((state) => state?.user.chatProfile.userType);
  useEffect(() => {
    if (clicked && patientDetails && patientDetails.appointmentId && id && !patientDetailsLoader) {
      alertHandler();
      setClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked, patientDetails, patientDetailsLoader, id]);

  useEffect(() => {
    if (alertCloseFlag) {
      hideToast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertCloseFlag]);

  const history = useHistory();
  const toastButtonClick = () => {
    if (memberInWaitingRoom) {
      setClicked(true);
    } else if (reviewSession) {
      hideToast();
      history.push(
        AUTH_TYPE.COACH === userType
          ? `${COACH_ROUTES.MEMBER_PROFILE_BASE}/${patientDetails?.patient?.patientId}/${patientDetails?.appointmentId}`
          : `${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${patientDetails?.patient?.patientId}/${patientDetails?.appointmentId}`,
      );
    }
  };
  return (
    <Button
      onClick={toastButtonClick}
      style={{ marginLeft: '20px', color: '#fff', fontWeight: 'bold' }}
      {...buttonProps}
    >
      {memberInWaitingRoom ? 'JOIN CALL' : reviewSession ? 'REVIEW PROFILE' : 'REJOIN'}
    </Button>
  );
};

export default withStartConsultation(ToastButton);
