import React from 'react'
// import moment from 'moment'
import classes from './ScrollingContainer.module.css'

class ScrollingContainer extends React.Component {
  UNSAFE_componentWillMount() {
    if (this.props.recordings.length > 0 && this.props.selectedRecording === null) {
      this.props.setInitialSelectedRecording(this.props.recordings[0])
    }
  }

  componentDidMount() {
    const {
      selectedRecording,
      dateField,
      xScale,
      windowWidth,
    } = this.props

    const selectedRecordingScrollLeft = selectedRecording ? xScale()(new Date(selectedRecording[dateField])) - (windowWidth / 2) : null

    // has recordings and previous selection before graph was mounted
    if (selectedRecording && this.scrollingContainer && this.scrollingContainer.scrollLeft !== selectedRecordingScrollLeft) {
      let nextScrollLeft = this.scrollingContainer.scrollLeft
      if (Math.abs(this.scrollingContainer.scrollLeft - selectedRecordingScrollLeft) > (windowWidth / 2)) {
        nextScrollLeft = selectedRecordingScrollLeft + 76
      }
      this.scrollingContainer.scrollLeft = nextScrollLeft
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.recordings.length > 0 && this.scrollingContainer) {
      const {
        recordings,
        selectedRecording,
        dateField,
        xScale,
        windowWidth,
        setInitialSelectedRecording,
      } = nextProps
      const firstRecording = recordings[0]

      const firstRecordingScrollLeft = xScale()(new Date(firstRecording[dateField])) - (windowWidth / 2)
      const selectedRecordingScrollLeft = selectedRecording ? xScale()(new Date(selectedRecording[dateField])) - (windowWidth / 2) : null

      // After receiving recordings but graph already mounted and no previous selection
      if (this.props.recordings.length !== recordings.length && !selectedRecording && this.scrollingContainer.scrollLeft === 0) {
        this.scrollingContainer.scrollLeft = firstRecordingScrollLeft
        if (typeof setInitialSelectedRecording === 'function') {
          setInitialSelectedRecording(firstRecording)
        }
      // has recordings and previous selection before graph was mounted
      } else if (selectedRecording && this.scrollingContainer.scrollLeft !== selectedRecordingScrollLeft) {
        let nextScrollLeft = this.scrollingContainer.scrollLeft
        if (Math.abs(this.scrollingContainer.scrollLeft - selectedRecordingScrollLeft) > (windowWidth / 2)) {
          nextScrollLeft = selectedRecordingScrollLeft + 76
        }
        this.scrollingContainer.scrollLeft = nextScrollLeft
      }
    }
  }

  render() {
    return (
      <div
        className={classes.scrollingContainer}
        ref={ref => { this.scrollingContainer = ref }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default ScrollingContainer
