/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { InputAdornment, TextField, Grid } from '@mui/material';
import NoSsr from '@material-ui/core/NoSsr';
import styled from 'styled-components';

import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';

import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES } from 'constants/app';
import { AppointmentStatusTypes } from 'utilities/Constants';

const Listbox = styled('ul')`
  width: 50%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

export default function AutoCompleteDropdown(props) {
  const { getSearchedMember, memberSearchList } = props;

  const [searchMember, setsearchMember] = useState('');
  const upcomingSession = useSelector((state) => state.events.events);
  const userType = useSelector((state) => state?.user.chatProfile.userType);

  const history = useHistory();

  const memberData = useCallback(() => {
    const updatedData = memberSearchList?.reduce((acc, cur) => {
      const datas = { title: `${cur.lastName}, ${cur.firstName}`, id: cur.id };
      return [...acc, datas];
    }, []);
    return updatedData;
  }, [memberSearchList]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    //defaultValue: '',
    multiple: false,
    options: memberData() || [],
    getOptionLabel: (option) => option.title,
  });

  useEffect(() => {
    GetSearchedValue(value);
  }, [value]);

  // handlleChange will not let users to input Special character and space,
  // Api will be called after 4 character.- ASP-318
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^\w]/gi, '');
    setsearchMember(sanitizedValue);
    if (inputValue.length >= 4) {
      getMemberSearchMethod(inputValue.trim());
    }
  };
  //search api filters -- 1 sec after key press is over, api will be triggerd
  const timer1 = useRef(null);
  const getMemberSearchMethod = (text) => {
    clearTimeout(timer1.current);
    timer1.current = setTimeout(() => {
      getSearchedMember(text);
    }, 500);
  };

  const GetSearchedValue = (value) => {
    if (value) {
      const isSessionAvailable = upcomingSession
        .filter((appointment) => appointment.eventType === 'appointment')
        .filter((item) => {
          return (
            [
              AppointmentStatusTypes.Scheduled,
              AppointmentStatusTypes.InProgress,
              AppointmentStatusTypes.MemberInWaitingRoom,
              AppointmentStatusTypes.Encounter_Summary_Preparation,
            ].includes(item.status) && item.patientId === value.id
          );
        });
      if (isSessionAvailable.length > 0) {
        history.push(
          userType === AUTH_TYPE.COACH
            ? `${COACH_ROUTES.MEMBER_PROFILE_BASE}/${value?.id}/${isSessionAvailable[0].id}`
            : `${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${value?.id}/${isSessionAvailable[0].id}`,
        );
      } else {
        history.push(
          userType === AUTH_TYPE.COACH
            ? `${COACH_ROUTES.MEMBER_PROFILE_BASE}/${value?.id}`
            : `${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${value?.id}`,
        );
      }
    }

    return;
  };

  return (
    <NoSsr>
      <div>
        <div {...getRootProps()}>
          {/* <Label {...getInputLabelProps()}>Customized hook</Label> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...getInputProps()}
                fullWidth
                label="Member Search"
                placeholder="Member Name"
                onChange={handleChange}
                value={searchMember}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
                <input {...getInputProps()} placeholder="Member Name" />
                <span style={{ marginTop: '5px', marginLeft: '10px' }}>
                </span>
              </InputWrapper> */}
            </Grid>
          </Grid>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li key={index} {...getOptionProps({ option, index })}>
                <span>{option.title}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        ) : null}
      </div>
    </NoSsr>
  );
}
