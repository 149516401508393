import React from 'react';
import { Column } from 'react-virtualized';
import moment from 'moment';

import Table from '../components/Table';
import TableStyles from '../components/Table/Table.module.css';
import TableHeader from '../components/TableHeader';
import { DatePopover, SliderPopover, MultiSelectListPopover } from '../../Popovers';
import {
  getDateFormat,
  getTimeFormat,
  WEIGHT_TABLE_COLUMN_HEADERS,
  TABLE_CHECKBOX_WIDTH,
} from 'constants/app';
import {
  getBmiSeverityDisplayText,
  getWeightFormat,
  getWeightSourceDisplayTextFromRecording,
} from '../../Utilities';
import { DATE_WIDTH } from '../Utilities';
import styles from './WeightTable.module.css';

class WeightTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
    };
  }

  static getDerivedStateFromProps(props, s) {
    const state = s;

    if (state.selected !== props.selected) {
      state.selected = props.selected;
    }

    return state;
  }

  dateFormat = getDateFormat(this.props.me);
  timeFormat = getTimeFormat(this.props.me);

  handleOnSelectionClick = (selected) => {
    const { onSelectionClick } = this.props;

    this.setState({
      selected,
    });

    if (typeof onSelectionClick === 'function') {
      onSelectionClick(selected);
    }
  };

  render() {
    const {
      noDataText = 'No recordings found.',
      enableLoader,
      showLoader,
      showRowSelection = false,
      data,
      // width,
      onScroll,
      showPatientColumns,
      selectedIndex,
      sortBy,
      sortDirection,
      me,
      filterDate,
      filterSource,
      filterCategory,
      filterBmi,
      filterWeight,
      handleOnSortClick,
      handleOnFilterButtonClick,
      handleOnFilterChange,
      handleOnOutsidePopoverClick,
      // handleOnBulkAction,
      // handleOnBulkActionClearSelection,
      // bulkActionComplete,
      // bulkActionIsFetching,
      selectedPopover,

      selectedStartDate,
      selectedEndDate,
      dateRange,

      selectedSource,
      sourceList,

      selectedSeverity,
      severityList,

      selectedBmiStartValue,
      selectedBmiEndValue,
      bmiRange,

      selectedWeightStartValue,
      selectedWeightEndValue,
      weightRange,
    } = this.props;
    const { selected } = this.state;

    // const isAnySelected = Object.keys(selected).some(key => selected[key] === true)

    const ROW_HEIGHT = 50;
    const PATIENT_WIDTH = 190;
    const BMI_WIDTH = 190;
    const WEIGHT_WIDTH = 100;
    let remainingWidth = 1440 - DATE_WIDTH - BMI_WIDTH - WEIGHT_WIDTH;
    remainingWidth = showPatientColumns ? remainingWidth - PATIENT_WIDTH : remainingWidth;

    if (showRowSelection) {
      remainingWidth -= TABLE_CHECKBOX_WIDTH;
    }

    const sourceWidth = remainingWidth / 2;
    const categoryWidth = remainingWidth / 2;

    return (
      <div className={styles.weightTable}>
        <Table
          noDataText={noDataText}
          enableLoader={enableLoader}
          showLoader={showLoader}
          showRowSelection={showRowSelection}
          // disableRowSelection={bulkActionComplete || bulkActionIsFetching}
          selected={selected}
          rowHeight={ROW_HEIGHT}
          uniqueKey="id"
          // hasTableActions={isAnySelected}
          onSelectionClick={this.handleOnSelectionClick}
          // onRowClick={onRowClick}
          onScroll={onScroll}
          scrollToIndex={selectedIndex}
          data={data}
          sortBy={sortBy}
          sortDirection={sortDirection}
          colorField="severityClass"
        >
          <Column
            className={TableStyles.column}
            label="Date"
            dataKey={WEIGHT_TABLE_COLUMN_HEADERS.RECORDED_AT}
            width={DATE_WIDTH}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={WEIGHT_TABLE_COLUMN_HEADERS.RECORDED_AT}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterDate}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div
                      className={
                        showRowSelection ? styles.datePopoverWithSelection : styles.datePopover
                      }
                    >
                      <DatePopover
                        handleOnChange={(dates) =>
                          handleOnFilterChange(WEIGHT_TABLE_COLUMN_HEADERS.RECORDED_AT, dates)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                        min={dateRange[0]}
                        max={dateRange[1]}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const date = moment(rowData[dataKey]).format(this.dateFormat);
              const time = moment(rowData[dataKey]).format(this.timeFormat);
              return (
                <div className={TableStyles.centeredColumnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>{date}</p>
                  <p className={`${TableStyles.secondaryText} ${TableStyles.centeredText}`}>
                    {time}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label="Source"
            dataKey={WEIGHT_TABLE_COLUMN_HEADERS.SOURCE}
            width={sourceWidth}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={WEIGHT_TABLE_COLUMN_HEADERS.SOURCE}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterSource}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div
                      className={
                        showRowSelection ? styles.sourcePopoverWithSelection : styles.sourcePopover
                      }
                    >
                      <MultiSelectListPopover
                        list={sourceList}
                        selected={selectedSource}
                        getOptionLabel={(d) => d.label}
                        getOptionValue={(d) => d.id}
                        enableSearch={false}
                        handleOnClick={(id) =>
                          handleOnFilterChange(WEIGHT_TABLE_COLUMN_HEADERS.SOURCE, id)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = getWeightSourceDisplayTextFromRecording(rowData);

              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label="Category"
            dataKey={WEIGHT_TABLE_COLUMN_HEADERS.CATEGORY}
            width={categoryWidth}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={WEIGHT_TABLE_COLUMN_HEADERS.CATEGORY}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterCategory}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div
                      className={
                        showRowSelection
                          ? styles.categoryPopoverWithSelection
                          : styles.categoryPopover
                      }
                    >
                      <MultiSelectListPopover
                        list={severityList}
                        selected={selectedSeverity}
                        getOptionLabel={(d) => d.label}
                        getOptionValue={(d) => d.id}
                        enableSearch={false}
                        handleOnClick={(id) =>
                          handleOnFilterChange(WEIGHT_TABLE_COLUMN_HEADERS.CATEGORY, id)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = getBmiSeverityDisplayText(rowData.severity);
              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label="BMI"
            dataKey={WEIGHT_TABLE_COLUMN_HEADERS.BMI}
            width={BMI_WIDTH}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={WEIGHT_TABLE_COLUMN_HEADERS.BMI}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterBmi}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={styles.bmiPopover}>
                      <SliderPopover
                        label="Select Range"
                        min={bmiRange[0]}
                        max={bmiRange[1]}
                        minValue={selectedBmiStartValue}
                        maxValue={selectedBmiEndValue}
                        handleOnChange={(selectedBmis) =>
                          handleOnFilterChange(WEIGHT_TABLE_COLUMN_HEADERS.BMI, selectedBmis)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = rowData.bmi.toFixed(1);
              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
          <Column
            className={TableStyles.column}
            label={me.data.teams[0].useImperialSystem ? 'Weight (lb)' : 'Weight (kg)'}
            dataKey={WEIGHT_TABLE_COLUMN_HEADERS.WEIGHT}
            width={WEIGHT_WIDTH}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={WEIGHT_TABLE_COLUMN_HEADERS.WEIGHT}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterWeight}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={styles.weightPopover}>
                      <SliderPopover
                        label="Select Range"
                        min={weightRange[0]}
                        max={weightRange[1]}
                        minValue={selectedWeightStartValue}
                        maxValue={selectedWeightEndValue}
                        handleOnChange={(selectedWeights) =>
                          handleOnFilterChange(WEIGHT_TABLE_COLUMN_HEADERS.WEIGHT, selectedWeights)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => {
              const primaryText = getWeightFormat(rowData.weight, me).toFixed(1);
              return (
                <div className={TableStyles.columnContent}>
                  <p className={`${TableStyles.primaryText} ${TableStyles.centeredText}`}>
                    {primaryText}
                  </p>
                </div>
              );
            }}
          />
        </Table>
      </div>
    );
  }
}

export default WeightTable;
