import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Up from '@material-ui/icons/ArrowUpward';
import Down from '@material-ui/icons/ArrowDownward';
import ReportProblem from '@material-ui/icons/ReportProblem';
const BloodPressureComponent = ({ bp }) => {
  const [latestBp, setLatestBp] = useState('--');
  const [status, setStatus] = useState(null);
  const [isHigh, setIsHigh] = useState(false);
  useEffect(() => {
    const temp = [...bp];
    temp.sort((a, b) => (new Date(a.RecordedAt) < new Date(b.RecordedAt) ? 1 : -1));
    var totalSys = bp.reduce(function (sum, b) {
      return sum + parseInt(b.Systolic);
    }, 0);
    var totalDia = bp.reduce(function (sum, b) {
      return sum + parseInt(b.Diastolic);
    }, 0);
    let newSysAvg, newDiaAvg;
    newSysAvg = totalSys / bp.length;
    newDiaAvg = totalDia / bp.length;
    if (bp.length > 1) {
      let latestSys, latestDia, oldDiaAvg, oldSysAvg;
      latestSys = bp[0].Systolic;
      latestDia = bp[0].Diastolic;
      oldSysAvg = totalSys - latestSys / bp.length;
      oldDiaAvg = totalDia - latestDia / bp.length;
      if (oldDiaAvg > newDiaAvg || oldSysAvg > newSysAvg) {
        setStatus(0);
      } else {
        setStatus(1);
      }
      if (newSysAvg >= 180 || newDiaAvg > 120 || (newSysAvg >= 180 && newDiaAvg > 120)) {
        setIsHigh((v) => !v);
      }
    }

    setLatestBp([Math.round(newSysAvg * 10) / 10, Math.round(newDiaAvg * 10) / 10]);
  }, [bp]);

  const getFlag = (s, d) => {
    let borderColor = '';
    if (s < 90 && d < 60) {
      borderColor = '#9DD3DE';
    } else if (s <= 120 && d <= 80) {
      borderColor = '#6BC077';
    } else if (s >= 120 && s <= 129 && d < 80) {
      borderColor = '#FDC246';
    } else if ((s >= 130 && s <= 139) || (d >= 80 && d <= 89)) {
      borderColor = '#F78C4E';
    } else if (s >= 140 || d >= 90) {
      borderColor = '#F25F5F';
    } else if (s >= 180 || d > 120 || (s >= 180 && d > 120)) {
      borderColor = '#CC3D3F';
    }
    return borderColor;
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <span
            style={{
              height: '20px',
              border: `4px solid ${getFlag(latestBp[0], latestBp[1])}`,
            }}
          ></span>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant={'body1'}
            style={{ color: '#142A39', fontWeight: 600, fontFamily: 'Work Sans' }}
          >
            {latestBp[0]} / {latestBp[1]}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant={'body1'} style={{ color: '#142A39', fontWeight: 600 }}>
            {!isHigh && status === 0 ? (
              <Down style={{ stroke: '#142A39', strokeWidth: '2' }} />
            ) : !isHigh && status === 1 ? (
              <Up style={{ stroke: '#142A39', strokeWidth: '2' }} />
            ) : (
              isHigh && <ReportProblem style={{ color: '#CC3D3F' }} />
            )}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default BloodPressureComponent;
