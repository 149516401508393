import React from 'react';
import classes from './ValidationRules.module.css';
import FailIcon from 'assets/images/icons/close.svg';
import PassIcon from 'assets/images/icons/check-circle.svg';

export const ValidationRules = (props) => {
  const { validationTests, updatePassword } = props;

  const getClassName = () => (updatePassword ? 'color' : 'validStatus');

  const getIcon = (iconClass) => {
    if (iconClass === 'pass') {
      return (
        <span className={classes[iconClass]}>
          <PassIcon />
        </span>
      );
    } else {
      return (
        <span className={classes[iconClass]}>
          <FailIcon />
        </span>
      );
    }
  };

  const getValidationIcon = (tests) => {
    let iconClass;

    for (let i = 0; i < tests.length; i += 1) {
      const test = tests[i];
      if (validationTests[test]) {
        iconClass = 'pass';
      } else {
        iconClass = 'fail';
        break;
      }
    }

    return <span className={classes[getClassName()]}>{getIcon(iconClass)}</span>;
  };

  return (
    <div className={classes.validationRules}>
      Your password must include:
      <div className={classes.rulesContainer}>
        <div>
          <div className={classes.rule}>
            {getValidationIcon(['validLength', 'noWhitespace'])}
            8-20 characters (no spaces)
          </div>
          <div className={classes.rule}>
            {getValidationIcon(['hasUppercase'])}
            At least 1 uppercase letter
          </div>
        </div>
        <div>
          <div className={classes.rule}>
            {getValidationIcon(['hasLowercase'])}
            At least 1 lowercase letter
          </div>
          <div className={classes.rule}>
            {getValidationIcon(['hasNumber'])}
            At least 1 number
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationRules;
