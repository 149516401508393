import * as types from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  isSearchButtonClicked: false,
  isLoadingSearchResults: false,
  searchError: false,
  searchresults: [],
};

const isSearchClicked = (state, action) => {
  return updateObject(state, {
    isSearchButtonClicked: action.payload,
  });
};

const isFetchingSearchResults = (state, action) => {
  return updateObject(state, {
    isLoadingSearchResults: action.payload,
  });
};

const searchErrorStatus = (state, action) => {
  return updateObject(state, {
    searchError: action.payload,
  });
};

const getDatasearch = (state, action) => {
  return updateObject(state, {
    searchresults: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.IS_SEARCH_BUTTON_CLICKED:
      return isSearchClicked(state, action);
    case types.GETSEARCHDATA_SUCCESS:
      return getDatasearch(state, action);
    case types.IS_FETCHING_SEARCH_RESULTS:
      return isFetchingSearchResults(state, action);
    case types.ERROR_SEARCHING_RESULTS:
      return searchErrorStatus(state, action);
    default:
      return state;
  }
};

export default reducer;
