import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { colors } from 'style/styleSettings';
import RetryIcon from '@material-ui/icons/Replay';
import { ALERT_NOTE } from 'constants/app';
import moment from 'moment';

function StatusHeader({ row, refetch, children }) {
  const calculateTimeDiff = (end, start) => {
    let endTime = moment(end);
    let startTime = moment(start);
    let diff = endTime.diff(startTime);
    return moment.utc(diff).format('mm:ss');
  };
  return (
    <Box>
      <Paper
        style={{
          backgroundColor: 'white',
          borderRadius: 5,
          boxShadow: '10px, 5px #c1c1c1',
          padding: '20px',
          textAlign: 'left',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={10}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography variant="h5">Status</Typography>
            <div
              style={{
                marginLeft: '20px',
                border: row.status == 'Draft' ? `2px solid ${colors.SPRUCE}` : `2px solid black`,
                borderRadius: '100px',
                padding: '8px 12px 8px 12px',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  fontSize: 11,
                  letterSpacing: '1.5px',
                  lineHeight: '13px',
                }}
              >
                {row.status}
              </Typography>
            </div>
          </Grid>
          {refetch && (
            <Grid
              item
              xs={2}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <RetryIcon onClick={() => refetch()} />
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography variant="body1">
              {' '}
              {`Provider: ${row.physicianNote ? 'Doctor' : 'Coach'}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {`Name: ${row.createdByDetail.firstName} ${row.createdByDetail.lastName}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {row.type === ALERT_NOTE ? (
              <Typography variant="body1">Alert Type: {row?.subTypeName}</Typography>
            ) : row.type === 'AdHoc Call' ? (
              <Typography variant="body1">
                Call Duration: {calculateTimeDiff(row.adHocCallActualEnd, row.adHocCallActualStart)}
              </Typography>
            ) : (
              <Typography variant="body1">Type: {row?.type}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            {row.type === ALERT_NOTE || row.type === 'AdHoc Call' ? (
              <Typography variant="body1">
                Date: {moment(row?.createdDate).format('MM-DD-YYYY')}
              </Typography>
            ) : (
              <Typography variant="body1">Tag: {row?.tagName}</Typography>
            )}
          </Grid>
        </Grid>
        {children}
      </Paper>
    </Box>
  );
}

export default StatusHeader;
