import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import { getUserMemberStatuses } from 'store/actions/user';
import DashboardCard from './Card';
import { dashboardKpiCount } from 'store/actions/escalations';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    margin: '0 0 20px',
    font: 'Work Sans !important',
    '& .MuiTypography-h5': {
      fontSize: '20px',
      lineHeight: '23px',
      fontFamily: 'Work Sans',
      fontWeight: 500,
      // margin: '20px',
    },
  },
}));

const CardBottomContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [kpiData, setKpiData] = useState(null);
  const [loading, setLoading] = useState(false);
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);

  useEffect(() => {
    setLoading(true);
    dispatch(dashboardKpiCount(teamId, profileId)).then((res) => {
      setKpiData(res);
      setLoading(false);
    });
  }, []);

  const clinicalKpis = useRef(null);
  const engagementKpis = useRef(null);
  useEffect(() => {
    clinicalKpis.current = [
      {
        type: 'Clinical',
        title: 'Hypertensive Crisis',
        content: kpiData?.bpHyperTension,
        color: '#CC3D3F',
        bgColor: '#ECE5F1',
      },
      {
        type: 'Clinical',
        title: 'Hypotension',
        content: kpiData?.bpHypoTension,
        color: '#CC3D3F',
        bgColor: '#ECE5F1',
      },
      {
        type: 'Clinical',
        title: 'Abnormal EKG',
        content: kpiData?.ekgAbnormal,
        color: '#F78C4E',
        bgColor: '#FFF8E9',
      },
      {
        type: 'Clinical',
        title: 'HTN Trend',
        content: kpiData?.bpTrend,
        color: '#FDC246',
        bgColor: '#EBF5F3',
      },
    ];

    engagementKpis.current = [
      {
        type: 'Engagement',
        title: 'Unscheduled',
        content: kpiData?.engagementUnscheduled,
        color: '#FDC246',
        bgColor: '#EBF5F3',
      },
      {
        type: 'Engagement',
        title: 'Disengaged',
        content: kpiData?.engagementDisengaged,
        color: '#FDC246',
        bgColor: '#EBF5F3',
      },
      {
        type: 'Engagement',
        title: 'RPM',
        content: kpiData?.engagementRpm,
        color: '#FDC246',
        bgColor: '#EBF5F3',
      },
    ];
    // messagesAndNotes = [
    //   {
    //     type: 'Messages and Notes',
    //     title: 'New Messages',
    //     content: kpiData?.new,
    //     color: '#142A39;',
    //     bgColor: '#EBF5F3',
    //     icon: 'message',
    //   },
    //   {
    //     type: 'Engagement',
    //     title: 'Draft Notes ',
    //     content: kpiData?.new,
    //     color: '#142A39;',
    //     bgColor: '#EBF5F3',
    //     icon: 'note',
    //   },
    // ];
    return () => {
      engagementKpis.current = null;
      clinicalKpis.current = null;
    };
  }, [kpiData, loading]);

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5" className="" gutterBottom>
          Clinical Alerts
        </Typography>
        <hr style={{ margin: '0 -20px' }} />
        <Grid container spacing={4} style={{ marginTop: '5px' }}>
          {clinicalKpis.current &&
            clinicalKpis.current.map((item, i) => {
              return (
                <Grid item xs={3} key={i}>
                  <DashboardCard
                    title={item.title}
                    content={loading ? '-' : item.content}
                    color={item.color}
                    bgColor={item.bgColor}
                    icon="alert"
                  />
                </Grid>
              );
            })}
        </Grid>
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className="" gutterBottom>
          Engagement Alerts
        </Typography>
        <hr style={{ margin: '0 -20px' }} />
        <Grid container spacing={4} style={{ marginTop: '5px' }}>
          {engagementKpis.current &&
            engagementKpis.current.map((item, i) => {
              return (
                <Grid item xs={4} key={i}>
                  <DashboardCard
                    title={item.title}
                    content={loading ? '-' : item.content}
                    color={item.color}
                    bgColor={item.bgColor}
                    icon="alert"
                  />
                </Grid>
              );
            })}
        </Grid>
      </Paper>
      {/* <Paper className={classes.root} style={{ marginBottom: '-10px' }}>
        <Typography variant="h5" className="" gutterBottom>
          Messages and Notes
        </Typography>
        <hr style={{ margin: '0 -20px' }} />
        <Grid container spacing={4} style={{ marginTop: '5px' }}>
          {messagesAndNotes &&
            messagesAndNotes.map((item, i) => {
              return (
                <Grid item xs={6} key={i}>
                  <DashboardCard
                    title={item.title}
                    content={loading || error ? '-' : item.content}
                    color={item.color}
                    bgColor={item.bgColor}
                    icon={item.icon}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Paper> */}
    </>
  );
};

export default CardBottomContainer;
