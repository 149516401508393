import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  typographyprops: {
    fontFamily: (props) => props.fontFamily,
    fontStyle: (props) => props.fontStyle,
    fontWeight: (props) => props.fontWeight,
    fontSize: (props) => props.fontSize,
    lineHeight: (props) => props.lineHeight,
    color: (props) => props.color,
    textTransform: (props) => props.textTransform,
    margin: (props) => props.margin,
    textAlign: (props) => props.textAlign,
    alignItems: (props) => props.alignItems,
    padding: (props) => props.padding,
    position: (props) => props.position,
    top: (props) => props.top,
  },
});

const MuiTypography = (props) => {
  const { children } = props;
  const classes = useStyles(props);

  return <Typography variant = {props.variant} className={classes.typographyprops}>{children}</Typography>;
};

export default MuiTypography;
