import { connect } from 'react-redux';
import { getChatUserAction, getLastMessageChatUser } from 'store/actions';
import ChatMemberList from './MemberList';

const mapActionCreators = {
  getChatUserAction,
  getLastMessageChatUser,
};

const mapStateToProps = (state) => ({
  profileId: state.user?.profile?.id,
  chat: state.chat,
});

export default connect(mapStateToProps, mapActionCreators)(ChatMemberList);
