import EcgLoader from 'components/Shared/EcgLoader';
import { ChatContext } from 'providers/chat';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { user_logout } from 'store/actions';

const Logout = () => {
  const { closeConnection } = useContext(ChatContext);

  const dispatch = useDispatch();
  console.log('GRACEFULLY LOGGING OUT');
  useEffect(() => {
    closeConnection();
    dispatch(user_logout());
    return () => {};
  }, []);
  return <EcgLoader text="Logging you out" />;
};

export default Logout;
