import { getToken } from 'auth/AuthUtilities';
import * as actionTypes from './actionTypes';
import { COMPLETED_ERROR_CODE, INPROGRESS_ERROR_CODE, IN_PROGRESS } from 'constants/audio';
import { CrpAPI } from 'Axios/axios';

export const getAudioSessionStart = (type) => {
  return {
    type: actionTypes.GET_AUDIO_START,
    payload: type,
  };
};

export const getAudioSessionSuccess = (data) => {
  return { type: actionTypes.GET_AUDIO_SUCCESS, payload: data };
};

export const getAudioSessionFail = (error) => {
  return { type: actionTypes.GET_AUDIO_FAIL, error: error };
};

export const showToastMessage = (data) => {
  return { type: actionTypes.SHOW_TOAST, payload: data };
};

export const changePageSize = (data) => {
  return { type: actionTypes.CHANGE_PAGE_SIZE, payload: data };
};

export const changePage = (data) => {
  return { type: actionTypes.CHANGE_PAGE, payload: data };
};

export const getCount = (data) => {
  return { type: actionTypes.GET_AUDIO_COUNT, payload: data };
};

export const countAPI = (data) => (dispatch, getState) => {
  return CrpAPI.get(`/v1/stethio/count`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCount(res?.data?.data?.count));
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAudioData =
  (status, pageSize, page, searchValue, sort = 'desc') =>
  (dispatch, getState) => {
    dispatch(getAudioSessionStart(true));
    return CrpAPI.get(
      `v1/stethio/worklist?limit=${pageSize}&offset=${
        page === 0 ? page : pageSize * page + 1
      }&search=${searchValue}&timeRequest=${sort}&status=${status.toUpperCase()}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        dispatch(getAudioSessionSuccess(res.data));
        dispatch(getAudioSessionStart(false));
        dispatch(countAPI());
        return res;
      })
      .catch((error) => {
        dispatch(getAudioSessionStart(false));
        dispatch(getAudioSessionFail(error));
      });
  };

///////////////////////////////////////

export const setSearchButtonClicked = (boolean) => {
  return {
    type: actionTypes.IS_SEARCH_BUTTON_CLICKED,
    payload: boolean,
  };
};

export const isFetchingSearchResults = (status) => {
  return {
    type: actionTypes.IS_FETCHING_SEARCH_RESULTS,
    payload: status,
  };
};

export const errorRetrievingSearchList = (status) => {
  return {
    type: actionTypes.ERROR_SEARCHING_RESULTS,
    payload: status,
  };
};

export const isFetchingPatientResults = (status) => {
  return {
    type: actionTypes.IS_FETCHING_PATIENT_RESULTS,
    payload: status,
  };
};

export const errorMessageRetrieved = (message) => {
  return {
    type: actionTypes.SHOW_ERROR_MESSAGE,
    payload: message,
  };
};

export const emptyPatientData = () => {
  return {
    type: actionTypes.GETS_PATIENTDATA_EMPTY,
    payload: [],
  };
};

export const errorRetrievingPatientList = (status) => {
  return {
    type: actionTypes.ERROR_PATIENT_RESULTS,
    payload: status,
  };
};

export const getReviewData = (data) => {
  return {
    type: actionTypes.GET_REVIEW_DATA,
    payload: data,
  };
};

export const changePatientInfo = (key, value) => {
  return {
    type: actionTypes.CHANGE_PATIENT_INFO,
    key,
    value,
  };
};

export const getAudioSearchData =
  (search = '', status) =>
  (dispatch, getState) => {
    dispatch(isFetchingSearchResults(true));
    return CrpAPI.get(`v1/stethio/searchlist?search=${search}&status=${status.toUpperCase()}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GETSEARCHDATA_SUCCESS,
            payload: res.data.data,
          });
          dispatch(isFetchingSearchResults(false));
          dispatch(errorRetrievingSearchList(false));
        }
      })
      .catch((err) => {
        dispatch(isFetchingSearchResults(false));
        dispatch(errorRetrievingSearchList(true));
      });
  };

export const getAudioPatientData = (id, status) => async (dispatch, getState) => {
  dispatch(isFetchingPatientResults(true));
  try {
    let res = await CrpAPI.get(`v1/stethio/patientDetail/${id}?complete=${status}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (!res.data?.error) {
      dispatch({
        type: actionTypes.GETS_PATIENTDATA_SUCCESS,
        payload: res.data.data,
      });
      dispatch(isFetchingPatientResults(false));
      dispatch(errorRetrievingPatientList(false));
    } else if (
      res.data?.error?.code === INPROGRESS_ERROR_CODE ||
      res.data?.error?.code === COMPLETED_ERROR_CODE
    ) {
      dispatch(
        errorMessageRetrieved(
          res.data?.error?.code === INPROGRESS_ERROR_CODE
            ? 'Report Processed By Another User'
            : 'Report already being submitted',
        ),
      );
      dispatch(isFetchingPatientResults(false));
      dispatch(errorRetrievingPatientList(true));
    }
  } catch (err) {
    dispatch(isFetchingPatientResults(false));
    dispatch(errorRetrievingPatientList(true));
  }
};

export const updateStatus = (id) => (dispatch, getState) => {
  return CrpAPI.put(`v1/stethio/status/${id}/inProgress`, null, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(changePatientInfo('status', IN_PROGRESS));
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const cancelRequest = (id) => (dispatch, getState) => {
  return CrpAPI.put(`v1/stethio/status/${id}/cancel`, null, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(showToastMessage({ show: true, cancel: true, submit: false }));
        dispatch(errorRetrievingPatientList(false));
        return res;
      }
    })
    .catch((err) => {
      dispatch(showToastMessage({ show: true, cancel: true, submit: false }));
      dispatch(errorRetrievingPatientList(false));
      console.log(err);
    });
};

export const saveReview = (data) => (dispatch, getState) => {
  return CrpAPI.post(`v1/stethio/review`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(showToastMessage({ show: true, cancel: false, submit: true }));
        dispatch(errorRetrievingPatientList(false));
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(showToastMessage({ show: true, cancel: false, submit: true }));
      dispatch(errorRetrievingPatientList(true));
    });
};

export const setPlaybackSpeed = (value) => {
  return {
    type: actionTypes.UPDATE_PLAYBACK_SPEED,
    payload: value,
  };
};
