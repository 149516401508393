import React from 'react';
import { Column, AutoSizer, Table as ReactVirtualizedTable } from 'react-virtualized';

import UncheckedCheckboxIcon from 'assets/images/checkbox_empty.svg';
import CheckedCheckboxIcon from 'assets/images/checkboxChecked.svg';
import TableHeaderStyles from '../TableHeader/TableHeader.module.css';
import EcgLoader from '../../../Shared/EcgLoader';
import { TABLE_HEADER_HEIGHT, TABLE_CHECKBOX_WIDTH } from 'constants/app';
import styles from './Table.module.css';
import { getSelectedList } from 'utilities/Settings/TableUtilities';

/**
 * Default row renderer for Table.
 * https://github.com/bvaughn/react-virtualized/blob/master/source/Table/defaultRowRenderer.js
 */
function DefaultRowRenderer({
  className,
  columns,
  index,
  onRowClick,
  onRowDoubleClick,
  onRowMouseOut,
  onRowMouseOver,
  onRowRightClick,
  rowData,
  style,
}) {
  const a11yProps = { 'aria-rowindex': index + 1 };

  if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
    a11yProps['aria-label'] = 'row';
    a11yProps.tabIndex = 0;

    if (onRowClick) {
      a11yProps.onClick = (event) => onRowClick({ event, index, rowData });
    }
    if (onRowDoubleClick) {
      a11yProps.onDoubleClick = (event) => onRowDoubleClick({ event, index, rowData });
    }
    if (onRowMouseOut) {
      a11yProps.onMouseOut = (event) => onRowMouseOut({ event, index, rowData });
    }
    if (onRowMouseOver) {
      a11yProps.onMouseOver = (event) => onRowMouseOver({ event, index, rowData });
    }
    if (onRowRightClick) {
      a11yProps.onContextMenu = (event) => onRowRightClick({ event, index, rowData });
    }
  }

  return (
    <div
      {...a11yProps}
      className={className}
      role="row"
      style={style}
      data-action="select-row-item"
      data-value={index}
    >
      {columns}
    </div>
  );
}

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
      selected: {},
      hoveredRowIndex: null,
    };
  }

  // using css hover for now until we add actions to the hover
  // when ready uncomment onRowMouseOver, onRowMouseOut fxs and from component and remove .row:hover style
  // onRowMouseOver = eventWrapped => {
  //   this.setState({
  //     hoveredRowIndex: eventWrapped.index,
  //   })
  // }

  // onRowMouseOut = eventWrapped => {
  //   this.setState({
  //     hoveredRowIndex: null,
  //   })
  // }

  static getDerivedStateFromProps(props, s) {
    const state = s;

    if (state.selected !== props.selected) {
      state.selected = props.selected;
    }

    return state;
  }

  isAllRowsSelected = (selected, data) => {
    const { uniqueKey } = this.props;

    const selectedKeys = getSelectedList(selected);
    const selectedLen = selectedKeys.length;

    let selection = false;
    if (data.length > 0 && selectedLen === data.length) {
      const dataKeys = data.map((datum) => datum[uniqueKey]);
      selection = selectedKeys.every((sKey) => dataKeys.includes(sKey));
    }

    return selection;
  };

  handleOnClick = (e, key) => {
    e.stopPropagation();
    const { onSelectionClick, disableRowSelection } = this.props;
    const { selected } = this.state;

    if (disableRowSelection) {
      return;
    }

    const newSelection = selected && selected[key] ? !selected[key] : true;

    const newSelected = {
      ...selected,
      [key]: newSelection,
    };
    this.setState({
      selected: newSelected,
    });

    if (typeof onSelectionClick === 'function') {
      onSelectionClick(newSelected);
    }
  };

  handleOnAllClick = (dataKey) => {
    const { data, onSelectionClick, disableRowSelection } = this.props;
    const { selected } = this.state;

    if (disableRowSelection) {
      return;
    }

    const isAllRowsSelected = this.isAllRowsSelected(selected, data);

    const newSelected = {};
    if (isAllRowsSelected) {
      data.forEach((datum) => {
        const key = datum[dataKey];
        newSelected[key] = false;
      });
    } else {
      data.forEach((datum) => {
        const key = datum[dataKey];
        newSelected[key] = true;
      });
    }

    this.setState({
      selected: newSelected,
    });

    if (typeof onSelectionClick === 'function') {
      onSelectionClick(newSelected);
    }
  };

  rowRenderer = (props) => {
    const { rowData, style, className, key, ...rest } = props;
    const { colorField } = this.props;

    const cn = colorField
      ? `${className} ${styles.rowWithColors} ${styles[rowData[colorField]]}`
      : className;

    return (
      <DefaultRowRenderer {...rest} key={key} rowData={rowData} className={cn} style={style} />
    );
  };

  render() {
    const {
      enableLoader,
      showLoader,
      showRowSelection,
      disableRowSelection,
      data,
      rowHeight,
      headerHeight = TABLE_HEADER_HEIGHT,
      scrollToAlignment = 'start', // 'auto', 'end', 'center' of list
      scrollToIndex,
      children,
      uniqueKey,
      onScroll,
      onRowClick,
      sort,
      sortBy,
      sortDirection,
      // colorField,
      noDataText = 'No data found',
    } = this.props;
    const { selected } = this.state;

    const getRowClassName = ({ index }) => {
      let classname;

      if (index < 0) {
        classname = styles.headerRow;
      } else {
        classname =
          (index + 1) % 2 === 0
            ? `${styles.row} ${styles.evenRow}`
            : `${styles.row} ${styles.oddRow}`;
      }

      // if (index === hoveredRowIndex) {
      //   classname += ` ${styles.hoveredRow}`
      // }

      if (index !== -1 && typeof onRowClick === 'function') {
        classname += ` ${styles.clickableRow}`;
      }

      return classname;
    };

    const disableRowSelectionClassName = disableRowSelection ? styles.disableRowSelection : '';

    return (
      <React.Fragment>
        {showLoader ? (
          <div className={styles.loadingOverlay}>
            <EcgLoader />
          </div>
        ) : !showLoader && data && data.length === 0 ? (
          <div className={styles.noDataOverlay}>
            <div className={styles.noDataText}>{noDataText}</div>
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <ReactVirtualizedTable
                className={styles.table}
                width={width}
                height={height}
                headerClassName={styles.headerWrapper}
                headerHeight={headerHeight}
                rowHeight={rowHeight}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                rowClassName={getRowClassName}
                rowRenderer={this.rowRenderer}
                // onRowMouseOver={this.onRowMouseOver}
                // onRowMouseOut={this.onRowMouseOut}
                onRowClick={onRowClick}
                onScroll={(heights) => {
                  if (typeof onScroll === 'function') {
                    let rowIndex = Math.floor(heights.scrollTop / rowHeight);
                    const scrollTopRow = heights.scrollTop % rowHeight;
                    const rowIsMoreThanHalfWay = scrollTopRow > rowHeight / 2;
                    const tableIsAtBottomOfList =
                      heights.scrollTop + heights.clientHeight === heights.scrollHeight;
                    if (rowIsMoreThanHalfWay) {
                      rowIndex = rowIndex + 1 <= data.length - 1 ? rowIndex + 1 : rowIndex;
                    }

                    if (
                      scrollToIndex !== rowIndex &&
                      rowIsMoreThanHalfWay &&
                      !tableIsAtBottomOfList
                    ) {
                      window.requestAnimationFrame(() => {
                        onScroll();
                      });
                    }
                  }
                }}
                scrollToAlignment={scrollToAlignment}
                scrollToIndex={scrollToIndex}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                overscanRowCount={5}
                ref={(node) => {
                  this.tableRef = node;
                }}
                gridClassName={styles.tableBody}
              >
                {showRowSelection && (
                  <Column
                    className={`${styles.column} ${disableRowSelectionClassName}`}
                    headerClassName={`${styles.headerColumn} ${disableRowSelectionClassName}`}
                    label="" // not needed but field required by library
                    dataKey={uniqueKey}
                    width={TABLE_CHECKBOX_WIDTH}
                    headerRenderer={({ label, dataKey, rowData }) => {
                      const isAllRowsSelected = this.isAllRowsSelected(selected, data);

                      return (
                        <div className={TableHeaderStyles.headerWithControls}>
                          <div className={TableHeaderStyles.columnHeader}>{label}</div>
                          <div
                            className={TableHeaderStyles.columnFilter}
                            onClick={() => this.handleOnAllClick(dataKey)}
                          >
                            <div className={styles.checkbox}>
                              {isAllRowsSelected ? (
                                <CheckedCheckboxIcon className={styles.checkboxIconChecked} />
                              ) : (
                                <UncheckedCheckboxIcon className={styles.checkboxIconUnchecked} />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    cellRenderer={({ dataKey, rowData }) => {
                      const key = rowData[dataKey];
                      const selection = selected && selected[key] ? selected[key] : false;

                      return (
                        <div
                          className={styles.checkbox}
                          onClick={(e) => this.handleOnClick(e, key)}
                        >
                          {selection ? (
                            <CheckedCheckboxIcon className={styles.checkboxIconChecked} />
                          ) : (
                            <UncheckedCheckboxIcon className={styles.checkboxIconUnchecked} />
                          )}
                        </div>
                      );
                    }}
                  />
                )}
                {children}
              </ReactVirtualizedTable>
            )}
          </AutoSizer>
        )}
      </React.Fragment>
    );
  }
}

export default Table;
