import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import SideNav from 'components/mui/asideBar/SideNav';
import ScreenLayout from 'layouts/ScreenLayout';
import Header from './Header';
import Program from './components/Program';
import HealthDetails from './components/HealthDetails';
import PatientFile from './components/Metrics/PatientFile';
import ActivityAndNotes from './components/ActivityAndNotes';
import Messages from './components/Messages';
import ThisSession from './components/ThisSession/ThisSession';

import { getMemberProfileDetails } from 'store/actions/memberProfile';
import { PATIENT_FILE_TABS } from 'constants/app';
import PreviewReportModal from 'components/AppointmentDetails/PreviewReportModal';
import { USER_TEAM_TYPE } from 'utilities/Constants';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import SkeletonLoader from 'components/Loading/SkeletonLoader';

function NewMUIScreens() {
  const dispatch = useDispatch();

  const { memberId, appointmentId, postSession } = useParams();

  const [previewReportModalOpen, setPreviewReportModalOpen] = useState(false);
  const [active, setActive] = useState(1);
  const [isCompleted, setIsCompleted] = useState(false);
  const [reportSent, setReportSent] = useState(true);

  const clinicianId = useSelector((state) => state.user.profile.id);
  const { error, programLoading } = useSelector((state) => state.memberProfile);

  useEffect(() => {
    dispatch(getMemberProfileDetails(memberId));
  }, []);

  const getTab = () => {
    switch (active) {
      case '1':
        return (
          <ThisSession
            setIsCompleted={setIsCompleted}
            setPreviewReportModalOpen={setPreviewReportModalOpen}
            isCompleted={isCompleted}
            setReportSent={setReportSent}
            reportSent={reportSent}
          />
        );
      case '2':
        return <Program />;
      case '3':
        return <HealthDetails />;
      case '5':
        return <PatientFile memberId={memberId} tab={PATIENT_FILE_TABS.EKGS} />;
      case '6':
        return <PatientFile memberId={memberId} tab={PATIENT_FILE_TABS.BLOOD_PRESSURES} />;
      case '7':
        return <PatientFile memberId={memberId} tab={PATIENT_FILE_TABS.WEIGHTS} />;
      case '8':
        return <ActivityAndNotes />;
      case '9':
        return <Messages />;
      default:
        return appointmentId == undefined || appointmentId == ' ' ? (
          <Program />
        ) : (
          <ThisSession
            setIsCompleted={setIsCompleted}
            setPreviewReportModalOpen={setPreviewReportModalOpen}
            isCompleted={isCompleted}
            setReportSent={setReportSent}
            reportSent={reportSent}
          />
        );
    }
  };

  const closePreviewModal = () => {
    setPreviewReportModalOpen(false);
  };

  return (
    <>
      {previewReportModalOpen && (
        <PreviewReportModal
          open={previewReportModalOpen}
          memberId={clinicianId}
          patientId={memberId}
          appointmentId={appointmentId}
          handlePreviewReportModalClose={closePreviewModal}
        />
      )}
      {error === null ? (
        <ScreenLayout
          // row="row-reverse"
          header={
            <Header
              refreshAction={() => dispatch(getMemberProfileDetails(memberId))}
              postSession={postSession}
            />
          }
          // consumer users wont have access to see any tabs in member profile
          aside={!postSession && <SideNav setActive={setActive} appointmentId={appointmentId} />}
        >
          {getTab()}
        </ScreenLayout>
      ) : (
        <SkeletonLoader isloading={programLoading}>
          {/* error handling for any APIs error */}
          <TeamAlert title={error} error={true} />
        </SkeletonLoader>
      )}
    </>
  );
}

export default NewMUIScreens;
