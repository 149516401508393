import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearDeterminate(props) {
  const [progress, setProgress] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (props.duration && props.isPlaying) {
      let timer = null;
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setCount((count) => (count = count + 1));
            return 0;
          }
          const diff = 100 / props.duration;
          const MinValue = Math.min(oldProgress + diff, 100);
          if ((props.duration >= 10 && MinValue === 100) || (count === 2 && props.duration < 10)) {
            props.setIsPlaying(false);
            props.setFilePlayed(false);
            setProgress(0);
            setCount(0);
          }
          return MinValue;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [props.duration, progress, props.isPlaying]);
  return (
    <Box sx={{ width: props?.size }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}
