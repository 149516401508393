import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
// import { Link } from 'react-router-dom';
import { ALERT_SUBTYPE_CONVERSION, COACH_ROUTES, ROUTES } from 'constants/app';
import { useHistory } from 'react-router';
import WarningIcon from '@material-ui/icons/Warning';
import EmailIcon from '@material-ui/icons/Email';
import TodayIcon from '@material-ui/icons/Today';
import { fontSizes } from 'style/styleSettings';

const useStyles = makeStyles({
  root: {
    minWidth: '100px',
    height: '80px',
    boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px`,
    borderRadius: '8px',
    borderTop: (props) => `2px solid ${props.color}`,
    borderRight: (props) => `2px solid ${props.color}`,
    borderBottom: (props) => `2px solid ${props.color}`,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // border: (props) => `2px solid ${props.color}`,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: (props) => props.color,
      marginTop: '5px',
    },
  },
  leftColor: {
    width: '12px',
    height: '120px',
    backgroundColor: (props) => props.color,
  },
  icon: {
    margin: '20px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: (props) => props.bgColor,
    textAlign: 'center',
  },
  content: {
    flexDirection: 'column',
    width: 'auto',
  },
});

const DashboardCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const memberListNavigation = useCallback(
    (alertType) => () => {
      history.push(`${ROUTES.ALERT}/${ALERT_SUBTYPE_CONVERSION[alertType]}`);
    },
    [],
  );
  return (
    <Card className={classes.root} onClick={memberListNavigation(props.title)}>
      <div className={classes.leftColor}></div>
      <div className={classes.icon}>
        {props.icon === 'alert' ? (
          <WarningIcon color={props.color} />
        ) : props.icon === 'message' ? (
          <EmailIcon color={props.color} />
        ) : (
          <TodayIcon />
        )}
      </div>
      <div className={classes.content}>
        <Typography
          variant="h5"
          // color="textSecondary"
          gutterBottom
          style={{
            fontFamily: 'Work Sans',
          }}
        >
          <b>{props.content}</b>
        </Typography>
        <Typography
          variant="body2"
          align="center"
          style={{
            fontFamily: 'Work Sans',
            fontSize: fontSizes.BASE,
          }}
        >
          {props.title}
        </Typography>
      </div>

      {/* <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions> */}
    </Card>
  );
};

export default DashboardCard;
