import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function SliderSizesprops(props) {
  return (
    <Box sx={{ width: props?.size, marginTop: '7px' }}>
      <Slider
        {...props}
        size={props?.variant}
        onChange = {props.onChange}
        value={props?.value}
        aria-label="Small"
        valueLabelDisplay="auto"
      />
    </Box>
  );
}
