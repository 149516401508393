/* eslint-disable */
import axios from 'axios';
import * as actionTypes from 'store/actions/actionTypes';
import { store } from '..';
import { clearToken, getToken } from 'auth/AuthUtilities';
import { PORTAL_ERROR_MESSAGES } from 'constants/app';

export const GoAPI = axios.create({
  baseURL: process.env.REACT_APP_GOKARDIA_API_BASE,
});
export const KccGoAPI = axios.create({
  baseURL: process.env.REACT_APP_KCC_GOKARDIA_API,
});
export const TeleKardiaAPI = axios.create({
  baseURL: process.env.REACT_APP_TELEKARDIA_API_BASE,
});
export const IntakeKardiaAPI = axios.create({
  baseURL: process.env.REACT_APP_INTAKEKARDIA_API_BASE,
});
export const ChatKardiaAPI = axios.create({
  baseURL: process.env.REACT_APP_CHAT_KARDIA_SERVICE,
});
export const EscalationAPI = axios.create({
  baseURL: process.env.REACT_APP_ESCALATION_SERVICE,
});
export const KproService = axios.create({
  baseURL: process.env.REACT_APP_KPRO_SERVICE,
});
export const CrpAPI = axios.create({
  baseURL: process.env.REACT_APP_CRP_SERVICE,
});

export const ResponseCallback = (res) => {
  return res;
};
export const RequestCallback = (req) => {
  // only `/attachment` api needs an extra header
  if (req.url.includes('/attachment')) {
    req.headers['Content-Type'] = 'multipart/form-data';
    return req;
  }
  if (!req.headers.Authorization) {
    const token = getToken();
    if (token) {
      req.headers['Authorization'] = `Bearer ${token}`;
    }
    return req;
  } else {
    return req;
  }
};

export const ErrorCallback = (err) => {
  const isVideoCall = store.getState().user.isComponentMounted;
  const isAudioCall = store.getState().adhocCall.isFloatingWindow;

  switch (err.response?.status) {
    case 401: {
      //avoid dispatching api error if videocall/audiocall is in progress.
      if (isVideoCall || isAudioCall) return;

      console.log('DIAGNOSTIC: Session Expired, logging you out');
      clearToken();
      store.dispatch({
        type: actionTypes.BACKEND_DOWN,
        payload: PORTAL_ERROR_MESSAGES.SESSION_TIMEOUT,
      });
      return setTimeout(clearToken, 2000);
    }
    case 502:
    case 503: {
      //avoid dispatching api error if videocall/audiocall is in progress.
      if (isVideoCall || isAudioCall) return;

      console.log('DIAGNOSTIC: Backend Server down, logging you out');
      store.dispatch({
        type: actionTypes.BACKEND_DOWN,
        payload: PORTAL_ERROR_MESSAGES.BACKEND_DOWN,
      });
      return setTimeout(clearToken, 2000);
    }
  }
  throw err;
};

// ========= File upload request/response/error interceptors' callback ===========//
let isPendingrequest = 0;
export const chatRequestCallback = (req) => {
  ++isPendingrequest;
  store.dispatch({
    type: actionTypes.IS_PENDING_REQUEST,
    payload: isPendingrequest,
  });
  return req;
};
export const chatResponseCallback = (res) => {
  --isPendingrequest;
  store.dispatch({
    type: actionTypes.IS_PENDING_REQUEST,
    payload: isPendingrequest,
  });
  return res;
};
export const chatErrorCallback = (err) => {
  --isPendingrequest;
  store.dispatch({
    type: actionTypes.IS_PENDING_REQUEST,
    payload: isPendingrequest,
  });
  throw err;
};
//======== ends ==========//

// Add a response interceptor
GoAPI.interceptors.request.use(RequestCallback, ErrorCallback);
GoAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
KccGoAPI.interceptors.request.use(RequestCallback, ErrorCallback);
KccGoAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
CrpAPI.interceptors.request.use(RequestCallback, ErrorCallback);
CrpAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
KproService.interceptors.request.use(RequestCallback, ErrorCallback);
KproService.interceptors.response.use(ResponseCallback, ErrorCallback);
TeleKardiaAPI.interceptors.request.use(RequestCallback, ErrorCallback);
TeleKardiaAPI.interceptors.response.use(ResponseCallback, ErrorCallback);
ChatKardiaAPI.interceptors.request.use(chatRequestCallback, chatErrorCallback); //file upload request interceptor
ChatKardiaAPI.interceptors.response.use(chatResponseCallback, chatErrorCallback); //file upload response interceptor
