import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@mui/material/styles';
import {
  darkBluishGrey,
  jungleGreen,
  white,
  snowWhite,
  fullBlack,
  lightGreen,
  dustySmoke,
} from '../../../style/colors';
import { fontFamily } from '../../../style/commonStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '&.css-yz9k0d-MuiInputBase-input': {
      fontSize: '11px',
      color: 'black',
    },
    fontFamily: 'Work Sans',
    fontSize: '11px',
    color: 'black',
  },
  container: {
    // backgroundColor: snowWhite,
    // minHeight: '100vh',
    overflow: 'auto',
    padding: '1rem',
    paddingTop: '0',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  breadCrumbStyle: {
    marginTop: '1vh',
  },
  tabStyle: {
    margin: '1.2vh 0',
  },
  tableParent: {
    width: '100%',
    backgroundColor: '#F5F5F5',
    // height:"78vh",
    margin: '1vh auto',
    borderRadius: '12px',
    border: '1px solid #CCCCCC',
    padding: '1.5vh 2.5vh',
    height: 'fit-content',
  },
  patientInfo: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  },
  tableAlign: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    columnGap: '20px',
  },
  boxStyle: {
    color: '#00000099',
    border: '1px solid #CCCCCC',
    padding: '5px 4px',
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: 14,
  },
  // toastContainer: {
  //   height: "50px",
  //   position: "relative",
  //   marginTop: "1rem",
  // },
  note: {
    fontSize: 13,
    display: 'flex',
    columnGap: '8px',
    margin: '7px auto',
  },
  notes: {
    marginTop: '1.1vh',
  },
  buttonGroup: {
    display: 'flex',
    gap: '20px',
  },
  inputContainer: {
    position: 'relative',
    width: '100%',
  },
  inputElement: {
    color: darkBluishGrey,
    borderRadius: '4px',
    // marginLeft: "0.5rem",
    // padding: "0.3rem",
    width: '100%',
    fontFamily,
    fontSize: '16px',
    lineHeight: '20px',
  },
  inputBase: {
    color: fullBlack,
  },
  cancelIcon: {
    color: darkBluishGrey,
    height: '20px',
    width: '20px',
  },
  searchResultTray: {
    maxHeight: '300px',
    overflow: 'auto',
    position: 'absolute',
    width: 'inherit',
    zIndex: 4,
  },
  list: {
    '&:hover': {
      backgroundColor: `${lightGreen} !important`,
    },
    fontFamily: `${fontFamily} !important`,
    height: '56px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  searchResultTrayText: {
    fontFamily,
    paddingLeft: '24px',
    fontSize: '16px',
    lineHeight: '20px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '30%',
    textTransform: 'capitalize',
  },
  searchResultTrayName: {
    fontWeight: 'bold',
  },
  noResultFound: {
    fontWeight: 'bold',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    fontFamily,
    background: snowWhite,
    color: darkBluishGrey,
    fontSize: '14px',
    border: '.5px solid #999999',
    textTransform: 'capitalize',
  },
  reviewAudit: {
    fontFamily: `${fontFamily} !important`,
    fontWeight: '600 !important',
    lineHeight: '1.7 !important',
    letterSpacing: '0.02em !important',
    marginBottom: '1em !important',
  },
}));

export const muitheme = createTheme({
  palette: {
    primary: {
      main: jungleGreen,
    },
  },
  typography: {
    fontFamily,
  },
});
