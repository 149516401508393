import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RetryIcon from '@material-ui/icons/Replay';
import { fonts, fontSizes } from 'style/styleSettings';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    fontFamily: fonts.SESSION,
    '& .MuiButton-root:hover': {
      backgroundColor: `lightgray !important`,
    },
    '& .MuiTypography-h5': {
      fontSize: `${fontSizes.HEADING} !important`,
    },
    '& .MuiInputBase-input': {
      border: '1px solid lightgrey',
    },
    '& .MuiButton-label': {
      fontFamily: 'Work Sans',
    },
  },
  attachmentSection: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dashed rgba(0, 0, 0, 0.12)',
    textAlign: 'center',
    padding: '20px 0',
    borderRadius: '6px',
  },
});

const DocumentationFileUpload = ({ data, onUpload, snackBarDataHandler, file }) => {
  const classes = useStyles();
  const [attachments, setAttachements] = useState([]);

  useEffect(() => {
    setAttachements(data);
  }, [data]);

  useEffect(() => {
    file !== undefined && uploadAttachments(file);
  }, []);

  //upload attachments
  const uploadAttachments = (file, retry = false) => {
    console.log('FILE IS', file);
    const attachmentList = [...attachments];
    if (file?.size / 1000 > 1024) {
      snackBarDataHandler({
        open: true,
        text: 'File size too big!',
        severity: 'error',
      });
      return;
    }
    if (!retry) {
      attachmentList.push(file);
    }
    onUpload(file).then((res) => {
      if (res?.error) {
        snackBarDataHandler({
          open: true,
          text: 'File size too big!',
          severity: 'error',
        });
      }
      const attIndex = attachmentList.findIndex((a) => a.name === file?.name);
      if (res.status == 200) {
        attachmentList[attIndex].error = false;
        attachmentList[attIndex].success = true;
      }
      if (res.status != 200 || res.error) {
        attachmentList[attIndex].error = true;
        attachmentList[attIndex].success = false;
      }

      setAttachements(attachmentList);
    });
  };
  //remove an attachment from list
  const removeAttachment = (file) => {
    const filteredAttachments = attachments.filter(
      (item) => item.lastModified !== file.lastModified,
    );
    setAttachements(filteredAttachments);
  };
  return (
    <>
      {/* render attachemnts */}
      {attachments && attachments.length > 0 && (
        <Grid item xs={12} style={{ maxHeight: '120px', overflow: 'auto' }}>
          {attachments.map((item, i) => {
            return (
              <Grid container key={i} style={{ alignItems: 'center', padding: '5px 20px' }}>
                <Grid item xs={1}>
                  <NoteAddIcon
                    style={{
                      color: '#6387C5',
                      width: '30px',
                      height: '30px',
                      backgroundColor: '#E1E4FC',
                      padding: '5px',
                      borderRadius: '50%',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  onClick={() => item.uploaded && window.open(item.preSignedUrl, '_blank')}
                >
                  <Typography>{item.name}</Typography>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Typography>
                      {item.size ? `${Math.round(item.size / 1024)} KB` : '-'}
                    </Typography>
                    <Typography
                      style={{
                        marginLeft: '10px',
                      }}
                    >
                      {'•'}
                    </Typography>
                    <Typography
                      style={{
                        marginLeft: '10px',
                      }}
                    >
                      {item.success ? 'Completed' : item.error ? 'Failed' : 'Uploading..'}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  {!item.uploaded && (
                    <CloseIcon
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      onClick={() => removeAttachment(item)}
                    />
                  )}
                </Grid>
                <Grid item xs={1}>
                  {item.success && !item.error && (
                    <CheckCircleIcon
                      style={{
                        color: '#6387C5',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  )}
                  {!item.success && item.error && (
                    <RetryIcon
                      onClick={() => uploadAttachments(item, true)}
                      style={{
                        color: 'red',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}

      {/* attachments */}

      {file == undefined && (
        <Grid item xs={12}>
          <div className={classes.attachmentSection}>
            <input
              accept="*"
              id="icon-button-photo"
              onChange={(e) => {
                if (e.target.files?.length) {
                  Object.values(e.target.files).map((item) => {
                    uploadAttachments(item);
                  });
                }
              }}
              type="file"
              multiple
              style={{ display: 'none' }}
            />
            <label htmlFor="icon-button-photo">
              <NoteAddIcon
                style={{
                  color: '#6387C5',
                  width: '30px',
                  height: '30px',
                  backgroundColor: '#E1E4FC',
                  padding: '5px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                }}
              />
            </label>
            {/* <Typography style={{ color: '#1976D2' }}>Click to upload</Typography>
            <Typography>SVG, PNG, JPG or GIF (max. 1MB)</Typography> */}
            <Typography style={{ color: '#6387C5', fontFamily: 'Work Sans', fontWeight: '700' }}>
              <label htmlFor="icon-button-photo">
                <u style={{ cursor: 'pointer' }}>Click to upload</u>
              </label>
              <span style={{ color: '#000', fontWeight: '700' }}> or drag and drop</span>
            </Typography>
            <Typography style={{ color: 'rgba(0,0,0,0.6)', fontFamily: 'Work Sans' }}>
              SVG, PNG, JPG or GIF (max. 1MB)
            </Typography>
          </div>
        </Grid>
      )}
    </>
  );
};

export default DocumentationFileUpload;
