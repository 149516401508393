import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
// import { Link } from 'react-router-dom';
import { getTimezoneName, parseName, streamToPromise } from 'utilities/Utilities';
import { AppointmentStatusTypes, SessionChannelTypeId } from 'utilities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import apiservice from 'api/services/apiservice.service';
import moment from 'moment';
import { useHistory } from 'react-router';

import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES } from 'constants/app';
import EcgLoader from 'components/Shared/EcgLoader';
import { StatusColumn } from 'screens/coach/MemberList/components/StatusColumn';
import withStartConsultation from 'components/Dashboard/withStartConsultation';
import { getPatientDetails, isComponentMounted, startAdhocCall } from 'store/actions';
import { getMemberProfileDetails } from 'store/actions/memberProfile';
import { homeScreenButtonText } from './constants/DashBoardConstant';

const Root = styled(TableContainer)(({ theme }) => ({
  minWidth: 650,

  '& .MuiTableCell-root': {
    fontFamily: 'Work Sans !important',
    fontSize: '16px',
  },
  '& .MuiTableHead-root': {
    background: '#F1F2F3',
    borderBottom: '1px solid #B4BBC0',
    alignSelf: 'stretch',
    '& .MuiTableCell-root': {
      fontWeight: 700,
    },
  },
  '& .MuiTableBody-root': {
    '& .MuiTableCell-root': {
      fontWeight: 400,
    },
  },
  '& .MuiTableRow-root': {
    '&:nth-of-type(even)': {
      backgroundColor: '#F0F8F5 !important',
    },
    '&:hover': {
      backgroundColor: '#F5F5F5 !important',
    },
  },
  '& .MuiTypography-h5': {
    fontSize: '20px',
    lineHeight: '23px',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    margin: '20px',
  },
  '& .MuiTypography-h6': {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    margin: '20px',
  },
  '& .MuiTypography-body1': {
    color: '#067F6F',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  '& .MuiTypography-body2': {
    margin: 20,
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    fontColor: 'grey',
    fontFamily: 'Work Sans',
  },
}));

const DashboardTable = (props) => {
  const theme = useTheme();
  const { userType } = props;
  const history = useHistory();
  const [sessionData, setSessionData] = useState([]);
  const [upcomingSessionInWaitroom, setupcomingSessionInWaitroom] = useState(null);

  // ========= redux state =========== //
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const { patientDetails } = useSelector((state) => state.appointments);
  const isLoading = useSelector((state) => state.appointments.loading);
  const isTeleconnectStarted = useSelector((state) => state.user.isComponentMounted);
  const { isFloatingWindow } = useSelector((state) => state.adhocCall);
  const appointments = useSelector((state) => state.events.events);
  //=========== end =========== //

  let offsetInMinutes = new Date().getTimezoneOffset();

  const timezoneValue = getTimezoneName();
  const now = moment();

  const startDateformatted = now.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
  const endDateformatted = now.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');

  /**
   * checks for updated session status from redux store
   * by calling /calendar api which updates event redux store.
   * @returns single array od session whose status is 'member in waitroom'
   */
  useEffect(() => {
    setupcomingSessionInWaitroom(
      appointments.filter((event) => event?.eventType === 'appointment' && event?.status === 12),
    );
  }, [appointments]);

  const getDataForTodaySession = async () => {
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/calendar/?endDate=${endDateformatted}&startDate=${startDateformatted}&offsetInMinutes=${offsetInMinutes}&timeZone=${timezoneValue}&splitBasedOnDays=true`,
      }),
    );
    const appointments = await appointmentRequest;
    if (appointments) {
      // console.log(appointments);
      return appointments.data.appointments;
      // return appointments.data.result.appointments;
    }
  };

  useEffect(() => {
    getDataForTodaySession().then((data) => {
      setSessionData(data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [openDetailModal, setOpenDetailModal] = useState(false);
  const navigate = (pId, appId, appStatus) => {
    if (
      [
        AppointmentStatusTypes.Scheduled,
        AppointmentStatusTypes.InProgress,
        AppointmentStatusTypes.MemberInWaitingRoom,
        AppointmentStatusTypes.Encounter_Summary_Preparation,
      ].includes(appStatus)
    ) {
      if (userType === AUTH_TYPE.COACH)
        history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${pId}/${appId}`);
      else history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${pId}/${appId}`);
    } else {
      if (userType === AUTH_TYPE.COACH) history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${pId}`);
      else history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${pId}`);
    }
  };

  const [appId, setAppId] = useState('');
  const [patientName, setPatientName] = useState('');
  const videoCall = useCallback(
    (id, name) => {
      setAppId(id);
      setPatientName(name);
      const params = {
        timeZoneOffset: offsetInMinutes,
        timeZone: getTimezoneName(),
      };
      dispatch(getPatientDetails(teamId, clinicianId, id, params));
    },
    [patientDetails],
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(patientDetails).length > 0 && appId.length > 0) {
      props.alertHandler(patientName);
    }
    // return () => setAppId('');
  }, [patientDetails, appId]);

  const audioCall = (memberId, appointmentId) => {
    dispatch(getMemberProfileDetails(memberId)).then(() => {
      dispatch(startAdhocCall('Call Member', memberId, appointmentId, false));
    });
    completeSession(memberId, appointmentId);
  };

  const completeSession = useCallback((pId, appId) => {
    if (userType === AUTH_TYPE.COACH)
      history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${pId}/${appId}`);
    else history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${pId}/${appId}`);
  }, []);

  return (
    <Root>
      <ThemeProvider theme={theme}>
        {isLoading ? (
          <EcgLoader />
        ) : (
          <>
            <Box>
              <Typography variant="h5" gutterBottom>
                Today's Session
              </Typography>
              {/* <Typography variant="h6">{moment(new Date()).format('MMM Do YYYY')}</Typography> */}
            </Box>
            <Table aria-label="simple table">
              {sessionData.length > 0 && (
                <TableHead>
                  <TableRow hover>
                    <TableCell>
                      <b>Time</b>
                    </TableCell>
                    <TableCell>
                      <b>{userType === AUTH_TYPE.COACH ? `Member` : `Patient`}</b>
                    </TableCell>
                    <TableCell>
                      <b>Status</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {sessionData.reverse().map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {`${moment(new Date(row.scheduledStartTime)).format('LT')} -
                        ${moment(new Date(row.scheduledEndTime)).format('LT')}`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="body1"
                        onClick={() =>
                          navigate(
                            row.patient.patientId,
                            row.appointmentId,
                            row.appointmentStatusId,
                          )
                        }
                      >
                        {parseName(row.patient.firstName, row.patient.lastName)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {upcomingSessionInWaitroom.length > 0 &&
                      upcomingSessionInWaitroom[0]?.id === row.id ? (
                        <StatusColumn data={upcomingSessionInWaitroom[0]?.title} />
                      ) : (
                        <StatusColumn data={row.appointmentStatus} />
                      )}
                    </TableCell>
                    <TableCell>
                      {((row.appointmentStatusId === AppointmentStatusTypes.MemberInWaitingRoom &&
                        row.appointmentChannelTypeId !== SessionChannelTypeId.AS_SCHEDULED_PHONE) ||
                        (upcomingSessionInWaitroom[0]?.id === row.id &&
                          upcomingSessionInWaitroom[0]?.status ===
                            AppointmentStatusTypes.MemberInWaitingRoom)) && (
                        <Button
                          variant="outlined"
                          disabled={isTeleconnectStarted || isFloatingWindow}
                          onClick={() =>
                            videoCall(
                              row.appointmentId,
                              `${row.patient.lastName}, ${row.patient.firstName}`,
                            )
                          }
                        >
                          {homeScreenButtonText.INITIATE_VIDEO_CALL}
                        </Button>
                      )}
                      {row.appointmentStatusId === AppointmentStatusTypes.InProgress &&
                        row.appointmentChannelTypeId !==
                          SessionChannelTypeId.AS_SCHEDULED_PHONE && (
                          <Button
                            variant="outlined"
                            disabled={isTeleconnectStarted || isFloatingWindow}
                            onClick={() =>
                              videoCall(
                                row.appointmentId,
                                row.patient.firstName,
                                row.patient.lastName,
                              )
                            }
                          >
                            {homeScreenButtonText.REJOIN_VIDEO_CALL}
                          </Button>
                        )}
                      {row.appointmentChannelTypeId === SessionChannelTypeId.AS_SCHEDULED_PHONE &&
                        (row.appointmentStatusId === AppointmentStatusTypes.Scheduled ||
                          row.appointmentStatusId === AppointmentStatusTypes.InProgress ||
                          row.appointmentStatusId ===
                            AppointmentStatusTypes.Encounter_Summary_Preparation) && (
                          <Button
                            variant="contained"
                            disabled={isTeleconnectStarted || isFloatingWindow}
                            onClick={() => audioCall(row.patient.patientId, row.appointmentId)}
                          >
                            {row.appointmentStatusId ===
                            AppointmentStatusTypes.Encounter_Summary_Preparation
                              ? homeScreenButtonText.REJOIN_AUDIO_CALL
                              : homeScreenButtonText.INITIATE_AUDIO_CALL}
                          </Button>
                        )}
                      {row.appointmentStatusId ===
                        AppointmentStatusTypes.Encounter_Summary_Preparation && (
                        <Button
                          variant="outlined"
                          onClick={() => completeSession(row.patient.patientId, row.appointmentId)}
                        >
                          {homeScreenButtonText.COMPLETE_SESSION_NOTES}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        {!isLoading && sessionData.length === 0 && (
          <>
            <Typography variant="body2">No session scheduled for today</Typography>
          </>
        )}
      </ThemeProvider>
    </Root>
  );
};

export default withStartConsultation(DashboardTable);
