import React, { useEffect } from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, Paper, Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { getIntakeDetailsForUser } from 'store/actions/fullfilment';

const ShippingDetailModal = ({ currentMember, handleClose, openDismissModal }) => {
  const [intakeDetails, setIntakeDetails] = React.useState(null);
  const [intakeDetailsFetching, setIntakeDetailsFetching] = React.useState(false);
  useEffect(() => {
    getIntakeDetailsForUserHandler();
  }, []);
  const getIntakeDetailsForUserHandler = () => {
    let params = null;
    setIntakeDetailsFetching(true);
    getIntakeDetailsForUser(currentMember.custom_participant_id).then((res) => {
      setIntakeDetailsFetching(false);
      setIntakeDetails(res);
      return;
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDismissModal}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#F1F2F3' }} id="customized-dialog-title">
        <Typography variant={'h5'} style={{ fontFamily: 'Work Sans' }}>
          {currentMember?.last_name}, {currentMember?.first_name}
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
            Sponsor:{' '}
          </Typography>
          <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
            {currentMember?.sponsor_symbol}
          </Typography>
        </div>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ backgroundColor: '#F1F2F3' }}>
        <Grid container spacing={2}>
          <Paper
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              marginTop: '16px',
              borderRadius: '8px',
              padding: '10px 20px',
              backgroundColor: 'white !important',
              width: '100%',
            }}
          >
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6} spacing={4}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Account Creation Date
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {moment(currentMember.created_at).format('MM-DD-YYYY')},
                  {moment(currentMember.created_at).format('HH:MM A')}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Status
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {' '}
                  {currentMember?.status}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Phone Number
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {' '}
                  {currentMember?.phone_number}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Email
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {' '}
                  {currentMember?.encrypted_email}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              marginTop: '16px',
              borderRadius: '8px',
              padding: '10px 20px',
              backgroundColor: 'white !important',
              width: '100%',
            }}
          >
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6} spacing={4}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Address
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {' '}
                  {currentMember?.primary_line}
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {' '}
                  {currentMember?.secondary_line}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  State
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {currentMember?.state}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  City
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {currentMember?.city}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Zip Code
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {currentMember?.postal_code}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              marginTop: '16px',
              borderRadius: '8px',
              padding: '10px 20px',
              backgroundColor: 'white !important',
              width: '100%',
            }}
          >
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6} spacing={4}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Program plan completion
                </Typography>
                {intakeDetails?.pp?.totalSteps ? (
                  <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                    {intakeDetails?.pp?.stepNumber} / {intakeDetails?.pp?.totalSteps}
                  </Typography>
                ) : (
                  <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                    {intakeDetailsFetching ? 'Fetching..' : '-'}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Heart health assessment completion
                </Typography>

                {intakeDetails?.hha?.totalSteps ? (
                  <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                    {intakeDetails?.hha?.stepNumber} / {intakeDetails?.hha?.totalSteps}
                  </Typography>
                ) : (
                  <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                    {intakeDetailsFetching ? 'Fetching..' : '-'}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Shipped
                </Typography>

                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {currentMember.shipped_at
                    ? moment(currentMember.shipped_at).format('MM-DD-YYYY, HH:MM A')
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              marginTop: '16px',
              borderRadius: '8px',
              padding: '10px 20px',
              backgroundColor: 'white !important',
              width: '100%',
            }}
          >
            <Grid container item xs={12}>
              <Grid item>
                <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
                  Note
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
                  {currentMember?.note || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShippingDetailModal;
