import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import WeekPicker from './weekpicker';
import englishLocale from 'date-fns/locale/en-US';
import spanishLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import germanLocale from 'date-fns/locale/de';
import italianLocale from 'date-fns/locale/it';
import { useTranslation } from 'react-i18next';

export default function MaterialUIPickers(props) {
  const [selectedDate, setSelectedDate] = React.useState(props.date);
  const handleDateChange = (date) => {
    setSelectedDate(date.daterange.selectedStartDate);
    props.changeHandle(date);
  };
  const { i18n } = useTranslation();
  const localeMap = {
    en: englishLocale,
    es: spanishLocale,
    de: germanLocale,
    it: italianLocale,
    fr: frLocale,
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <Grid container justify="flex-end">
        <WeekPicker
          autoOk
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM-dd-yyyy"
          // margin="normal"
          id="date-picker-inline"
          // label={props.label}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          inputProps={{
            style: {
              padding: props.padding,
            },
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
