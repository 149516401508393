import { Grid, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const ViewNotesSkeleton = () => {
  return (
    <div>
      <Paper
        style={{
          backgroundColor: 'white',
          borderRadius: 5,
          width: '550px',
          boxShadow: '10px, 5px #c1c1c1',
          padding: '20px',
          textAlign: 'left',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Skeleton variant="text" width={'100%'} height={60} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" height={60} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" height={60} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" height={60} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" height={60} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Skeleton variant="rounded" width={'100%'} height={100} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Skeleton variant="rounded" width={'100%'} height={100} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ViewNotesSkeleton;
