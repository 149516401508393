export const carotidEvaluation = {
    "Normal-Normal" : ["Normal sounds for the left carotid artery.","Normal sounds for the right carotid artery."],
    "Normal-Bruit" : ["Normal sounds for the left carotid artery.","Right carotid bruit may be present. Follow up with your healthcare provider is recommended."],
    "Normal-Inaudible" : ["Normal sounds for the left carotid artery.","Sound artifact prevents reliable interpretation of the right carotid audio."],
    "Bruit-Normal" : ["Left carotid bruit may be present. Follow up with your healthcare provider is recommended.","Normal sounds for the right carotid artery."],
    "Bruit-Bruit" : ["Left carotid bruit may be present. Follow up with your healthcare provider is recommended.","Right carotid bruit may be present. Follow up with your healthcare provider is recommended."],
    "Bruit-Inaudible" : ["Left carotid bruit may be present. Follow up with your healthcare provider is recommended.","Sound artifact prevents reliable interpretation of the right carotid audio."],
    "Inaudible-Normal" : ["Sound artifact prevents reliable interpretation of the left carotid audio.","Normal sounds for the right carotid artery."],
    "Inaudible-Bruit" : ["Sound artifact prevents reliable interpretation of the left carotid audio.","Right carotid bruit may be present. Follow up with your healthcare provider is recommended."],
    "Inaudible-Inaudible" : ["Sound artifact prevents reliable interpretation of the left carotid audio.","Sound artifact prevents reliable interpretation of the right carotid audio."],
    "NormalP":"Normal sounds for the left carotid artery.",
    "BruitP":"Left carotid bruit may be present. Follow up with your healthcare provider is recommended.",
    "InaudibleP":"Sound artifact prevents reliable interpretation of the left carotid audio.",
    "NormalM":"Normal sounds for the left carotid artery.",
    "BruitM":"Right carotid bruit may be present. Follow up with your healthcare provider is recommended.",
    "InaudibleM":"Sound artifact prevents reliable interpretation of the right carotid audio.",
  }

export const heartEvaluation = {
    "Normal-Normal" : ["Normal sounds for the pulmonary valve.","Normal sounds for the mitral valve."],
    "Normal-Murmur" : ["Normal sounds for the pulmonary valve.","Murmur may be present in mitral valve. Follow up with your healthcare provider is recommended."],
    "Normal-Inaudible" : ["Normal sounds for the pulmonary valve.","Sound artifact prevents reliable interpretation of the mitral audio."],
    "Murmur-Normal" : ["Murmur may be present in pulmonary valve. Follow up with your healthcare provider is recommended.","Normal sounds for the mitral valve."],
    "Murmur-Murmur" : ["Murmur may be present in pulmonary valve. Follow up with your healthcare provider is recommended.","Murmur may be present in mitral valve. Follow up with your healthcare provider is recommended."],
    "Murmur-Inaudible" : ["Murmur may be present in pulmonary valve. Follow up with your healthcare provider is recommended.","Sound artifact prevents reliable interpretation of the mitral audio."],
    "Inaudible-Normal" : ["Sound artifact prevents reliable interpretation of the pulmonary audio.","Normal sounds for the mitral valve."],
    "Inaudible-Murmur" : ["Sound artifact prevents reliable interpretation of the pulmonary audio.","Murmur may be present in mitral valve. Follow up with your healthcare provider is recommended."],
    "Inaudible-Inaudible" : ["Sound artifact prevents reliable interpretation of the pulmonary audio.","Sound artifact prevents reliable interpretation of the mitral audio."],
    "NormalP":"Normal sounds for the pulmonary valve.",
    "MurmurP":"Murmur may be present in pulmonary valve. Follow up with your healthcare provider is recommended.",
    "InaudibleP":"Sound artifact prevents reliable interpretation of the pulmonary audio.",
    "NormalM":"Normal sounds for the mitral valve.",
    "MurmurM":"Murmur may be present in mitral valve. Follow up with your healthcare provider is recommended.",
    "InaudibleM":"Sound artifact prevents reliable interpretation of the mitral audio."
  }  

  export const INCOMING = "Incoming";

  export const COMPLETED = "Completed";

    export const NOT_STARTED = "NOT STARTED";
    export const OVERDUE = "OVERDUE";
    export const ERROR = "ERROR";
    export const IN_PROGRESS = "IN PROGRESS";

export const reviewStatus = {
  HEART_PULMONARY:"heartPulmonary",
  HEART_MITRAL:"heartMitral",
  HEART_FOLLOWUP:"heartFollowup",
  CAROTID_RIGHT:"carotidRight",
  CAROTID_LEFT:"carotidLeft",
  CAROTID_FOLLOWUP:"carotidFollowup",
  NORMAL:"Normal",
  HEART_NOTES:"heartNotes",
  CAROTID_NOTES:"carotidNotes",
  PULMANORY:"Pulmonary",
  MITRAL:"Mitral",
  LEFT:"Left",
  RIGHT:"Right",
  MURMUR:"Murmur",
  INAUDIBLE:"Inaudible",
  BRUIT:"Bruit",
  LEFT_CAROTID:"Left Carotid",
  RIGHT_CAROTID:"Right Carotid",
}    

export const AUDIO_INBOX = "Audio Inbox";

export const HEART_SOUNDS = "Heart Sounds";

export const HEART_EVALUATION = "Heart Evaluation";

export const CAROTID_EVALUATION = "Carotid Evaluation";

export const CHECKBOX_TEXT = "Follow Up Recommended";

export const CNACLE_REQUEST_FIRST = "Are you sure you want to leave?";

export const CNACLE_REQUEST_SECOND = "If you do, all progress will be lost and the request will return to your worklist.";

export const INPROGRESS_ERROR_CODE = "E#1LGCI6"

export const COMPLETED_ERROR_CODE = "E#1LGV2K";

export const API_RECORDING_AREA_KEYNAME = "recordingArea";

export const API_S3_URL_KEYNAME = "s3SignedUrl";