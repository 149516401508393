export const tableHead = [
  {
    id: 'dateTime',
    label: 'Date',
  },
  {
    id: 'dateTime',
    label: 'Time',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'attachment',
    label: 'Attachment',
    hide: true,
  },
  {
    id: 'type',
    label: 'Type',
  },
 

  {
    id: 'tag',
    label: 'Tag',
  },
  {
    id: 'sessionLogNotes',
    label: 'Actions',
  },
];
export const filterVariableMap = {
  type: 'Type',
  status: 'Status',
  tag: 'Tag',
  attachment: 'Attachment',
};
/**
   * {
    "id": "6d996005-119f-4193-b74b-04a60a717a72",
    "dateTime": "2022-09-26T14:16:05.498",
    "type": "AdHoc Call",
    "tag": "EKG Outreach",
    "status": "Draft",
    "attachment": false
}
   */
