/* Whites */
export const white = '#FFFFFF';
export const snowWhite = '#FAFAFA';
export const platinumSmoke = '#E5E5E5';
export const dustySmoke = '#E4E0E0';
export const dustyWhite = '#E3E5E7';
export const whiteSmoke = '#F2F4F8';
export const lightWhite = '#dddddd';

/* Black */
export const black = '#000000';
export const halfBlack = 'rgba(0, 0, 0, 0.54)';
export const quarterBlack = 'rgba(0, 0, 0, 0.23)';
export const fullBlack = 'rgba(0, 0, 0, 1)';

/* Greens */
export const mintCream = '#F4FAF8';
export const pineGreen = '#067F6F';
export const jungleGreen = '#2D9F86';
export const lightGreen = '#E0EFEE';
export const zompGreen = '#419F86';
export const lightAquaGreen = '#D7EAE8';

/* Greys */
export const bluishGrey = 'rgba(20, 42, 57, 0.16)';
export const darkBluishGrey = '#142A39';
export const darkSlateGrey = '#415B6A';
export const lightOxfordBluishGrey = '#0f2837';
export const mehendiGrey = '#BABFBD';
export const slateGrey = '#6A7881';
export const lightGrey = '#E2E7E3';
export const darkSeaGrey = '#415b6a';

/* Reds (errors) */
export const pantoneRed = '#CC3D3F';

/* Yellows */
export const pantoneYellow = '#fdc246';

export const colorStatusObj = {
  OVERDUE: '#CC3D3F',
  'IN PROGRESS': '#142A39',
  COMPLETED: '#2D9F86',
  'NOT STARTED': '#fdc246',
};

/* Blues */
export const paleBlue = '#174E8C';
