import React, { useEffect } from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, Paper, Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { getActivityTimelineForUser } from 'store/actions/fullfilment';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EcgLoaderSmall from 'components/Shared/EcgLoaderSmall';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2D9F86',
    color: theme.palette.common.white,
    fontFamily: 'Work Sans',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Work Sans',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const legend = {
  ekgDevicePairedDate: 'EKG device paired on',
  bpDevicePairedDate: 'BP device paired on',
  shippedDate: 'Device shipped on',
  shippingReadyDate: 'Ready to Ship on',
  accountCreatedDate: 'Account created in the app on',
  enrollDate: 'User Enrolled on ',
};

const ActivityTimelineModal = ({ currentMember, handleClose, openDismissModal }) => {
  const [data, setData] = React.useState(null);
  const [activityFetching, setActivityFetching] = React.useState(false);
  useEffect(() => {
    getActivityHandler();
  }, []);
  const getActivityHandler = () => {
    setActivityFetching(true);
    getActivityTimelineForUser(currentMember.custom_participant_id).then((res) => {
      setActivityFetching(false);
      setData(res);
      return;
    });
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDismissModal}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#F1F2F3' }} id="customized-dialog-title">
        <Typography variant={'h5'} style={{ fontFamily: 'Work Sans' }}>
          Activity Timeline for {currentMember?.last_name}, {currentMember?.first_name}
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" style={{ color: 'grey', fontFamily: 'Work Sans' }}>
            Sponsor:{' '}
          </Typography>
          <Typography variant="body1" style={{ fontFamily: 'Work Sans' }}>
            {currentMember?.sponsor_symbol}
          </Typography>
        </div>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ backgroundColor: '#F1F2F3' }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Activity</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data != null &&
                Object.entries(legend).map(([key, value], index) => {
                  if (key in data) {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th">{value}</StyledTableCell>
                        <StyledTableCell>
                          {data[key] ? moment(data[key]).format('lll') : '-'}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  }
                })}
            </TableBody>
          </Table>
          {(activityFetching || !data) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <EcgLoaderSmall />
            </div>
          )}
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityTimelineModal;
