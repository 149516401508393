import { connect } from 'react-redux';

import ContentHeader from './ContentHeader';
import { handleToggleNavigationSidebar } from 'store/reducers/app';

const mapActionCreators = {
  handleToggleNavigationSidebar,
};

const mapStateToProps = (state) => ({
  sidebarIsCollapsed: state.app.sidebarIsCollapsed,
  appointments: state.events.events
});

export default connect(mapStateToProps, mapActionCreators)(ContentHeader);
