import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { Box, Button, Grid, Typography, styled } from '@mui/material';
import Alert from '@mui/lab/Alert';

import {
  addSessionHeartHabitDetails,
  deleteSessionHeartHabitDetails,
  editSessionHeartHabitDetails,
} from 'store/actions/session';
import HeartHabitCard from './HeartHabitCard';
import { fonts } from '../../../../style/styleSettings';
import { AUTH_TYPE } from 'constants/app';

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .MuiTypography-body1': {
    fontFamily: `${fonts.SESSION}`,
    fontSize: '16px !important',
  },
}));

const HeartHabitComponent = (props) => {
  const { memberId, appointmentId, userType } = props;
  const dispatch = useDispatch();
  const heartHabitData = useSelector((state) => state.session.heartHabit);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [heartHabit, setheartHabit] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setheartHabit(heartHabitData);
  }, [heartHabitData]);
  // console.log(programFocusData);
  /**
   * Add card handler
   * Locally updates the state to show a new card and not saving to api
   */

  const addCardHandler = () => {
    const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
    let newHH = {
      id: null,
      focus: '',
      focusNote: '',
      recurrencePattern: 'RRULE:FREQ=DAILY;UNTIL=20211103T000000;BYDAY=MO,TU,WE,TH,FR',
      startDate: moment().format(pattern),
      endDate: moment().format(pattern),
    };
    setheartHabit((s) => [...s, newHH]);
  };

  /**
   * addCardHandlerSaveToApi
   * @param {*} data
   * @desc saves the newly added card to api
   */

  const addCardHandlerSaveToApi = (data) => {
    // console.log(data);
    let offsetInMinutes = new Date().getTimezoneOffset();

    dispatch(
      addSessionHeartHabitDetails(
        teamId,
        clinicianId,
        memberId,
        appointmentId,
        offsetInMinutes,
        data,
      ),
    );
    return true;
  };

  /**
   * deleteCardHandler
   * @param {*} id
   * Deletes the new card locally and saves it to the api
   */
  const deleteCardHandler = (id, description) => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    if (id === null || id == undefined) {
      setheartHabit(heartHabitData);
    } else {
      dispatch(
        deleteSessionHeartHabitDetails(
          teamId,
          clinicianId,
          memberId,
          appointmentId,
          offsetInMinutes,
          id,
          description,
        ),
      );
    }
  };

  /**
   * editCardHandler
   * @param {*} id
   * @param {*} data
   * Edits the card with the given id
   */
  const editCardHandler = (id, data) => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    dispatch(
      editSessionHeartHabitDetails(
        teamId,
        clinicianId,
        memberId,
        appointmentId,
        offsetInMinutes,
        id,
        data,
      ),
    );
  };

  return (
    <Root>
      <div>{error && <Alert severity="error">{error}</Alert>}</div>
      <Grid container>
        {heartHabit &&
          heartHabit.map((item, index) => {
            return (
              <Grid item xs={4} key={item.id}>
                <HeartHabitCard
                  item={item}
                  index={index}
                  deleteCard={deleteCardHandler}
                  editCard={editCardHandler}
                  addCardHandlerSaveToApi={addCardHandlerSaveToApi}
                  userType={userType}
                />
              </Grid>
            );
          })}
      </Grid>
      <Grid xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        {heartHabit.length == 0 && <Typography variant="body1">No data available</Typography>}
      </Grid>
      <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {userType === AUTH_TYPE.COACH && heartHabit.length < 3 && (
          <Button variant="outlined" onClick={addCardHandler}>
            ADD HEART HABIT
          </Button>
        )}
      </Grid>
    </Root>
  );
};

export default HeartHabitComponent;
