/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import classes from './Chat.module.css';
import moment from 'moment';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import AttachmentIcon from '@material-ui/icons/Attachment';
// eslint-disable-next-line import/no-unresolved
import Documentation from 'components/Common/ModalView/Documentation';
import Loader from 'components/Shared/Loader';
import { Document, Page } from 'react-pdf';
import { Button } from '@material-ui/core';
import { ATTACHMENT_NOTES } from 'constants/app';
/**
 * Display message on chat screen
 * @param {Object} message - contain message data like text, time.
 * @param {string} position -  msg position Left or Right
 * @returns {ReactElement}
 */

const MessageBox = ({
  message,
  setChatLoaded,
  position = 'Right',
  memberName,
  memberId,
  checkForHyperLink,
}) => {
  const imageDownloadUrl = process.env.REACT_APP_CHAT_KARDIA_SERVICE;
  const [iamgeUrl, setImageUrl] = useState('');
  const [openDocument, setopenDocument] = useState({
    open: false,
    file: null,
  });

  useEffect(() => {
    setChatLoaded(true);
    return () => setChatLoaded(false);
  }, []);
  useEffect(() => {
    if (message?.text.includes('asset://')) {
      setImageUrl(`${imageDownloadUrl}api/v1/asset/${message.text.split('//')[1].split('?')[0]}`);
    } else {
      setImageUrl(message.text);
    }
  }, [message]);

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const [loading, setLoading] = useState(false);
  const getUpdatedObjectFile = (url) => {
    // let fileArr = [];
    setLoading(true);
    toDataURL(url + '/download').then((dataUrl) => {
      var fileData = dataURLtoFile(dataUrl, url.slice(-12));
      setLoading(false);
      setopenDocument({
        open: true,
        file: fileData,
      });
    });
  };

  //open add notes modal on click of attachment icon
  const openDocuments = async (file) => {
    getUpdatedObjectFile(file);
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const changePage = useCallback(
    (type) => {
      if (type === 'prev') {
        setPageNumber(pageNumber - 1);
      } else {
        setPageNumber(pageNumber + 1);
      }
    },
    [pageNumber],
  );
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <>
      <div className={classes[`message${position}`]}>
        {false ? (
          <div className={classes.imgBox}>
            <img src="https://miro.medium.com/max/640/1*Mom9A9c2MVqI-V-KPz14ag.png" alt="message" />
          </div>
        ) : (
          <div className={classes.messageContainer}>
            {position === 'Left' && (
              <div className={classes.userimg}>{memberName[0].toUpperCase()}</div>
            )}
            {
              // when chat message contains files
              iamgeUrl && iamgeUrl.includes(imageDownloadUrl) ? (
                iamgeUrl.includes('mp4') || iamgeUrl.includes('mov') ? (
                  <div className={classes[`imageMessageInnerContainer`]}>
                    <AttachmentIcon
                      onClick={() => {
                        openDocuments(iamgeUrl);
                        // saveAs(chatMessage);
                      }}
                      className={classes['downloadIcon']}
                    />
                    {loading && <Loader />}
                    <video
                      width="auto"
                      height="320px"
                      controls
                      poster={
                        iamgeUrl.includes('mp4')
                          ? iamgeUrl.replace('.mp4', '-tmb.jpg')
                          : iamgeUrl.replace('.mov', '-tmb.jpg')
                      }
                      style={{ opacity: loading && 0.2 }}
                    >
                      <source src={iamgeUrl} type="video/mp4" />
                    </video>
                  </div>
                ) : iamgeUrl.includes('pdf') ? (
                  <div className={classes[`imageMessageInnerContainer`]}>
                    <AttachmentIcon
                      onClick={() => {
                        // saveAs(chatMessage);
                        openDocuments(iamgeUrl);
                      }}
                      className={classes['downloadIcon']}
                    />
                    {loading && <Loader />}
                    <Document
                      className={classes[`pdfContainer`]}
                      file={iamgeUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                      <div style={{ display: 'flex', justifyContent: 'center', zIndex: 99 }}>
                        {pageNumber > 1 && <Button onClick={() => changePage('prev')}>Prev</Button>}
                        {pageNumber < numPages && (
                          <Button onClick={() => changePage('next')}>Next</Button>
                        )}
                      </div>
                    </Document>
                  </div>
                ) : iamgeUrl.includes('mp3') ? (
                  <div
                    className={classes[`imageMessageInnerContainer`]}
                    style={{ marginRight: 20 }}
                  >
                    <AttachmentIcon
                      onClick={() => {
                        openDocuments(iamgeUrl);
                        // saveAs(chatMessage);
                      }}
                      className={classes['downloadIcon']}
                      style={{ right: -16 }}
                    />
                    {loading && <Loader />}
                    <audio
                      src={iamgeUrl}
                      controls="false"
                      type="audio/mp3"
                      style={{ opacity: loading && 0.2 }}
                    ></audio>
                  </div>
                ) : (
                  <div className={classes[`imageMessageInnerContainer`]}>
                    <AttachmentIcon
                      onClick={() => {
                        openDocuments(iamgeUrl);
                        // saveAs(chatMessage);
                      }}
                      className={classes['downloadIcon']}
                    />
                    {/* to zoom the image file on click */}
                    {loading && <Loader />}
                    <Zoom>
                      <img
                        alt=""
                        src={iamgeUrl}
                        width="380px"
                        height="auto"
                        style={{ opacity: loading && 0.2 }}
                      />
                    </Zoom>
                  </div>
                )
              ) : (
                <div className={classes[`messageInnerContainer${position}`]}>
                  <p
                    className={classes.messageContent}
                    dangerouslySetInnerHTML={{ __html: checkForHyperLink(iamgeUrl) }}
                  ></p>
                </div>
              )
            }
          </div>
        )}
        <div className={classes[`messageTimestamp${position}`]}>
          {moment(new Date(message.time)).format('LT')}
        </div>
      </div>
      {openDocument.open && (
        <Documentation
          onClose={() => {
            setopenDocument(false);
          }}
          noteId={''}
          setopenNotesModal={setopenDocument}
          memberId={memberId}
          openNotesModal={openDocument.open}
          file={openDocument.file}
          type={ATTACHMENT_NOTES}
        />
      )}
    </>
  );
};

export default MessageBox;
