import { Box, Typography } from '@mui/material';
import React from 'react';
import SponsorPopover from 'screens/coach/MemberList/components/SponsorPopover';

function Pills(props) {
  const { params, column } = props;
  const statusPills = {
    1: '#CC3D3F',
    2: '#FDC246',
    3: 'lightgray',
  };
  const ekgPills = {
    'Atrial Fibrillation': '#FDC246',
    Tachycardia: '#CC3D3F',
    'Sinus Rhythm with Supraventricular Ectopy': '#ECB524',
    'Normal Sinus Rhythm': '#28B33B',
    'Heart Rate Over 150': '#C3F738',
    'Heart Rate Over 120': '#ECB524',
    'Sinus Rhythm with Premature Ventricular Contractions': '#CC3D3F',
    'Sinus Rhythm with Wide QRS': '#FDC246',
    'Sinus Rhythm': '#F75787',
  };

  return (
    <>
      {column === 'ekg' && params?.row?.ekgDetermination !== null ? (
        <Box
          style={{
            border: `1px ${params?.row?.ekgDetermination === '' ? 'dashed' : 'solid'} ${
              params.row.status === 3 ? 'lightgray' : ekgPills[params?.row?.ekgName]
            }`,
            borderRadius: '20px',
            padding: '5px 10px',
          }}
        >
          <Typography
            variant="body1"
            style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}
          >
            {params?.row?.ekgName !== 'No Analysis' &&
              (params?.row?.ekgName.length > 20 ? (
                <SponsorPopover item={params?.row?.ekgName} length={20} />
              ) : (
                params?.row?.ekgName
              ))}
          </Typography>
        </Box>
      ) : (
        column === 'status' && (
          <Box
            style={{
              border: `1px solid ${
                params.row.status === 3 ? 'lightgray' : statusPills[params.row.status]
              }`,
              borderRadius: '20px',
              padding: '5px 10px',
            }}
          >
            <Typography
              variant="body1"
              style={{ color: params.row.status === 3 ? 'lightgray' : '#142A39' }}
            >
              {params?.row?.statusName}
            </Typography>
          </Box>
        )
      )}
    </>
  );
}

export default Pills;
