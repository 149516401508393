/* eslint-disable */
import moment from 'moment'

import { SORT_TYPES, EKG_TABLE_COLUMN_HEADERS, MIN_BPM_VALUE, MAX_BPM_VALUE, KARDIA_PRO_START_DATE, KARDIA_PRO_END_DATE, MAX_QTCF_VALUE, MIN_QTCF_VALUE } from 'constants/app'
import {
  getAnalysisBy,
  StandardCompare,
} from '../../Utilities/Utilities'
import { comparePatients } from 'store/reducers/app'

export const sortEkgRecordingIds = (
  sorts = {
    sortBy: EKG_TABLE_COLUMN_HEADERS.RECORDED_AT,
    sortDirection: SORT_TYPES.DESC,
  },
  recIds = [],
  recByIds = {},
) => {
  const {
    sortBy,
    sortDirection,
  } = sorts
  const recordingIds = [ ...recIds ]
  let sortedRecordingIds = recordingIds

  if (recordingIds) {
    switch (sortBy) {
    case EKG_TABLE_COLUMN_HEADERS.RECORDED_AT:
      sortedRecordingIds = recordingIds.sort((a, b) => StandardCompare.dates(recByIds[a][sortBy], recByIds[b][sortBy], sortDirection))
      break
    case EKG_TABLE_COLUMN_HEADERS.CONFIRMED_AT:
      sortedRecordingIds = recordingIds.sort((a, b) => {
        const date1 = new Date(recByIds[a].newestInterpretation.confirmation.createdAt)
        const date2 = new Date(recByIds[b].newestInterpretation.confirmation.createdAt)

        return StandardCompare.dates(date1, date2, sortDirection)
      })
      break
    case EKG_TABLE_COLUMN_HEADERS.QT_CONFIRMED_AT:
      sortedRecordingIds = recordingIds.sort((a, b) => {
        const date1 = new Date(recByIds[a].qtMeasurementReceived)
        const date2 = new Date(recByIds[b].qtMeasurementReceived)
        return StandardCompare.dates(date1, date2, sortDirection)
      })
      break
    case EKG_TABLE_COLUMN_HEADERS.PATIENT:
      sortedRecordingIds = recordingIds.sort((a, b) => comparePatients(recByIds[a].participantProfile, recByIds[b].participantProfile, sortDirection))
      break
    case EKG_TABLE_COLUMN_HEADERS.MRN:
      sortedRecordingIds = recordingIds.sort((a, b) => StandardCompare.strings(recByIds[a].participantProfile[sortBy], recByIds[b].participantProfile[sortBy], sortDirection))
      break
    case EKG_TABLE_COLUMN_HEADERS.ANALYSIS:
      sortedRecordingIds = recordingIds.sort((a, b) => StandardCompare.strings(recByIds[a][sortBy], recByIds[b][sortBy], sortDirection))
      break
    case EKG_TABLE_COLUMN_HEADERS.ANALYSIS_BY:
      sortedRecordingIds = recordingIds.sort((a, b) => {
        const physician1 = getAnalysisBy(recByIds[a])
        const physician2 = getAnalysisBy(recByIds[b])

        return StandardCompare.strings(physician1, physician2, sortDirection)
      })
      break
    case EKG_TABLE_COLUMN_HEADERS.BPM:
      if (sortDirection === SORT_TYPES.DESC) {
        sortedRecordingIds = recordingIds.sort((a, b) => recByIds[b][sortBy] - recByIds[a][sortBy])
      } else if (sortDirection === SORT_TYPES.ASC) {
        sortedRecordingIds = recordingIds.sort((a, b) => recByIds[a][sortBy] - recByIds[b][sortBy])
      }
      break
    case EKG_TABLE_COLUMN_HEADERS.ORDERING_PRACTICE:
      sortedRecordingIds = recordingIds.sort((a, b) => StandardCompare.strings(recByIds[a][sortBy].name, recByIds[b][sortBy].name, sortDirection))
      break
    default:
      // No sort, keep previous sort
      break
    }
  }

  return sortedRecordingIds
}

export const filterEkgRecordingIds = (
  filters = {
    selectedPhysicianId: undefined,
    selectedPatientId: undefined,
    selectedEkgResult: undefined,
    selectedStartDate: undefined,
    selectedEndDate: undefined,
    selectedBpmStartValue: undefined,
    selectedBpmEndValue: undefined,
    selectedPhysicians: undefined,
    selectedAssignedPhysicians: undefined,
    selectedQtOrderingPractice: undefined,
  },
  recordingIds,
  recordingByIds,
  isConfirmedDate,
  isQtConfirmedDate = false,
) => {
  const {
    selectedPhysicianId,
    selectedPatientId,
    selectedEkgResult,
    selectedStartDate,
    selectedEndDate,
    selectedBpmStartValue,
    selectedBpmEndValue,
    selectedPhysicians,
    selectedAssignedPhysicians,
    selectedQtOrderingPractice,
    selectedQtcfStartValue,
    selectedQtcfEndValue,
    selectedIntrText,
    selectedPatientText,
  } = filters;
  let filteredEKGRecordings = recordingIds

  if (recordingIds) {
    if (selectedStartDate !== KARDIA_PRO_START_DATE
      || selectedEndDate !== KARDIA_PRO_END_DATE
    ) {
      const startDay = moment(selectedStartDate).startOf('day')
      const endDay = moment(selectedEndDate).endOf('day')
      filteredEKGRecordings = filteredEKGRecordings
        .filter(recId => {
          const r = recordingByIds[recId]
          let date = r.recordedAt
          if (isConfirmedDate) {
            date = r.newestInterpretation.confirmation.createdAt
          } else if (isQtConfirmedDate) {
            date = r.qtMeasurementReceived
          }

          if (moment(date).isBefore(startDay)
            || moment(date).isAfter(endDay)) {
            return false
          }

          return true
        })
    }

    if (selectedPatientId) {
      filteredEKGRecordings = filteredEKGRecordings
        .filter(id => {
          const r = recordingByIds[id]
          return r.participantID === selectedPatientId
        })
    }

    if (selectedEkgResult.length > 0) {
      filteredEKGRecordings = filteredEKGRecordings
        .filter(recId => {
          const r = recordingByIds[recId]
          return selectedEkgResult.some(ekgResult => ekgResult.id === r.filterClass)
        })
    }

    if (selectedPhysicianId) {
      filteredEKGRecordings = filteredEKGRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          if (r.newestInterpretation && r.newestInterpretation.confirmation) {
            return r.newestInterpretation.confirmation.memberID === selectedPhysicianId
          } else if (r.newestInterpretation && !r.newestInterpretation.confirmation) {
            return r.newestInterpretation.memberID === selectedPhysicianId
          } else {
            // Should never be here
            return false
          }
        })
    }

    if (selectedPhysicians && selectedPhysicians.length > 0) {
      let hasNone = false
      const selectedPhysiciansById = selectedPhysicians
        .filter(p => {
          if (p.id === '0') {
            hasNone = true
          }
          return p.id !== '0'
        })
        .map(p => p.id)

      filteredEKGRecordings = filteredEKGRecordings
        .filter(recId => {
          const patient = recordingByIds[recId].participantProfile

          if (hasNone && !patient.orderingPhysician) {
            return true
          } else if (patient.orderingPhysician) {
            return selectedPhysiciansById.includes(patient.orderingPhysician.id)
          }
          return false
        })
    }

    if (selectedAssignedPhysicians && selectedAssignedPhysicians.length > 0) {
      let hasNone = false;
      const selectedPhysiciansById = selectedAssignedPhysicians
        .filter(p => {
          if (p.id === '0') {
            hasNone = true;
          }
          return p.id !== '0';
        })
        .map(p => p.id);
      filteredEKGRecordings = filteredEKGRecordings.filter(recId => {
        const assignedCardiologist = recordingByIds[recId].assignedMember;

        if (hasNone && !assignedCardiologist) {
          return true;
        } else if (assignedCardiologist) {
          return selectedPhysiciansById.includes(assignedCardiologist.id);
        }
        return false;
      });
    }

    if (selectedQtOrderingPractice && selectedQtOrderingPractice.length > 0) {
      let hasNone = false;
      const selectedOrderingPracticeById = selectedQtOrderingPractice.filter(p => {
          if (p.id === '0') {
            hasNone = true;
          }
          return p.id !== '0';
        })
        .map(p => p.id);
      filteredEKGRecordings = filteredEKGRecordings.filter(recId => {
        const assignedPractice = recordingByIds[recId].orderingPractice;
        if (hasNone && !assignedPractice) {
          return true;
        } else if (assignedPractice) {
          return selectedOrderingPracticeById.includes(assignedPractice.id);
        }
        return false;
      });
    }

    if (selectedBpmStartValue !== MIN_BPM_VALUE
      || selectedBpmEndValue !== MAX_BPM_VALUE
    ) {
      filteredEKGRecordings = filteredEKGRecordings
        .filter(recId => {
          const r = recordingByIds[recId]

          return StandardCompare.tableNumberRangeFilter({
            values: {
              min: selectedBpmStartValue,
              current: r.bpm,
              max: selectedBpmEndValue,
            },
            limits: {
              min: MIN_BPM_VALUE,
              max: MAX_BPM_VALUE,
            },
          })
        })
    }

    if (selectedQtcfStartValue !== MIN_QTCF_VALUE ||
      selectedQtcfEndValue !== MAX_QTCF_VALUE
    ) {
      if (selectedQtcfStartValue && selectedQtcfEndValue) {
        // console.log('QTCF IS getting applied ', selectedQtcfStartValue);
        filteredEKGRecordings = filteredEKGRecordings.filter(recId => {
          const r = recordingByIds[recId];
          return StandardCompare.tableNumberRangeFilter({
            values: {
              min: selectedQtcfStartValue,
              current: r.latestQtcf,
              max: selectedQtcfEndValue,
            },
            limits: {
              min: MIN_QTCF_VALUE,
              max: MAX_QTCF_VALUE,
            },
          });
        });
      }
    }

    if (selectedIntrText && selectedIntrText !== '') {
      filteredEKGRecordings = filteredEKGRecordings.filter(recId => {
        const r = recordingByIds[recId];
        let result = false;
        if (r.interpretationComment) {
          let comment = r.interpretationComment.toLowerCase();
          let searchString = selectedIntrText.toLowerCase();
          result = comment.match(searchString) ? true : false;
        }
        return result;
      });
    }

    if (selectedPatientText && selectedPatientText !== '') {
      filteredEKGRecordings = filteredEKGRecordings.filter(recId => {
        const r = recordingByIds[recId];
        let result = false;
        if (r.participantProfile.customParticipantID) {
          let patientname = r.participantProfile.customParticipantID.toLowerCase();
          let searchString = selectedPatientText.toLowerCase();
          result = patientname.match(searchString) ? true : false;
        }
        return result;
      });
    }
  }

  return filteredEKGRecordings
}
