import React from 'react';
import { CoachPortal } from 'screens/coach';
import { useSelector } from 'react-redux';

function DoctorConsultations() {
  const userType = useSelector((state) => state.user.chatProfile.userType);
  return <CoachPortal userType={userType} />;
}

export default DoctorConsultations;
