import React, { memo, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // '& > * + *': {
    //   marginLeft: theme.spacing(4),
    // },
  },
  countStyle: {
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    fontWeight: 400,
    fontSize: 12,
    marginLeft: 10,
    // right:30,
    // top:8,
    // position:"relative"
  },
  '& .css-heg063-MuiTabs-flexContainer': {
    columnGap: '20px',
  },
}));

const TabSelector = (props) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    props?.selected && setValue(props?.selected);
  }, [props?.selected]);

  const handleChange = (event, newValue) => {
    props.callback(newValue);
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        {props.items.map((item, index) => {
          return (
            <Tab
              sx={{ minWidth: 140 }}
              value={index}
              label={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div>{item?.item}</div>{' '}
                  {item?.showCount ? (
                    <div
                      className={classes.countStyle}
                      style={{
                        background: item?.active ? '#1976d2' : '#00000014',
                        color: item?.active ? 'white' : 'black',
                      }}
                    >
                      {props?.count}
                    </div>
                  ) : (
                    <div style={{ height: 30, width: 30 }}></div>
                  )}
                </div>
              }
            ></Tab>
          );
        })}
      </Tabs>
    </Box>
  );
};

export default memo(TabSelector);
