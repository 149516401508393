import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Container, Box } from '@material-ui/core';
import Hometab from './home_tab';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardKpiCount } from 'store/actions';
// import BottomLineTitle from '../Shared/Typography/BottomLineTitle';
// import MuiTypography from '../Shared/Typography/MuiTypography';
// import CachedIcon from '@material-ui/icons/Cached';
// import { useTranslation } from 'react-i18next';
// import moment from 'moment';

function AdminDashBoard(props) {
  // const { t } = useTranslation();
  const [refreshaction, setrefreshaction] = useState(Date.now());
  const [refreshactiononnetworkcall, setrefreshactiononnetworkcall] = useState(Date.now());
  const onRefreshClick = () => {
    setrefreshactiononnetworkcall(Date.now());
    setrefreshaction(Date.now());
  };

  const dispatch = useDispatch();

  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  useEffect(() => {
    dispatch(dashboardKpiCount(teamId, profileId));
  }, []);

  const onRefreshClicknwrk = () => {
    setrefreshactiononnetworkcall(Date.now());
  };

  const refreshdatetimeaction = useCallback(() => {
    onRefreshClicknwrk();
  }, []);
  return (
    <Container maxWidth="xl">
      <>
        {/* <Box mt={2} />
        <Grid alignItems="center" container justify="space-between">
          <Grid item>
            <MuiTypography
              fontSize="34px"
              fontFamily="Work Sans"
              lineHeight="40px"
              letterSpacing="0.25px"
              fontWeight={500}
              color="#000000"
            >
              {t('Dashboard')}
            </MuiTypography>

            <BottomLineTitle />
          </Grid>
          <Grid item>
            <Grid alignItems="center" container justify="space-between">
              <Grid item>
                <MuiTypography
                  fontSize="12px"
                  fontFamily="Work Sans"
                  lineHeight="20px"
                  letterSpacing="-0.2px"
                  textTransform="uppercase"
                  color="#536063"
                >
                  {t('LAST REFRESHED')} {moment(refreshactiononnetworkcall).format('hh:mm A')}
                </MuiTypography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => onRefreshClick()}>
                  <CachedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Box mt={2} />
        <Grid alignItems="center" container justify="flex-start">
          <Grid item xs={12}>
            <Hometab refreshaction={refreshaction} refreshdatetimeaction={refreshdatetimeaction} />
          </Grid>
        </Grid>
      </>
    </Container>
  );
}

export default AdminDashBoard;
