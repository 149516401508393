import React from 'react';
import { appointmentStatusName, sessionSeverity } from 'utilities/Constants';

export const StatusColumn = (props) => {
  const { data, outliner = '', columnBorderColor = '' } = props;

  let borderStyle = 'solid';
  let borderColor;
  if (outliner === '') {
    if (data.toLowerCase().includes(sessionSeverity.HIGH)) {
      borderColor = '#CC3D3F';
    } else if (data.toLowerCase().includes(sessionSeverity.HIGH_MEDIUM)) {
      borderColor = '#F78C4E';
    } else if (data.toLowerCase().includes(sessionSeverity.LOW_MEDIUM)) {
      borderColor = '#FDC246';
    } else if (data.toLowerCase().includes(sessionSeverity.MEDIUM)) {
      borderColor = '#FDC246';
    } else if (data.toLowerCase().includes(sessionSeverity.LOW)) {
      borderColor = '#067F6F';
    } else {
      switch (data) {
        case appointmentStatusName.SCHEDULED:
          borderColor = '#BABFBD';
          break;
        case appointmentStatusName.COMPLETED:
          borderStyle = 'dashed';
          borderColor = '#067F6F';
          break;
        case appointmentStatusName.INPROGRESS:
          borderColor = '#2D9F86';
          break;
        case appointmentStatusName.CLOSED:
          borderColor = '#CC3D3F';
          break;
        case appointmentStatusName.WAITING_ROOM:
          borderStyle = 'dashed';
          borderColor = '#FDC246';
          break;
        case appointmentStatusName.ENCOUNTER_SUMMARY:
          borderStyle = 'dashed';
          borderColor = '#2D9F86';
          break;
        default:
          borderColor = '#BABFBD';
          break;
      }
    }
  }
  const statusBorderStyle = {
    border: `1.5px ${outliner || borderStyle} ${columnBorderColor || borderColor}`,
    borderRadius: '100px',
    padding: '4px 12px',
    textAlign: 'left',
    width: 'fit-content',
    fontSize: '10px',
    // ...style,
  };

  return <p style={statusBorderStyle}>{data.toUpperCase()}</p>;
};
